import { KanplaError } from "..";

export const getProductNames = async (
  productIds: Array<string> = ["-"],
  collectionName: string = "products",
  db: any
) => {
  try {
    const productPromises = productIds.map(async (productId) => {
      const data = (
        await db.collection(collectionName).doc(productId).get()
      ).data();

      if (!data) return { [productId]: productId };

      return { [productId]: data?.name };
    });

    const products = await Promise.all(productPromises);

    const finished = products.reduce((acc, doc) => {
      return {
        ...acc,
        ...doc,
      };
    }, {});

    return finished;
  } catch (e) {
    throw new KanplaError(e?.code, e?.message);
  }
};
