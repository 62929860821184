/* eslint-disable @typescript-eslint/ban-ts-comment */
import { faCreditCardFront } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DrawerOrModal } from "@kanpla/ui";
import { Button } from "antd";
import {
  default as React,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { loadAsync } from "../../lib/helpers/scriptLoader";
import { AppContext } from "../contextProvider";
import CardsManage from "../modals/payment/CardsManage";
import MethodsWrapper from "./design/PaymentRowsWrapper";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedMethod: string | null;
  onSelect: (method: string) => void;
  /** Option to choose credit from kanpla Go  */
  basketPrice?: number;
  /** Allow card editing */
  showCardButton?: boolean;
}

export const SelectPayment = (props: Props) => {
  const {
    open,
    setOpen,
    onSelect,
    selectedMethod,
    basketPrice = null,
    showCardButton = false,
  } = props;
  const { t } = useTranslation(["translation", "payment"]);
  const [whatIsAvailable, setWhatIsAvailable] = useState(null);
  // const [selected, setSelected] = useState(selectedMethod || "card");
  const [openCardManager, setOpenCardManager] = useState(false);

  const { card } = useContainer(AppContext);

  const handleOnSelect = (val: string) => {
    // setSelected(val);

    onSelect(val);
    setOpen(false);
  };

  const loadReepayScript = async () => {
    try {
      // @ts-ignore
      loadAsync("https://checkout.reepay.com/checkout.js", async () => {
        // @ts-ignore
        const isGooglePayAvailable = await Reepay.isGooglePayAvailable();
        // @ts-ignore
        const isApplePayAvailable = await Reepay.isApplePayAvailable();

        if (isGooglePayAvailable) setWhatIsAvailable("googlepay");
        if (isApplePayAvailable) setWhatIsAvailable("applepay");
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (open) {
      loadReepayScript();
    }
  }, [open]);

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("translation:payment-methods")}
      subtitle={t("payment:modal.subtitles.how-would-you-like-to-pay")}
      drawerProps={{ showCloseButton: false }}
    >
      <div className="w-full h-full flex flex-col pt-6 pb-8">
        <MethodsWrapper
          onClick={handleOnSelect}
          available={whatIsAvailable}
          card={card}
          method={selectedMethod || "card"}
          basketPrice={basketPrice}
        />
        {card && (
          <>
            <Button
              style={{ width: "max-content" }}
              className="mt-6 mx-auto"
              icon={
                <FontAwesomeIcon icon={faCreditCardFront} className="mr-2" />
              }
              onClick={() => setOpenCardManager(true)}
            >
              {t("payment:edit-multiple-cards")}
            </Button>
            <CardsManage open={openCardManager} setOpen={setOpenCardManager} />
          </>
        )}
      </div>
    </DrawerOrModal>
  );
};
