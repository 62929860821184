import { Admin, UnsplashImage } from "@kanpla/types";
import moment from "moment";

interface Props {
  images: any[];
  admin: Admin;
  partnerId: string;
}

export const unsplashImagesToKanplaImages = (props: Props) => {
  const { images, admin, partnerId } = props;

  return images.reduce((acc: UnsplashImage[], curr) => {
    return [
      ...acc,
      {
        id: curr.id,
        createdAtSeconds: moment().unix(),
        createdBy: admin.adminId,
        createdByEmail: admin.email,
        partnerId,
        url: curr.urls?.regular || curr?.urls.raw,
        description: curr?.description || "",
        author: curr.user?.name || curr.user?.username,
        downloadUrl: curr.links.download_location,
      },
    ];
  }, []) as UnsplashImage[];
};
