import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getDayIndexFromSeconds,
  getNextWorkingDay,
  getPrevWorkingDay,
  getRelativeDateFormat,
  getWeekArray,
  getWeekSeconds,
  Timestamp,
} from "@kanpla/system";
import { DayIndex, Week } from "@kanpla/types";
import { DatePicker } from "antd";
import { capitalize, isEmpty } from "lodash";
import moment from "moment";
import { default as React, Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  week: Week;
  setWeek: Dispatch<SetStateAction<Week>>;
  dayIndex: DayIndex;
  setDayIndex: Dispatch<SetStateAction<DayIndex>>;
  maxWidth?: number;
  confirm?: () => Promise<boolean>;
}

export const isDateStringPattern = (date: string) =>
  /(i )|(sidste)|(næste)|(last)|(next)/g.test(date);

export const TodaySwitch = ({
  week,
  setWeek,
  dayIndex,
  setDayIndex,
  maxWidth = 330,
  confirm = async () => true,
}: Props) => {
  const { t, i18n } = useTranslation(["translation"]);
  /**
   * Util to change the localization of moment.js
   * dow and doy explanation: https://github.com/moment/momentjs.com/issues/279
   */
  moment.updateLocale(isEmpty(i18n) ? "da" : i18n.language, {
    week: { dow: 1, doy: 4 },
  });
  const dictionaries = isEmpty(i18n)
    ? undefined
    : {
        today: t("translation:today"),
        tomorrow: t("translation:tomorrow").toUpperCase(),
        yesterday: t("translation:yesterday").toUpperCase(),
        this: t("translation:this"),
        next: t("translation:next"),
        last: t("translation:last"),
      };
  const [open, setOpen] = useState(false);
  const dateSeconds = week[dayIndex]?.seconds || 0;
  const date = moment.unix(dateSeconds);

  const displayDate = getRelativeDateFormat({ dateSeconds }, dictionaries);

  // Confirm

  const handleDateChange = async (dateSeconds: number) => {
    if (!date) return;

    await confirm();
    const weekSeconds = getWeekSeconds(dateSeconds);
    const weekArray = getWeekArray(weekSeconds, Timestamp);
    const dayIndex = parseInt(
      getDayIndexFromSeconds(dateSeconds).toFixed(0)
    ) as DayIndex;

    // setDate(date);
    setDayIndex(dayIndex);
    setWeek(weekArray);
  };

  const buttonStyles =
    "px-6 h-auto flex items-center justify-center rounded hover:bg-main-600 transition-all flex-grow-1 flex-shrink-0";

  const isToday = displayDate === "i dag" || displayDate === "today";
  const title = displayDate;
  // let title = `${!isDateStringPattern(displayDate) ? "på " : ""}${displayDate}`;

  const prevDay = () => {
    const prevWorkingDay = getPrevWorkingDay(date.unix());
    handleDateChange(prevWorkingDay);
  };

  const nextDay = () => {
    const nextWorkingDay = getNextWorkingDay(date.unix());
    handleDateChange(nextWorkingDay);
  };

  return (
    <div
      className="justify-between items-stretch w-full flex border rounded-lg bg-main-100"
      style={{ maxWidth }}
    >
      <button className={buttonStyles} onClick={prevDay}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>

      <button
        className="block text-center md:mx-2 relative rounded hover:bg-main-600 transition-all flex-grow cursor-pointer"
        onClick={() => setOpen(true)}
      >
        <div
          className="text-lg font-semibold text-primary-main py-3"
          // style={{ marginBottom: -2 }}
        >
          {capitalize(title)}
        </div>
        {/* <div className="text-xs text-text-secondary">
          {date.format("dddd [d.] DD/M")}
        </div> */}

        <style>
          {`.hidden-input .ant-picker {
            height: 100% !important;
            width: 100% !important;
            opacity: 0;
          }`}
        </style>
        <div className="absolute inset-0 hidden-input cursor-pointer">
          <DatePicker
            value={date}
            inputReadOnly
            onChange={(date) => handleDateChange(date.unix())}
            dropdownAlign={{ points: ["tc", "bc"] }}
            className="cursor-pointer"
            disabledDate={(date) =>
              date.get("day") === 0 || date.get("day") === 6
            }
          />
        </div>
      </button>

      <button className={buttonStyles} onClick={nextDay}>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};
