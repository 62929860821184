import { Tree } from "@kanpla/types";

export const filterTopLevel = (
  nodes: Tree["productBanks"] | Tree["schools"],
  nodeId: string,
  /** generatedProductBankIds in selection */
  generatedProductBankIds: string[]
) => {
  const values = Object.values(nodes || {});
  const node = values.find((s) => s.id === nodeId);
  // Return top-level true if its not in the tree at all
  if (!node) return true;
  const selectedParents = Object.keys(node.parents || {}).filter(
    (productBankId) => generatedProductBankIds.includes(productBankId)
  );
  const isTopLevel = selectedParents.length === 0;
  return isTopLevel;
};
