import { faAddressCard } from "@fortawesome/pro-regular-svg-icons";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Module } from "@kanpla/types";
import { ContextCard, ProductSettingsHeader } from "@kanpla/ui";
import { Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { isEmpty } from "lodash";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  value?: string;
  onChange?: (newReference: string) => void;
  defaultReference?: string;
  module: Module;
  noBg?: boolean;
}

interface AvailableReferencesProps {
  refs: Array<string>;
  onSet: Dispatch<SetStateAction<string>>;
}

const AvailableReferences = ({ refs, onSet }: AvailableReferencesProps) => {
  if (!refs || isEmpty(refs)) return null;

  return (
    <div className="absolute z-10 right-0 top-2 m-px">
      <ContextCard
        easyClose
        className="right-0 w-52 overflow-auto"
        button={
          <Tooltip title="Tilføj en foruddefineret reference" placement="left">
            <button className="bg-gray-300 rounded-md px-2" type="button">
              <FontAwesomeIcon icon={faChevronDown} />
            </button>
          </Tooltip>
        }
      >
        <ul>
          {refs.map((ref, key) => (
            <li key={`invoice-refs-${ref}-${key}`}>
              <button
                className="p-2 px-3 w-full text-left hover:bg-background-secondary focus:bg-background-secondary transition-colors"
                onClick={() => onSet(ref)}
                type="button"
              >
                {ref}
              </button>
            </li>
          ))}
        </ul>
      </ContextCard>
    </div>
  );
};

export const InvoiceReference = ({
  value: reference,
  onChange: setReference,
  defaultReference,
  module,
  noBg = false,
}: Props) => {
  const { t, i18n } = useTranslation(["libs"]);
  const [ref, setRef] = useState<string>(defaultReference || reference);

  const availableReferences =
    module?.plugins?.invoiceReference?.availableReferences;

  useEffect(() => {
    if (defaultReference && defaultReference !== "" && reference === null) {
      return setReference(defaultReference);
    }
  }, [defaultReference, reference]);

  useEffect(() => {
    setRef(reference);
  }, [reference]);

  return (
    <label className="px-2 block">
      <div className="relative">
        <AvailableReferences
          refs={availableReferences}
          onSet={(d) => setReference(d)}
        />
        <TextArea
          required={true}
          className="mt-2 mb-4"
          onChange={(e) => setReference(e.target.value)}
          value={ref}
          placeholder={
            isEmpty(i18n)
              ? "F. eks. dit personale ID til bogholderiet"
              : t("libs:example-account-id")
          }
          rows={4}
        ></TextArea>
      </div>
    </label>
  );
};
