import { escapeRegExp } from "lodash";

export const antdFilterOptionSearch = (
  query: string,
  option: any,
  /** @deprecated */
  customTest?: string
) => {
  const pattern = new RegExp(escapeRegExp(query), "i");
  return pattern.test(
    typeof option?.children === "string"
      ? option.children.toLowerCase()
      : `${option?.label?.toLowerCase()}${option?.value?.toLowerCase()}${
          option?.key
        }`
  );
};
