import { Button, Space } from "antd";
import { isEmpty } from "lodash";
import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import confirmAnimation from "./../assets/lf30_editor_4ixcomog.json";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-duotone-svg-icons";

interface Props {
  out: boolean;
  close?: () => void;
  mobile?: boolean;
}

export const ConfirmAnimation = ({ out, mobile, close }: Props) => {
  const [play, setPlay] = useState(false);
  const { t, i18n } = useTranslation(["translation", "mealplan2"]);

  // Delay playing of animation
  useEffect(() => {
    if (out) setTimeout(() => setPlay(true), 1000);
    if (!out) setPlay(false);
  }, [out]);

  const backButtonClassNames = classnames({
    "absolute z-10 mt-3 ml-1": mobile,
    hidden: !mobile,
  });

  return (
    <div
      className={`${
        mobile ? "fixed" : "absolute"
      } inset-0 pointer-events-none transition text-primary-contrast duration-500 z-max transform ${
        out ? "" : "opacity-0 translate-y-2"
      }`}
    >
      {close && (
        <Button
          type="link"
          onClick={close}
          className={backButtonClassNames}
          style={{ paddingTop: "var(--status-bar-height)" }}
        >
          <Space>
            <FontAwesomeIcon icon={faArrowLeft} />
            {isEmpty(i18n) ? "Tilbage" : t("translation:back")}
          </Space>
        </Button>
      )}
      <div
        className={`flex flex-col items-center justify-end from-primary-main to-primary-dark bg-gradient-to-b transition-transform transform ${
          out ? "" : ""
        } h-full origin-bottom shadow-2xl rounded-t-lg`}
      >
        <div
          className={`bg-background-primary rounded-t-xl mx-4 md:mx-12 mt-16 shadow-2xl transition transform delay-500 duration-500 bottom-0 ${
            out ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
          }`}
        >
          <Lottie
            animationData={confirmAnimation}
            loop={false}
            onLoopComplete={() => setPlay(false)}
            autoPlay={play}
          />
          <h3 className="h700 mb-10 px-4 -mt-16 text-center text-text-primary">
            {isEmpty(i18n)
              ? "Din mad er nu bestilt!"
              : t("mealplan2:your-food-is-now-ordered")}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAnimation;
