import { Child, School } from "@kanpla/types";
import { useState, useEffect } from "react";

interface Props {
  selectedSchools: School[];
  /** Firestore handler */
  db: any;
}

interface ReturnProps {
  children: Child[];
  /**
   * You don't need this function anymore, the function now listens to changes in the collection.
   * @deprecated
   */
  fetchChildren: () => void;
  setChildren: (children: Child[]) => void;
  loading: boolean;
}

export const subscribeChildren = ({
  selectedSchools,
  db,
}: Props): ReturnProps => {
  const [children, setChildren] = useState([]);
  const [loading, setLoading] = useState(false);

  // const [orders, setOrders] = useState([]);
  const fetchChildren = () =>
    console.warn("This function is deprecated, you can remove it");

  useEffect(() => {
    if (!selectedSchools || selectedSchools.length === 0) return;

    setLoading(true);
    const childrenQuery = db
      .collection("children")
      .where(
        "school_ref",
        "in",
        selectedSchools.map((s) => s.ref).slice(0, 10)
      );

    // listen to changes
    const unsubscribe = childrenQuery.onSnapshot(async (res) => {
      const allChildren = await res.docs.map((ch) => {
        const data = ch.data();

        // Create short name
        const fullName = data.name.split(" "),
          firstName = fullName[0];

        const [, ...otherNames] = fullName;

        const initials = otherNames
          .filter((name) => name.length > 0)
          .map((name) => `${name.substr(0, 1).toUpperCase()}.`)
          .join(" ");

        data.shortName = `${firstName} ${initials}`;

        // Create group name
        const group = data.selectors
          ? Object.keys(data.selectors)
              .sort((a, b) => a.localeCompare(b, "en"))
              .map((key) =>
                data.selectors[key] === "一" ? "" : data.selectors[key]
              )
              .join("")
          : `${typeof data.grade === `number` ? data.grade : "▯"}${
              data.classification || "▯"
            }`;

        return { ...data, group, child_ref: ch.ref };
      });

      setChildren(allChildren);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [selectedSchools]);

  return { children, fetchChildren, setChildren, loading };
};
