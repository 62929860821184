import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import React, { ReactNode } from "react";
import { isMobile } from "react-device-detect";

interface Props {
  backButton?: boolean;
  backButtonHandler?: () => void;
  backButtonLabel?: string;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  centerSubtitle?: boolean;
  className?: string;
  children?: ReactNode;
}

export const Header = (props: Props) => {
  const {
    title,
    subtitle,
    className = "",
    centerSubtitle = false,
    children,
    backButton = false,
    backButtonLabel = "Tilbage",
    backButtonHandler = () => null,
  } = props;

  return (
    <div
      className={`px-3 ${
        centerSubtitle ? "text-center" : "text-center md:text-left"
      } lg:mt-0 ${className}`}
      style={{ paddingTop: isMobile ? "80px" : "0px" }}
    >
      {backButton && (
        <Button
          onClick={backButtonHandler}
          type="text"
          size="small"
          className="mb-2"
          // className="text-text-secondary text-sm mb-1 btn secondary small"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
          {backButtonLabel}
        </Button>
      )}
      {title && (
        <h1 className="h800 text-text-secondary text-xl lg:text-2xl font-semibold">
          {title}
        </h1>
      )}
      {subtitle && (
        <div
          className={`${
            centerSubtitle ? "text-center" : ""
          } text-sm text-text-secondary relative`}
          style={{ marginTop: 2 }}
        >
          {subtitle}
        </div>
      )}
      {children}
    </div>
  );
};
