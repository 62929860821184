import {
  storage,
  config,
  fetchDocument,
  db,
  removeUndefinedKeys,
  isReactRunningInEmulator,
} from "@kanpla/system";
import { Admin, User, Image } from "@kanpla/types";

interface Props {
  file: Blob | Uint8Array | ArrayBuffer;
  userId: string;
  /** Folder destination of the file */
  folder?: string;
  /** Should the image be available to others in the gallery? */
  shared?: boolean;
  onTaskSuccess?: (success: any, task: any) => void;
  /** Callback function on progress */
  onProgress?: ({ percent }: { percent: number }) => void;
  /** Callback function on error */
  onError?: (err: any) => void;
}

/**
 * Upload an image to the FB storage and returns the url
 * @param props
 * @returns The image url
 */
export const uploadImageToStorage = async (props: Props): Promise<string> => {
  const {
    file,
    userId,
    folder = null,
    shared,
    onProgress = null,
    onError = null,
    onTaskSuccess = null,
  } = props;

  const storageRef = storage.ref();
  const timestamp = new Date().getTime();

  const fileRef = storageRef.child(
    `products/${folder ? `${folder}/` : ""}${userId}/${timestamp}`
  );

  try {
    const uploadTask = fileRef.put(file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snap) =>
          onProgress &&
          onProgress({
            percent: (snap.bytesTransferred / snap.totalBytes) * 100,
          }),
        (err) => {
          onError && onError(err);
          reject();
        },
        async () => {
          const uploadedUrl = `https://${
            isReactRunningInEmulator ? "kanpla" : "knpl"
          }.imgix.net/${folder ? `${folder}/` : ""}${userId}/${timestamp}`;

          console.log("uploaded url", uploadedUrl);

          const admin = await fetchDocument<Admin>(
            db.collection("admins").doc(userId)
          );

          const user =
            !admin.email &&
            (await fetchDocument<User>(db.collection("users").doc(userId)));

          const imageUploadData: Partial<Image> = {
            url: uploadedUrl,
            createdAtSeconds: timestamp,
            createdBy: userId,
            createdByEmail: admin.email || user.email,
            shared,
            partnerId: admin.partnerId,
            folder,
          };

          removeUndefinedKeys(imageUploadData);

          await db.collection("images").add(imageUploadData);
          onTaskSuccess && onTaskSuccess(null, uploadTask);
          resolve(uploadedUrl);
        }
      );
    });
  } catch (e) {
    console.error(e);
    onError(e);
  }
};
