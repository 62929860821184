import { OrderOrder, OrderOrderProduct } from "@kanpla/types";
import { calculateConfigTotal } from "./calculateConfigTotal";

export const calculateOrderTotal = (order: OrderOrder) =>
  Object.values(order || {}).reduce(
    (orderAcc: number, i: OrderOrderProduct) => {
      // Calculate extra price for configurations
      const configPrice =
        i?.config?.reduce((configAcc, config) => {
          // The extra price of one portion of certain config
          const optionsPrice = calculateConfigTotal(config);
          return configAcc + optionsPrice * config.amount;
        }, 0) || 0;

      // The total price of all the portions of the product
      const productPrice = i.price * i.amount + configPrice;

      return orderAcc + productPrice;
    },
    0
  );
