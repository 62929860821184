import { Button, ButtonProps } from "antd";
import React from "react";

interface Props extends ButtonProps {
  text?: string;
  loading?: boolean;
  loadingText?: string;
  htmlType?: "button" | "submit" | "reset";
}

export const ButtonWithLoading = (props: Props) => {
  const {
    loading,
    text = "Bekræft",
    loadingText = "Vent venligst",
    htmlType = "button",
  } = props;
  return (
    <Button
      htmlType={htmlType}
      type="primary"
      style={{ animationDuration: "2s" }}
      {...props}
    >
      <span className="relative">{!loading ? text : loadingText}</span>
    </Button>
  );
};

export default ButtonWithLoading;
