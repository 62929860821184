import { flexBulkOrder, FlexOrder } from "@kanpla/types";

const calculateFlex = ({ individualDocs, bulkDocs }) => {
  // TODO: Account for minimum order

  const allTotal = [...individualDocs, ...bulkDocs].reduce(
    (acc: number, document: FlexOrder | flexBulkOrder) => {
      // Sum up the price of each order document
      const documentCost = Object.values(document.order || {}).reduce(
        (a: number, item: any) => a + item.amount * (item.price || 0),
        0
      );

      return acc + documentCost;
    },
    0
  );

  return allTotal;
};

export default calculateFlex;
