import { Supplier } from "@kanpla/types";
import React from "react";

type Props = {
  customBranding: Supplier;
};

export const SafetyPaymentBackground = ({ customBranding }: Props) => {
  const primaryBackgroundHEX =
    customBranding?.custom?.palette?.background?.primary || "#FFFFFF";

  return (
    <>
      {/* Dots */}
      <div
        className="absolute inset-0"
        style={{
          backgroundImage: `radial-gradient(
          circle,
          #94a3b8 13%,
          transparent 0
          )`,
          backgroundSize: `15px 15px`,
          backgroundRepeat: "repeat",
        }}
      />
      {/* Overlay */}
      <div
        className="absolute inset-0 receipt-gradient-animation"
        style={{
          backgroundSize: "400% 400%",
          backgroundImage: `radial-gradient(
            circle,
            ${primaryBackgroundHEX}EE 00%,
            ${primaryBackgroundHEX}55 20%,
            ${primaryBackgroundHEX}EE 40%,
            ${primaryBackgroundHEX}55 50%,
            ${primaryBackgroundHEX}EE 60%,
            ${primaryBackgroundHEX}55 80%,
            ${primaryBackgroundHEX}EE 100%
            )`,
          animation: "gradient-move 4s ease alternate infinite",
        }}
      />
    </>
  );
};
