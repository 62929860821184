import { useEffect, useState } from "react";
import { supplierUrl } from "@kanpla/system";
import { useSessionstorageState } from "rooks";

interface Props {
  defaultValue?: string | null;
}

export const maskedURLs = {
  "app.foodbycoor.dk": "coor.kanpla.dk",
};

export const useLocationHostname = ({ defaultValue = "kanpla.dk" }: Props) => {
  const [hostname, setHostname] = useState(
    typeof window === "undefined"
      ? defaultValue
      : window?.sessionStorage?.getItem("kanplaLocationHostname")
  );

  // Used for testing with cypress
  const fromSessionStorage = null;
  // const [fromSessionStorage] = useSessionstorageState(
  //   "kanplaLocationHostname",
  //   null
  // );

  useEffect(() => {
    const fromWindow = window.location.hostname;

    const url =
      maskedURLs[
        supplierUrl?.supplierUrl || fromSessionStorage || fromWindow
      ] ||
      supplierUrl?.supplierUrl ||
      fromSessionStorage ||
      fromWindow;

    setHostname(url);
  }, [fromSessionStorage]);

  return hostname;
};
