import React, { Dispatch, SetStateAction } from "react";
import SingleButton from "./SingleButton";

interface SingleProps {
  disabled?: boolean;
}

interface Props {
  color: string;
  plusProps?: SingleProps;
  minusProps?: SingleProps;
  // setValue: Dispatch<SetStateAction<number>>;
  /** An optional trigger used in Choice component */
  processAmountChange?: (type: "plus" | "minus" | "clear") => void;
}

export const ChoiceButton = ({
  color,
  plusProps,
  minusProps,
  processAmountChange,
}: Props) => {
  return (
    <div className={`ml-auto rounded flex flex-shrink-0 -my-2`}>
      <SingleButton
        color={color}
        onClick={() => {
          processAmountChange("minus");
        }}
        type="minus"
        disabled={minusProps?.disabled}
      />
      <SingleButton
        color={color}
        onClick={() => {
          processAmountChange("plus");
        }}
        type="plus"
        disabled={plusProps?.disabled}
      />
    </div>
  );
};
