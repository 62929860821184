import { FirebaseFirestore } from "@firebase/firestore-types";
import axios from "axios";
import { VismaTokenData, VismaTokenReturn } from "../../../../../types/src";
import { transformRequest } from "../../../utils/transformRequest";

export const getVismaToken = async (
  redirect_uri: string,
  code: string,
  client_id: string,
  code_verifier: string,
  navigateTo: string,
  db: FirebaseFirestore,
  partnerId: string
): Promise<VismaTokenReturn> => {
  try {
    const postReq = await axios.post<VismaTokenData>(
      "https://connect.visma.com/connect/token",
      transformRequest({
        grant_type: "authorization_code",
        redirect_uri,
        code,
        client_id,
        code_verifier,
      }),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      }
    );

    const data = postReq.data;

    db.collection("partnersIntegration")
      .doc(partnerId)
      .set({
        dineroToken: data.access_token,
        expire: new Date(Date.now() + 60 * 60 * 1000),
      });

    return {
      navigateTo,
      content: {
        type: "success",
        msg: "Dinero token oprettet",
        data,
      },
    };
  } catch (e) {
    return {
      navigateTo,
      content: {
        type: "error",
        msg: "Error getting Visma token",
        data: e,
      },
    };
  }
};
