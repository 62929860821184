import { Module, School } from "@kanpla/types";

interface Props {
  module?: Module;
  school: School; // seconds
}

export const getModuleDeadline = (props: Props) => {
  const { module, school } = props;

  const schoolSpecificDeadline = school?.modules?.[module?.id]?.deadline;
  const moduleSpecificDeadline = module?.config?.deadline;

  const deadline: number =
    typeof schoolSpecificDeadline === `number`
      ? schoolSpecificDeadline
      : typeof moduleSpecificDeadline === `number`
      ? moduleSpecificDeadline
      : 27000;

  const schoolSpecificRelative =
    school?.modules?.[module?.id]?.deadlineWeekRelative;
  const moduleSpecificRelative = module?.config?.deadlineWeekRelative;

  const deadlineWeekRelative: boolean =
    typeof schoolSpecificRelative === `boolean`
      ? schoolSpecificRelative
      : typeof moduleSpecificRelative === `boolean`
      ? moduleSpecificRelative
      : false;
  const deadlineSoft: number =
    school?.modules?.[module?.id]?.deadlineSoft ||
    module?.config?.deadlineSoft ||
    deadline;

  // Excluding weekends prop
  const schoolExcludingWeekends =
    school?.modules?.[module?.id]?.deadlineExcludingWeekends;
  const moduleExcludingWeekends =
    module?.plugins?.deadlineExcludingWeekends?.active;
  const deadlineExcludingWeekends: boolean =
    typeof schoolExcludingWeekends === `boolean`
      ? schoolExcludingWeekends
      : typeof moduleExcludingWeekends === `boolean`
      ? moduleExcludingWeekends
      : false;

  return {
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    deadlineSoft,
  };
};
