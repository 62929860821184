import { Supplier } from "@kanpla/types";
import React from "react";
import { AllergensDisplay, SingleProductProps } from "..";
import { InlineLabels } from "./InlineLabels";

interface Props {
  product: SingleProductProps;
  onClick: () => void;
  dateSeconds: number;
  customBranding?: Supplier;
}

export const FlexModule = ({
  product,
  onClick,
  dateSeconds,
  customBranding,
}: Props) => {
  if (!product) return null;

  const menuItem = product.dates?.[dateSeconds]?.menu;

  const productName: string = menuItem?.name || product.name;
  const allergens = {
    ...(menuItem?.allergens || {}),
    ...((product as any)?.allergens || {}),
  };

  const labels = {
    ...((product as any)?.labels || {}),
    ...(menuItem?.labels || {}),
  };

  return (
    <div
      onClick={onClick}
      className={`bg-background-primary rounded-lg flex flex-col justify-between relative cursor-pointer border border-divider-main`}
    >
      <div className="px-5 py-6">
        {menuItem?.name && (
          <p className="text-xs text-text-secondary ">{product.name}</p>
        )}
        <h3 className="text-text-primary text-xl mb-2 font-semibold">
          {productName}
        </h3>
        <p className="text-text-secondary text-sm whitespace-pre-wrap">
          {menuItem?.description || product.description || ""}
        </p>
        <InlineLabels labels={labels} />
        <AllergensDisplay
          allergens={allergens}
          hideHeader
          customBranding={customBranding}
        />
      </div>
    </div>
  );
};
