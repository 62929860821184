import { db, detectProductPrice } from "@kanpla/system";
import {
  FlexOption,
  Module,
  OrderConfig,
  OrderOrder,
  Plugins,
  Product,
} from "@kanpla/types";
import { ProductOptions } from "@kanpla/ui";
import { useDocumentListener } from "libs/system/src/firestore/UseDocumentListener";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";

interface Props {
  settings: Plugins.RequiredProduct;
  requiredProductOrder: OrderOrder;
  setRequiredProductOrder: (newValue: OrderOrder) => void;
  mealOptions: Array<FlexOption>;
  module: Module;
  order: OrderOrder;
}

export const RequiredProduct = ({
  settings,
  setRequiredProductOrder,
  mealOptions,
  module,
  order,
}: Props) => {
  const { productId } = settings;
  const [optionChoices, setOptionChoices] = useState<OrderConfig["options"]>(
    {}
  );
  const [product] = useDocumentListener<Product>({
    collection: "products",
    id: productId,
  });

  useEffect(() => {
    const defaultOptionChoices = order?.[productId]?.config?.[0]?.options || {};
    if (isEmpty(optionChoices)) setOptionChoices(defaultOptionChoices);
  }, [order]);

  if (!product) return null;

  const options = mealOptions?.filter((option: FlexOption) =>
    product.options?.includes(option.id)
  );

  return (
    <div>
      <div className="mx-2">
        <ProductOptions
          product={{
            name: product.name,
            id: productId,
            color: product.color,
            price: product.price,
          }}
          options={options}
          value={optionChoices}
          onChange={(newValue) => {
            // update locally
            setOptionChoices(newValue);

            // submit data to the order
            const newOrder: OrderOrder = {
              [productId]: {
                amount: 1,
                name: product.name,
                price: detectProductPrice(product, module),
                config: [
                  {
                    name: product.name,
                    amount: 1,
                    options: newValue,
                  },
                ],
              },
            };

            setRequiredProductOrder(newOrder);
          }}
        />
      </div>
    </div>
  );
};
