import React from "react";

export const MeetingIllustration = () => {
  return (
    <svg
      width="224"
      height="187"
      viewBox="0 0 224 187"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2991 177.475C14.3802 183.189 20.9194 185.642 20.9194 185.642C20.9194 185.642 22.4631 178.83 19.382 173.116C16.3009 167.402 9.76174 164.949 9.76174 164.949C9.76174 164.949 8.21799 171.761 11.2991 177.475Z"
        fill="#4A5568"
      />
      <path
        d="M13.4167 175.595C18.9793 178.941 21.1216 185.589 21.1216 185.589C21.1216 185.589 14.2452 186.81 8.68253 183.463C3.11989 180.117 0.977539 173.469 0.977539 173.469C0.977539 173.469 7.85401 172.248 13.4167 175.595Z"
        fill="var(--palette-primary-main)"
      />
      <path
        d="M138.132 9.68359H103.425V10.1794H138.132V9.68359Z"
        fill="white"
      />
      <path
        d="M128.712 10.0557H128.216V14.6419H128.712V10.0557Z"
        fill="white"
      />
      <path
        d="M113.589 10.0557H113.093V14.6419H113.589V10.0557Z"
        fill="white"
      />
      <path
        d="M155.733 33.4824H121.026V33.9782H155.733V33.4824Z"
        fill="white"
      />
      <path
        d="M130.942 29.0205H130.446V33.6068H130.942V29.0205Z"
        fill="white"
      />
      <path
        d="M146.065 29.0205H145.569V33.6068H146.065V29.0205Z"
        fill="white"
      />
      <path
        d="M201.844 44.6387H167.137V45.1345H201.844V44.6387Z"
        fill="white"
      />
      <path d="M177.053 40.1768H176.558V44.763H177.053V40.1768Z" fill="white" />
      <path d="M192.176 40.1768H191.68V44.763H192.176V40.1768Z" fill="white" />
      <path
        d="M198.373 159.668H163.666V160.164H198.373V159.668Z"
        fill="white"
      />
      <path
        d="M173.583 155.205H173.087V159.791H173.583V155.205Z"
        fill="white"
      />
      <path
        d="M188.705 155.205H188.209V159.791H188.705V155.205Z"
        fill="white"
      />
      <path
        d="M139.123 80.0898H104.416V80.5857H139.123V80.0898Z"
        fill="white"
      />
      <path d="M114.333 75.627H113.837V80.2132H114.333V75.627Z" fill="white" />
      <path d="M129.455 75.627H128.959V80.2132H129.455V75.627Z" fill="white" />
      <path
        d="M150.031 139.835H115.324V140.331H150.031V139.835Z"
        fill="white"
      />
      <path
        d="M125.241 135.373H124.745V139.959H125.241V135.373Z"
        fill="white"
      />
      <path
        d="M140.363 135.373H139.867V139.959H140.363V135.373Z"
        fill="white"
      />
      <path
        d="M205.562 121.737H170.855V122.233H205.562V121.737Z"
        fill="white"
      />
      <path
        d="M180.772 117.275H180.276V121.862H180.772V117.275Z"
        fill="white"
      />
      <path
        d="M195.894 117.275H195.398V121.862H195.894V117.275Z"
        fill="white"
      />
      <path
        d="M90.7351 174.858H32.0342V174.436H90.3127V153.953H36.969L34.3776 148.555L34.7584 148.372L37.2348 153.531H90.7351V174.858Z"
        fill="#4A5568"
      />
      <path
        d="M39.8464 185.204C42.2953 185.204 44.2806 183.219 44.2806 180.77C44.2806 178.321 42.2953 176.336 39.8464 176.336C37.3974 176.336 35.4121 178.321 35.4121 180.77C35.4121 183.219 37.3974 185.204 39.8464 185.204Z"
        fill="#718096"
      />
      <path
        d="M83.5553 185.204C86.0043 185.204 87.9896 183.219 87.9896 180.77C87.9896 178.321 86.0043 176.336 83.5553 176.336C81.1064 176.336 79.1211 178.321 79.1211 180.77C79.1211 183.219 81.1064 185.204 83.5553 185.204Z"
        fill="#718096"
      />
      <path
        d="M135.077 89.3406C136.593 89.3406 137.822 88.1116 137.822 86.5956C137.822 85.0796 136.593 83.8506 135.077 83.8506C133.561 83.8506 132.332 85.0796 132.332 86.5956C132.332 88.1116 133.561 89.3406 135.077 89.3406Z"
        fill="#718096"
      />
      <path
        d="M97.0135 146.985H31.0334L16.7568 100.32H111.716L111.63 100.595L97.0135 146.985ZM31.3458 146.563H96.7038L111.14 100.743H17.3276L31.3458 146.563Z"
        fill="#4A5568"
      />
      <path
        d="M91.8625 144.557H33.9097L21.3701 103.171H104.776L104.7 103.415L91.8625 144.557Z"
        fill="white"
      />
      <path
        d="M112.9 96.1519L112.491 96.0436L115.278 85.54H131.488V85.9625H115.603L112.9 96.1519Z"
        fill="#4A5568"
      />
      <path
        d="M106.971 114.468H21.3701V114.89H106.971V114.468Z"
        fill="#4A5568"
      />
      <path
        d="M102.068 130.026H26.1299V130.449H102.068V130.026Z"
        fill="#4A5568"
      />
      <path
        d="M64.4457 100.532H64.0234V146.775H64.4457V100.532Z"
        fill="#4A5568"
      />
      <path
        d="M83.3538 100.519L80.3223 146.761L80.7437 146.788L83.7752 100.547L83.3538 100.519Z"
        fill="#4A5568"
      />
      <path
        d="M45.1256 100.518L44.7041 100.546L47.718 146.789L48.1395 146.761L45.1256 100.518Z"
        fill="#4A5568"
      />
      <path
        d="M223.102 185.473H0.977539V185.968H223.102V185.473Z"
        fill="#4A5568"
      />
      <path
        d="M148.584 16.1213C148.584 16.1213 152.208 26.4358 149.978 28.0226C147.748 29.6095 157.505 42.5687 157.505 42.5687L169.213 39.395L162.801 28.5516C162.801 28.5516 161.965 17.7081 161.965 16.1213C161.965 14.5344 148.584 16.1213 148.584 16.1213Z"
        fill="#CBD5E0"
      />
      <path
        opacity="0.1"
        d="M148.584 16.1213C148.584 16.1213 152.208 26.4358 149.978 28.0226C147.748 29.6095 157.505 42.5687 157.505 42.5687L169.213 39.395L162.801 28.5516C162.801 28.5516 161.965 17.7081 161.965 16.1213C161.965 14.5344 148.584 16.1213 148.584 16.1213Z"
        fill="#2D3748"
      />
      <path
        d="M142.501 89.645C142.501 89.645 141.444 102.869 142.501 109.745C143.559 116.621 145.146 135.663 145.146 135.663C145.146 135.663 145.146 171.632 150.965 172.161C156.783 172.69 161.015 173.219 161.544 171.103C162.073 168.987 158.899 167.929 160.486 166.871C162.072 165.814 162.601 162.111 160.486 157.879C158.37 153.648 160.486 110.803 160.486 110.803L170.536 137.779C170.536 137.779 171.594 159.995 172.651 163.169C173.709 166.342 171.594 172.161 175.296 172.69C178.999 173.219 183.23 170.045 185.346 168.987C187.462 167.929 182.173 167.929 183.23 167.4C184.288 166.871 187.462 165.285 186.404 164.756C185.346 164.227 184.288 138.837 184.288 138.837C184.288 138.837 181.379 84.62 177.676 82.5042C173.974 80.3884 171.594 84.0327 171.594 84.0327L142.501 89.645Z"
        fill="#4A5568"
      />
      <path
        d="M152.022 170.045V174.277C152.022 174.277 147.262 185.779 152.022 185.779C156.783 185.779 160.485 186.971 160.485 185.384V171.103L152.022 170.045Z"
        fill="#4A5568"
      />
      <path
        d="M183.23 170.038V174.27C183.23 174.27 187.99 185.772 183.23 185.772C178.469 185.772 174.767 186.964 174.767 185.378V171.096L183.23 170.038Z"
        fill="#4A5568"
      />
      <path
        d="M155.99 23.7912C161.248 23.7912 165.511 19.5284 165.511 14.2701C165.511 9.01175 161.248 4.74902 155.99 4.74902C150.731 4.74902 146.469 9.01175 146.469 14.2701C146.469 19.5284 150.731 23.7912 155.99 23.7912Z"
        fill="#CBD5E0"
      />
      <path
        d="M153.08 31.9902C153.08 31.9902 155.725 39.9245 159.957 38.3376L164.188 36.7508L171.594 87.5298C171.594 87.5298 165.775 95.9929 157.312 90.7035C148.849 85.414 153.08 31.9902 153.08 31.9902Z"
        fill="var(--brand-primary-main)"
      />
      <path
        d="M160.485 30.4029L162.866 25.3779C162.866 25.3779 176.883 31.9898 178.999 34.1056C181.114 36.2214 181.114 39.3951 181.114 39.3951L177.412 52.6188C177.412 52.6188 178.47 81.7109 178.47 82.7688C178.47 83.8267 182.172 89.6451 179.527 87.5293C176.883 85.4135 176.354 83.2977 174.238 86.4714C172.122 89.6451 167.362 93.3478 167.362 93.3478L160.485 30.4029Z"
        fill="#718096"
      />
      <path
        d="M178.999 68.4873L177.412 83.2978C177.412 83.2978 167.891 91.761 170.007 92.29C172.123 92.8189 173.181 90.7031 173.181 90.7031C173.181 90.7031 176.883 94.4057 178.999 92.29C181.115 90.1742 186.404 70.0742 186.404 70.0742L178.999 68.4873Z"
        fill="#CBD5E0"
      />
      <path
        d="M156.192 0.814423C154.082 0.738967 151.823 0.701642 150.043 1.83907C149.225 2.4099 148.506 3.11293 147.918 3.91939C146.185 6.11051 144.687 8.86743 145.331 11.586L146.079 11.2944C146.079 12.02 145.918 12.7366 145.607 13.3923C145.712 13.0861 146.065 13.5811 145.97 13.8908L145.146 16.5932C146.501 16.0968 148.185 17.1018 148.391 18.5292C148.485 15.3904 148.811 11.7912 151.357 9.95332C152.641 9.02648 154.266 8.74354 155.83 8.49228C157.272 8.26049 158.784 8.03946 160.166 8.51431C161.547 8.98917 162.724 10.4021 162.411 11.8291C163.048 11.6096 163.76 12.0536 164.075 12.6493C164.39 13.245 164.406 13.9477 164.416 14.6214C165.095 15.1013 165.868 14.1483 166.144 13.3642C166.794 11.5237 167.371 9.56445 167.021 7.64426C166.672 5.72407 165.107 3.88904 163.155 3.88252C163.392 3.6223 163.518 3.28014 163.507 2.92839L161.899 2.79247C162.307 2.73464 162.683 2.53661 162.961 2.23231C162.315 2.94304 157.237 0.85176 156.192 0.814423Z"
        fill="#4A5568"
      />
      <path
        d="M155.724 28.8163C155.724 28.8163 151.418 24.5967 149.869 24.8552C148.319 25.1136 146.203 28.8163 146.203 28.8163C146.203 28.8163 133.509 33.0479 134.038 37.2794C134.567 41.511 140.385 62.14 140.385 62.14C140.385 62.14 145.145 87.0006 140.914 89.6453C136.682 92.29 161.014 99.1664 161.543 95.9927C162.072 92.819 163.13 61.0821 161.543 56.3216C159.956 51.561 155.724 28.8163 155.724 28.8163Z"
        fill="#718096"
      />
      <path
        d="M174.238 37.2793H180.779C180.779 37.2793 185.346 57.3793 185.875 59.4951C186.404 61.6109 187.462 71.6609 186.933 71.6609C186.404 71.6609 175.825 69.5451 175.825 71.132L174.238 37.2793Z"
        fill="#718096"
      />
      <path
        d="M174.017 87.0539H148.978C146.535 75.8138 146.331 64.9539 148.978 54.5781H174.017C169.976 64.7673 169.724 75.5528 174.017 87.0539Z"
        fill="white"
      />
      <path
        d="M138.799 73.2476L146.204 76.4213C146.204 76.4213 160.486 78.5371 160.486 72.7187C160.486 66.9003 146.204 70.0739 146.204 70.0739L141.443 68.5518L138.799 73.2476Z"
        fill="#CBD5E0"
      />
      <path
        d="M140.386 34.1055L134.038 35.6923L129.278 63.7266C129.278 63.7266 127.691 71.1318 130.336 71.6608C132.98 72.1897 140.386 76.4213 140.386 76.4213C140.386 76.4213 141.444 68.4871 143.559 68.4871L138.27 64.2555L141.444 45.7423L140.386 34.1055Z"
        fill="#718096"
      />
      <path d="M50.621 88.2705H15.9141V88.7663H50.621V88.2705Z" fill="white" />
      <path d="M25.8298 83.8076H25.334V88.3939H25.8298V83.8076Z" fill="white" />
      <path
        d="M40.9519 83.8076H40.4561V88.3939H40.9519V83.8076Z"
        fill="white"
      />
      <path
        d="M62.7685 14.8896H28.0615V15.3855H62.7685V14.8896Z"
        fill="white"
      />
      <path d="M53.3474 15.2617H52.8516V19.848H53.3474V15.2617Z" fill="white" />
      <path d="M38.2253 15.2617H37.7295V19.848H38.2253V15.2617Z" fill="white" />
      <path
        d="M97.7226 63.7275H63.0156V64.2234H97.7226V63.7275Z"
        fill="white"
      />
      <path
        d="M88.3025 64.0996H87.8066V68.6859H88.3025V64.0996Z"
        fill="white"
      />
      <path
        d="M73.1804 64.0996H72.6846V68.6859H73.1804V64.0996Z"
        fill="white"
      />
      <path
        d="M38.7207 63.4795H4.01367V63.9753H38.7207V63.4795Z"
        fill="white"
      />
      <path
        d="M29.3005 63.8516H28.8047V68.4378H29.3005V63.8516Z"
        fill="white"
      />
      <path
        d="M14.1784 63.8516H13.6826V68.4378H14.1784V63.8516Z"
        fill="white"
      />
      <path
        d="M80.3691 38.6885H45.6621V39.1843H80.3691V38.6885Z"
        fill="white"
      />
      <path d="M55.5788 34.2266H55.083V38.8128H55.5788V34.2266Z" fill="white" />
      <path
        d="M70.7009 34.2266H70.2051V38.8128H70.7009V34.2266Z"
        fill="white"
      />
    </svg>
  );
};
