import React from "react";
import { isMobile } from "react-device-detect";
import { Label } from "./Label";

export const Labels = ({ labels, className = "" }) => {
  if (!labels || Object.keys(labels).length === 0) return null;

  const labelEntries = Object.entries(labels);
  const countLabels = labelEntries.filter(([key, val]) => val).length;

  return (
    <div
      className={`absolute top-0 right-0 flex overflow-hidden md:rounded-tr ${className}`}
      style={{ borderBottomLeftRadius: 6 }}
    >
      {labelEntries.map(([name, value]) => {
        if (!value) return null;
        return (
          <Label
            name={name}
            collapsed={isMobile ? countLabels > 1 : countLabels > 3}
            key={name}
          />
        );
      })}
    </div>
  );
};
