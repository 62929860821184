import React, { Dispatch, SetStateAction } from "react";
import { useWindowSize } from "@kanpla/system";
import { BottomDrawer, BottomDrawerProps } from "./";
import Modal, { ModalProps } from "../../modal/Modal";

type PassingProps = BottomDrawerProps & ModalProps;

interface Props extends PassingProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  drawerProps?: Partial<BottomDrawerProps>;
  modalProps?: Partial<ModalProps>;
}

export const DrawerOrModal = (props: Props) => {
  const { children, drawerProps = {}, modalProps = {} } = props;
  const { width: screenWidth } = useWindowSize();

  return screenWidth < 768 ? (
    <BottomDrawer {...props} {...drawerProps}>
      {children}
    </BottomDrawer>
  ) : (
    <Modal zIndex={props.zIndex || 60} {...props} {...modalProps}>
      {children}
    </Modal>
  );
};
