import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "rooks";

/** Compared to `scroll` event, the `wheel` event doesn't fire when the scroll is being done by JS code. */
const SCROLL_EVENT_TYPE = 'scroll';

/**
 * Returns if the user is scrolling or not. The scroll caused by code won't be counted.
 *
 * @param debounce shows how much time the user should not scroll to set the value false.
 */
export const useIsUserScrolling = (debounce = 300): boolean => {
  const [isScrolling, setScrolling] = useState(false);
  const setDebounceScrolling = useDebounce(setScrolling, debounce);
  // @ts-ignore
  const isRenderedInWebView = typeof window !== "undefined" && window.isRenderedInWebView;
  const scrollElem = isRenderedInWebView ? document.body : window;

  const listener = useCallback(() => {
    // set true without debouncing
    setScrolling(true);

    // set false with debouncing to let user finish scrolling
    setDebounceScrolling(false);
  }, [setDebounceScrolling]);

  useEffect(() => {
    scrollElem.addEventListener(SCROLL_EVENT_TYPE, listener);

    return () => scrollElem.removeEventListener(SCROLL_EVENT_TYPE, listener);
  }, [listener, scrollElem]);

  return isScrolling;
};
