import { AllergenName, Supplier, PictogramName } from "@kanpla/types";
import { isEmpty } from "lodash";
import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import SVG from "react-inlinesvg";
import { Tooltip } from "antd";

type AllergenList = {
  [key in AllergenName]?: string;
};

type PictogramList = {
  [key in PictogramName]?: string;
};

export const allergenList = (props?: AllergenList): AllergenList => {
  return {
    wheat: props ? props.wheat : "Hvede",
    celery: props ? props.celery : "Selleri",
    crustaceans: props ? props.crustaceans : "Skaldyr",
    egg: props ? props.egg : "Æg",
    fish: props ? props.fish : "Fisk",
    halal: props ? props.halal : "Halal",
    lupin: props ? props.lupin : "Lupin",
    milk: props ? props.milk : "Mælk",
    molluscs: props ? props.molluscs : "Bløddyr",
    mustard: props ? props.mustard : "Sennep",
    nuts: props ? props.nuts : "Nødder",
    peanut: props ? props.peanut : "Jordnødder",
    pork: props ? props.pork : "Svinekød",
    sesame: props ? props.sesame : "Sesam",
    soya: props ? props.soya : "Soja",
    sulphurdioxide: props ? props.sulphurdioxide : "Svovldioxid",
  };
};

export const pictogramsList = (props?: PictogramList): PictogramList => {
  return {
    sugar: props ? props.sugar : "Sukker",
    duck: props ? props.duck : "And",
    chili: props ? props.chili : "Chili",
    garlic: props ? props.garlic : "Hvidløg",
    turkey: props ? props.turkey : "Kalkun",
    deer: props ? props.deer : "Krondyr",
    chicken: props ? props.chicken : "Kylling",
    lamb: props ? props.lamb : "Lam",
    beef: props ? props.beef : "Okse",
    cheese: props ? props.cheese : "Ost",
    pig: props ? props.pig : "Gris",
    cow: props ? props.cow : "Ko",
    goat: props ? props.goat : "Ged",
    bird: props ? props.bird : "Fugl",
    vegetarian: props ? props.vegetarian : "Vegetar",
    vegan: props ? props.vegan : "Vegan",
  };
};

type Props = {
  allergenName: string;
  /** For example 'text-text-secondary' */
  colorClassName?: string;
  customBranding?: Supplier;
  layout?: "horizontal" | "vertical";
  onlyIcon?: boolean;
  className?: string;
  size?: "normal" | "large";
  /** Display either allergens or pictograms, defaults to `allergens` */
  type?: "allergens" | "pictograms";
  disabled?: boolean;
};

export const Allergen = ({
  allergenName,
  colorClassName = "text-text-secondary",
  customBranding = null,
  layout = "horizontal",
  onlyIcon = false,
  className,
  size = "normal",
  type = "allergens",
  disabled = false,
}: Props) => {
  const { t, i18n } = useTranslation(["libs"]);

  const showPictograms = type === "pictograms";

  const returnRightName = (name: string, fallback: string) =>
    isEmpty(i18n) ? fallback : t(`libs:${type}-list.${name}`);

  const dictionaries = {
    /** Allergens */
    wheat: returnRightName("wheat", "Hvede"),
    celery: returnRightName("celery", "Selleri"),
    crustaceans: returnRightName("crustaceans", "Skaldyr"),
    egg: returnRightName("egg", "Æg"),
    fish: returnRightName("fish", "Fisk"),
    halal: returnRightName("halal", "Halal"),
    lupin: returnRightName("lupin", "Lupin"),
    milk: returnRightName("milk", "Mælk"),
    molluscs: returnRightName("molluscs", "Bløddyr"),
    mustard: returnRightName("mustard", "Sennep"),
    nuts: returnRightName("nuts", "Nødder"),
    peanut: returnRightName("peanut", "Jordnødder"),
    pork: returnRightName("pork", "Svinekød"),
    sesame: returnRightName("sesame", "Sesam"),
    soya: returnRightName("soya", "Soja"),
    sulphurdioxide: returnRightName("sulphurdioxide", "Svovldioxid"),
    /** Pictograms */
    sugar: returnRightName("sugar", "Sukker"),
    duck: returnRightName("duck", "And"),
    chili: returnRightName("chili", "Chili"),
    garlic: returnRightName("garlic", "Hvidløg"),
    turkey: returnRightName("turkey", "Kalkun"),
    deer: returnRightName("deer", "Krondyr"),
    chicken: returnRightName("chicken", "Kylling"),
    lamb: returnRightName("lamb", "Lam"),
    beef: returnRightName("beef", "Okse"),
    cheese: returnRightName("cheese", "Ost"),
    pig: returnRightName("pig", "Gris"),
    cow: returnRightName("cow", "Ko"),
    goat: returnRightName("goat", "Ged"),
    bird: returnRightName("bird", "Fugl"),
    vegetarian: returnRightName("vegetarian", "Vegetar"),
    vegan: returnRightName("vegan", "Vegan"),
  };

  const label = isEmpty(i18n)
    ? showPictograms
      ? pictogramsList()[allergenName]
      : allergenList()[allergenName]
    : showPictograms
    ? pictogramsList(dictionaries)[allergenName]
    : allergenList(dictionaries)[allergenName];

  const customBrandingImage =
    customBranding?.custom?.icons?.[type]?.[allergenName];
  const image = `https://coor-version-admin.kanpla.dk/allergens/${allergenName}.svg`;

  const allergenSizeClassname =
    size === "large" ? "w-8 h-8 mr-2" : "w-5 h-5 mr-1";

  if (onlyIcon)
    return (
      <div
        key={allergenName}
        className={classnames(allergenSizeClassname, colorClassName, className)}
      >
        <Tooltip title={label}>
          <SVG
            src={customBrandingImage || image}
            width={size === "large" ? "2rem" : "1.25rem"}
            height={size === "large" ? "2rem" : "1.25rem"}
            className={`${disabled ? "opacity-40" : ""}`}
          >
            <img src={customBrandingImage || image} alt={label} />
          </SVG>
        </Tooltip>
      </div>
    );

  return (
    <span
      key={allergenName}
      className={classnames(
        "h-9 inline-flex items-center m-1 text-sm",
        colorClassName,
        layout === "vertical" && "flex-col",
        className
      )}
    >
      <div
        className={`${allergenSizeClassname} ${
          disabled ? "opacity-40" : ""
        } ${colorClassName}`}
      >
        {/* Keep SVG import, so the icons keep the right colors */}
        <SVG
          src={customBrandingImage || image}
          width={size === "large" ? "2rem" : "1.25rem"}
          height={size === "large" ? "2rem" : "1.25rem"}
        >
          <img src={customBrandingImage || image} alt={label} />
        </SVG>
      </div>
      <span className={`${disabled ? "line-through" : ""}`}>{label}</span>
    </span>
  );
};
