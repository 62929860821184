import { removeUndefinedKeys } from "@kanpla/system";
import { Form, FormProps, message } from "antd";
import { cloneDeep, get, setWith } from "lodash";
import React, { ReactNode, useEffect, useMemo, useState } from "react";

export type FormWrapperData = Record<string, any>;

export interface FormWrapperItemProps<Data> {
  data: Data;
  onValuesChange: (changedValues: Partial<Data>) => void;
  isNew: boolean;
  saving: boolean;
  /** Takes the data state object and sends it to the submit function */
  submit: () => Promise<void>;
}

interface DataToFieldsProps<Data> {
  /** Data to be reduced into Antd fields */
  data: Data;
  /** Helper function, to reduce an object with a prefix */
  reduceProps: (props: any, prefix?: string) => FormProps["fields"];
  /** Extra helper function, just pass the path from the data object */
  reducePropsPath: (path: string) => FormProps["fields"];
}

interface WrapperProps<Data> extends FormWrapperItemProps<Data> {
  children: ReactNode;
}

export interface FormWrapper<Data> {
  /** Transforms data state object into an array of field data, used by AntD */
  dataToFields: (props: DataToFieldsProps<Data>) => FormProps["fields"];
  /** Content of the view (form) */
  Item: (props: FormWrapperItemProps<Data>) => JSX.Element;
  /** Can be used to wrap the form in an element that receives item props (e.g. modal that needs to access submit for the save button) */
  Wrapper?: (props: WrapperProps<Data>) => JSX.Element;
  isNew: boolean;
  itemProps?: any;
  /** Default data to be loaded */
  defaultData?: Data;
  /** Takes the data state object and sends it to the submit function */
  submit: (data: Data) => Promise<void>;
}

const DefaultWrapper = ({ children }) => <>{children}</>;

export const FormWrapper = <Data extends FormWrapperData>({
  dataToFields,
  Item,
  Wrapper,
  isNew,
  itemProps,
  defaultData = {} as Data,
  submit = () => null,
}: FormWrapper<Data>) => {
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();
  const [triggered, setTriggered] = useState(0); // Used to update rendering (dirty hack)

  // Data
  const [data, setData] = useState<Data>(defaultData);

  const onValuesChange = (changedValues: Partial<Data>) => {
    if (!changedValues) {
      return;
    }

    setData((oldData) => {
      const newOldData = cloneDeep(oldData);
      const newData = Object.entries(changedValues).reduce(
        (acc, [path, value]) => {
          const newAcc = setWith(acc, path, value, Object);
          return newAcc;
        },
        newOldData
      );
      return newData;
    });
    setTriggered((t) => t + 1);
  };

  const defaultDataTrigger = JSON.stringify(defaultData);

  useEffect(() => {
    setData((oldData) => {
      const hasData = Object.keys(oldData || {}).length > 0;
      if (hasData) return oldData;
      return defaultData || ({} as Data);
    });
    setTriggered((t) => t + 1);
  }, [defaultDataTrigger]);

  const dataTrigger = JSON.stringify(data);

  const fields = useMemo<FormProps["fields"]>(() => {
    // Helper 1
    const reduceProps = (props, prefix?: string) => {
      return Object.entries(props || {}).reduce((acc, [name, value]) => {
        return [
          ...acc,
          {
            name: prefix ? `${prefix}.${name}` : name,
            value,
          },
        ];
      }, []);
    };

    // Helper 2
    const reducePropsPath = (path: string) => {
      const props = path ? get(data, path) : data;
      return reduceProps(props, path);
    };

    const newFields = dataToFields({ data, reduceProps, reducePropsPath });
    return newFields;
  }, [dataTrigger]);

  const handleSubmit = async () => {
    try {
      setSaving(true);
      await form.validateFields();

      if (data === {} || !data) {
        throw new Error("Ingen data!");
      }

      const cleanData = cloneDeep(data);
      removeUndefinedKeys(cleanData);
      await submit(cleanData);
      message.success("Indstillinger opdateret");
    } catch (err) {
      console.error(err);
      message.error(
        err?.errorFields ? `Du bedes udfylde de påkrævede felter` : err?.message
      );
      err?.errorFields && form.scrollToField(err?.errorFields?.[0]?.name);
    } finally {
      setSaving(false);
    }
  };

  const allItemProps: FormWrapperItemProps<Data> = {
    data,
    onValuesChange,
    isNew,
    form,
    submit: handleSubmit,
    saving,
    ...(itemProps || {}),
  };

  const FinalWrapper = Wrapper || DefaultWrapper;

  return (
    <FinalWrapper {...allItemProps}>
      <Form
        layout="vertical"
        fields={fields}
        onValuesChange={onValuesChange}
        form={form}
      >
        <Item {...allItemProps} />
      </Form>
    </FinalWrapper>
  );
};
