interface Props {
  db: any; // firebase handler
  userId: string;
  partnerId: string;
}

export const isPartnerAdmin = async (props: Props) => {
  const { db, userId, partnerId } = props;
  try {
    if (!userId) return false;

    // Allow superadmin
    const superadminRef = await db.collection("superadmins").doc(userId).get();
    if (superadminRef.exists) return true;

    // Allow partner admin
    const adminRef = await db.collection("admins").doc(userId).get();
    const adminData = adminRef.data();
    return adminData.partnerId === partnerId;
  } catch (err) {
    console.error(err);
  }
};
