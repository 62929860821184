import Link from "next/link";
import React, { ReactChild } from "react";

interface Props {
  children?: ReactChild;
  href?: string;
}

export const NextLink = ({ children, href }: Props) => {
  return (
    <Link href={href}>
      <span className="font-semibold hover:underline cursor-pointer focus:shadow-none focus:underline">
        {children}
      </span>
    </Link>
  );
};

export default NextLink;
