export const loadAsync = (
  src: string,
  callback?: () => Promise<void>,
  relative?: boolean
) => {
  const baseUrl = "/resources/script/";
  let script = document.createElement("script") as any;
  if (relative === true) {
    script.src = baseUrl + src;
  } else {
    script.src = src;
  }

  if (callback !== null) {
    if (script.readyState) {
      // IE, incl. IE9
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = function () {
        // Other browsers
        callback();
      };
    }
  }
  document.getElementsByTagName("head")[0].appendChild(script);
};
