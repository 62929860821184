import { sortBy } from "lodash";
import { Module } from "@kanpla/types";

export const sortModules = (modules: Module[]) => {
  const typeSort = ["homescreen", "flex", "mealplan", "subscription"];
  const sortedByType = sortBy(modules, (m) => typeSort.indexOf(m.type));
  const sortedByIndex = sortBy(sortedByType, (m) => m.sortIndex ?? 99);

  return sortedByIndex;
};
