import { Scope, Tree } from "@kanpla/types";
import { cloneDeep, set, unset } from "lodash";
import { getSchoolIdsFromScope } from "./getSchoolIdsFromScope";

type Props = {
  scope: Scope;
  schoolId?: string;
  productBankId?: string;
  tree: Tree;
  /** Should the school be active or not */
  active: boolean;
};

export const setIdInScope = ({
  scope,
  schoolId,
  productBankId,
  tree,
  active,
}: Props) => {
  const newScope = cloneDeep(scope);

  const recurse = (id: string, key: "schools" | "productBanks") => {
    if (active) {
      // activate itself
      set(newScope, `${key}.${id}`, {});
    } else {
      // deactivate itself
      unset(newScope, `${key}.${id}`);
    }

    const newGeneratedSchoolIds = getSchoolIdsFromScope({
      scope: newScope,
      tree,
    });

    const parentIds = Object.keys(tree?.[key]?.parents || {});

    // For each parent
    parentIds.forEach((parentId) => {
      const allSchoolIdsUnderParent = getSchoolIdsFromScope({
        scope: {
          schools: {},
          productBanks: {
            [parentId]: {},
          },
        },
        tree,
      });

      // Check if parent is fully deactivated
      const disabledAll = !allSchoolIdsUnderParent.some((id) =>
        newGeneratedSchoolIds.includes(id)
      );

      // If yes, recurse
      if (disabledAll) recurse(parentId, "productBanks");
    });
  };

  if (schoolId) recurse(schoolId, "schools");
  if (productBankId) recurse(productBankId, "productBanks");

  return newScope;
};
