import {
  useLocationHostname,
  db,
  fetchCollection,
  isReactRunningInEmulator,
} from "@kanpla/system";
import { Supplier } from "@kanpla/types";
import { parseDomain, ParseResultType } from "parse-domain";
import { useState, useEffect } from "react";

const useGetSupplier = ({ customBranding }) => {
  const [url, setUrl] = useState("");
  const [supplier, setSupplier] = useState<Supplier>(null);
  const parseResult = useLocationHostname({});

  const supplierUrl = isReactRunningInEmulator ? "company" : url;

  const fetchData = async () => {
    try {
      const suppliers =
        (url || isReactRunningInEmulator) &&
        (await fetchCollection<Supplier>(
          db.collection("suppliers").where("url", "==", supplierUrl)
        ));

      if (!suppliers || suppliers.length === 0) return;

      // return the first available supplier
      setSupplier(suppliers[0]);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (
      !isReactRunningInEmulator &&
      customBranding?.url &&
      customBranding.url === url
    )
      return;
    fetchData();
  }, [url]);

  useEffect(() => {
    const parsed = parseDomain(parseResult);
    if (parsed.type === ParseResultType.Listed) {
      const { subDomains } = parsed;
      const newUrl = subDomains.pop();
      setUrl(newUrl);
      return;
    }

    setUrl(null);
  }, [parseResult]);

  return { supplier };
};

export default useGetSupplier;
