import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faPlateUtensils,
  faUtensilsSlash,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import React from "react";
import { useTranslation } from "react-i18next";
import short from "short-uuid";
import { NewLogo } from "../others/NewLogo";
import SentryFeedback from "./SentryFeedback";

interface Props {
  title: string;
  subtitle?: string;
  statusCode?: number;
  errMessage?: string;
  /**
   * We are not using anymore router.push(linkTo) but
   * we are using the method of the router of Next.js called back().
   * This method will return to the previous route.
   */
  linkTo?: () => void;
  errorIcon?: IconProp;
}

export const ErrorComponent = (props: Props) => {
  const { t, i18n } = useTranslation(["libs", "translation"]);
  const { statusCode, title, subtitle, errMessage, linkTo, errorIcon } = props;
  const router = useRouter();

  const translator = short();
  const eventId = translator.generate();

  const dashboard = () => router.push("/dashboard");

  return (
    <>
      <div className="px-4 pt-2 h-14 wrapper">
        <NewLogo />
      </div>
      <div className="flex flex-col lg:flex-row w-full items-center justify-center lg:justify-between wrapper absolute inset-0">
        <div className="flex flex-col order-2 lg:order-1 mt-8 lg:mt-0 mb-10 w-full">
          <div className="mt-8 text-center lg:text-left">
            <h1 className="h800">{title}</h1>
            {!subtitle && (
              <p className="mt-2">
                {isEmpty(i18n)
                  ? "Der opstod en uventet fejl"
                  : t("libs:errors.unexpected-error")}
                {statusCode && (
                  <b className="text-text-primary"> ({statusCode})</b>
                )}
              </p>
            )}
            {subtitle && <p className="mt-2">{subtitle}</p>}
            {errMessage && (
              <p className="font-mono p-2 px-4 rounded-md bg-background-secondary text-text-primary inline-block mt-2">
                {errMessage}
              </p>
            )}
          </div>
          <div className="flex md:flex-row md:justify-center lg:justify-start flex-col mt-8 mb-6">
            <SentryFeedback eventId={eventId} />
            <Button
              size="large"
              className="md:ml-3 mt-4 md:mt-0"
              onClick={linkTo || dashboard}
            >
              {isEmpty(i18n) ? "Gå tilbage" : t("translation:ga-back")}
            </Button>
          </div>
        </div>
        <div className="text-primary-main order-1">
          <FontAwesomeIcon
            icon={errorIcon || faUtensilsSlash}
            className="text-9xl lg:text-icon"
          />
        </div>
      </div>
    </>
  );
};

export const UnexpectedError = ({
  statusCode,
  errMessage,
  linkTo = undefined,
}) => {
  return (
    <ErrorComponent
      title="Ooops!"
      statusCode={statusCode}
      errMessage={errMessage}
      linkTo={linkTo}
    />
  );
};

export const NotFoundError = ({ linkTo = undefined }) => {
  const { t, i18n } = useTranslation(["libs"]);
  return (
    <ErrorComponent
      title="404"
      subtitle={
        !isEmpty(i18n)
          ? t("libs:errors.404")
          : "Vi står med en tom tallerken, siden findes ikke."
      }
      linkTo={linkTo}
      errorIcon={faPlateUtensils}
    />
  );
};

export default ErrorComponent;
