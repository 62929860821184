import { rules } from "@kanpla/system";
import { Form, Input } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  value: string;
  onChange: (newValue: string) => void;
  defaultVisible?: boolean;
  disableAutocomplete: boolean;
}

export const InputPassword = (props: Props) => {
  const {
    value,
    onChange,
    defaultVisible = false,
    disableAutocomplete = false,
  } = props;
  const { t } = useTranslation(["translation"]);
  const [passwordVisible, setPasswordVisible] = useState(defaultVisible);

  return (
    <Form.Item
      name="password"
      label={t("translation:password")}
      rules={[
        rules({
          rule: "required",
          ruleValue: true,
          message: t("translation:form.errors.required-en", {
            value: t("translation:password").toLowerCase(),
          }),
        }),
        {
          min: 6,
          message: "Adgangskoden skal være på mindst 6 tegn",
        },
      ]}
    >
      <Input.Password
        id="password"
        placeholder={t("translation:password")}
        autoComplete={disableAutocomplete ? "new-password" : ""}
        onChange={(e) => onChange(e.target.value)}
        value={value}
      />
    </Form.Item>
  );
};
