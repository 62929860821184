import { db, fetchCollection, useLocationHostname } from "@kanpla/system";
import { Supplier } from "@kanpla/types";
import { changeAntdTheme } from "mini-dynamic-antd-theme";
import Head from "next/head";
import { parseDomain, ParseResultType } from "parse-domain";
import React, { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

const CompanySuppliers = () => {
  const { customBranding, setCustomBranding, setCustomBrandingLoading } =
    useContainer(AppContext);
  const [url, setUrl] = useState("");
  const [loaded, setLoaded] = useState(false);
  const parseResult = useLocationHostname({ defaultValue: undefined });

  // get data
  const fetchData = async () => {
    try {
      setCustomBrandingLoading(true);
      const suppliers =
        url &&
        (await fetchCollection<Supplier>(
          db.collection("suppliers").where("url", "==", url)
        ));

      if (!suppliers || !suppliers.length) {
        changeAntdTheme("#48BB78");
        setCustomBranding({ color: "#48BB78" } as Supplier);
        return;
      }

      const color =
        suppliers[0]?.custom?.palette?.primary?.main ||
        suppliers[0]?.custom?.color?.primary?.default ||
        suppliers[0]?.color;

      color && changeAntdTheme(color);
      setCustomBranding(suppliers[0]);
    } catch (e) {
      console.error(e);
    } finally {
      setCustomBrandingLoading(false);
    }
  };

  useEffect(() => {
    if (!loaded || url === "") return;
    if (customBranding?.url && customBranding.url === url) return;
    fetchData();
  }, [url, loaded]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    const parsed = parseDomain(parseResult);
    if (parsed.type === ParseResultType.Listed) {
      const { subDomains } = parsed;
      const newUrl = subDomains.pop();
      setUrl(newUrl);
      return;
    }

    setUrl(null);
  }, [parseResult]);

  if (!customBranding || !customBranding?.name) return null;
  const appStoreId = customBranding?.appstoreId;

  return (
    <Head>
      <title>{customBranding?.name} | powered by Kanpla</title>
      <link rel="shortcut icon" href={customBranding?.favicon} />
      {appStoreId && (
        <meta
          name="apple-itunes-app"
          content={`app-id=${appStoreId.replace("id", "")}`}
        />
      )}
    </Head>
  );
};

export default CompanySuppliers;
