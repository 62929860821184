import { Timestamp } from "@kanpla/types";
import moment from "moment";

export const timestampToMoment = (timestamp: Timestamp | undefined) => {
  if (!timestamp) return undefined;
  const momentDate =
    typeof timestamp?.seconds === `number`
      ? moment.unix(timestamp.seconds)
      : moment(timestamp.toDate());
  return momentDate;
};
