import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FontProps, FontSelectTypes, google } from "@kanpla/types";
import { Segmented } from "antd";
import React from "react";
import CustomFontUpload from "./CustomFontUpload";
import { fontTypes } from "./fontTypes";
import GoogleFontSelect from "./GoogleFontSelect";

export interface FontInputProps {
  value: FontProps;
  onChange: (value: FontProps) => void;
  placeholder?: string;
  fonts?: Array<google.fonts.WebfontFamily>;
  Head: any;
  partnerId: string;
}

export const FontInput = (props: FontInputProps) => {
  const value = props.value;
  const type = value?.type || "google";

  const changeType = (newType: FontSelectTypes) =>
    props.onChange({
      type: newType,
      family: "Inter",
      category: "sans-serif",
      variant: "500",
    });

  return (
    <div>
      <Segmented
        value={type}
        onChange={(value) => changeType(value as FontSelectTypes)}
        options={fontTypes.map((type) => ({
          value: type.value,
          label: type.label,
          icon: <FontAwesomeIcon className="mr-1" icon={type.icon} />,
        }))}
        className="mb-2"
      />
      {type === "google" && <GoogleFontSelect {...props} />}
      {type === "custom" && <CustomFontUpload {...props} />}
    </div>
  );
};
