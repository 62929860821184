import { isEmpty } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { setLabelsList } from "@kanpla/system";

const labelsList = (props?) => setLabelsList(props);

const Label = ({ name }) => {
  const { t, i18n } = useTranslation(["libs"]);
  const dictionaries = {
    vegan: t("libs:labels-list.vegan"),
    vegetarian: t("libs:labels-list.vegetarian"),
    "gluten-free": t("libs:labels-list.gluten-free"),
    "lactose-free": t("libs:labels-list.lactose-free"),
    eco: t("libs:labels-list.eco"),
    "partly-eco": t("libs:labels-list.partly-eco"),
    popular: t("libs:labels-list.popular"),
    "surplus-food": t("libs:labels-list.surplus-food"),
    halal: t("libs:labels-list.halal"),
    news: t("libs:labels-list.news"),
    "only-today": t("libs:labels-list.only-today"),
    "only-this-week": t("libs:labels-list.only-this-week"),
    "urgent-sale": t("libs:labels-list.urgent-sale"),
  };
  const background = isEmpty(i18n)
    ? labelsList()[name].background
    : labelsList(dictionaries)[name].background;
  const color = isEmpty(i18n)
    ? labelsList()[name].color
    : labelsList(dictionaries)[name].color;
  const label = isEmpty(i18n)
    ? labelsList()[name].label
    : labelsList(dictionaries)[name].label;
  return (
    <div
      className="group flex items-center"
      style={{ background, padding: "4px 8px" }}
    >
      <span
        className="text-xs uppercase font-semibold whitespace-nowrap"
        style={{ color: color || "#FFFFFF", marginRight: 2 }}
      >
        {label}
      </span>
      <div
        className=" w-4 h-4 flex justify-center"
        style={{ marginLeft: 2, marginBottom: 1 }}
      >
        <img src={`/images/labels/${name}.svg`} className="w-full" alt="" />
      </div>
    </div>
  );
};

export const InlineLabels = ({ labels }) => {
  if (!labels || Object.keys(labels).length === 0) return null;

  return (
    <div className="mt-4 inline-flex md:rounded overflow-hidden">
      {Object.entries(labels).map(([name, value]) => {
        if (!value) return null;
        return <Label name={name} key={name} />;
      })}
    </div>
  );
};
