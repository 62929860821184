import { Supplier } from "@kanpla/types";
import React from "react";
import { NotificationPreview } from "@kanpla/ui";
import { SystemScreen } from "./SystemScreen";

interface Props {
  supplier: Supplier;
  onContinue?: () => void;
}

export const PushNotificationsScreen = (props: Props) => {
  const { supplier, onContinue = () => true } = props;

  return (
    <SystemScreen
      title="Tillad Push Notifikationer"
      description="Tillad beskeder fra din kantine og vær altid opdateret på nyheder."
      previewElement={
        <NotificationPreview
          partnerId={supplier.partnerId}
          title="Besked fra kantinen"
          body="Hvilken ret vil du gerne have på fredag?"
          noBackground
        />
      }
      backgroundImageUrl="https://firebasestorage.googleapis.com/v0/b/kanpla-87be3.appspot.com/o/charlesdeluvio-Dilfan21P8o-unsplash%20(1).jpg?alt=media&token=e9edd040-9ba7-4236-85c2-e430d9c808e3"
      onContinue={onContinue}
    />
  );
};
