import React from "react";
import { DatePicker, DatePickerProps } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { isEmpty } from "lodash";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-duotone-svg-icons";

interface LocalProps {
  loading?: boolean;
  loadingText?: string;
}

type Props = LocalProps & DatePickerProps;

export const DatePanelPicker = (props: Props) => {
  const { i18n } = useTranslation();
  /**
   * Util to change the localization of moment.js
   * dow and doy explanation: https://github.com/moment/momentjs.com/issues/279
   */
  moment.updateLocale(isEmpty(i18n) ? "da" : i18n.language, {
    week: { dow: 1, doy: 4 },
  });
  return (
    <div
      className="relative overflow-hidden mx-auto z-0 branded-calendar rounded-md"
      style={{ height: 320, width: 355, maxWidth: "100%" }}
    >
      {props.loadingText && (
        <div
          className={classnames({
            "": props.loading,
            "opacity-0": !props.loading,
            "transition-opacity pointer-events-none absolute inset-0 z-max flex items-center justify-center text-text-primary":
              true,
          })}
        >
          <div className="bg-background-secondary opacity-70 absolute inset-0 z-hidden"></div>
          <p className="z-max flex items-center">
            <FontAwesomeIcon icon={faLoader} className="fa-spin mr-2" />
            {props.loadingText}
          </p>
        </div>
      )}
      <div id="KanplaDatePanelPickerContainer">
        <DatePicker
          size="large"
          popupStyle={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode as HTMLElement;
          }}
          allowClear={false}
          format={(m) => m.format("ll")}
          style={{ height: "48px" }}
          {...props}
          open
          defaultOpen
        />
      </div>
    </div>
  );
};
