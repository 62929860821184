import { Card } from "@kanpla/types";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { isSafari } from "react-device-detect";
import PaymentRow from "./PaymentRow";
import { faCcApplePay } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/pro-duotone-svg-icons";

interface Props {
  onClick: (val: string) => void;
  available: string;
  method: string | null;
  card: Card;
  /** Option to choose credit as payment from Kanpla Go  */
  basketPrice?: number;
}

const PaymentRowsWrapper = (props: Props) => {
  const { onClick, available, card, method } = props;
  const { t } = useTranslation(["payment", "translation"]);

  const [selected, setSelected] = useState(method || "card");

  // Not checking if Apple Pay is actually available because of WebView, using agents instead (less reliable but is the only solution)
  const userAgent = window.navigator.userAgent.toLowerCase();
  // @ts-ignore
  const isWebViewPWA = window.isRenderedInWebView;
  const hasApplePay =
    //(mobile && available === "applepay") ||
    isSafari || (isWebViewPWA && /iphone|ipod|ipad/.test(userAgent));

  // const hasGooglePay = available === "googlepay";

  const getCardBrandName = (type: string) => {
    if (!card) return t("payment:card");

    switch (type) {
      case "visa":
        return "Visa";
      case "mc":
        return "Mastercard";
      case "amex":
        return "American Express";
      case "dankort":
        return "Dankort";
      default:
        return t("payment:standard-card");
    }
  };

  return (
    <div className="w-full h-full">
      <PaymentRow
        name="Mobile Pay"
        value="mobilepay"
        image="MPay"
        selected={selected}
        onChange={(val) => {
          setSelected(val);
          onClick(val);
        }}
      />
      {hasApplePay && (
        <PaymentRow
          name="Apple Pay"
          value="applepay"
          imageComponent={<FontAwesomeIcon icon={faCcApplePay} size="2x" />}
          selected={selected}
          onChange={(val) => {
            setSelected(val);
            onClick(val);
          }}
        />
      )}
      {/* {hasGooglePay && (
        <PaymentRow
          name="Google Pay"
          value="googlepay"
          image="Gpay"
          selected={selected}
          onChange={(val) => {
            setSelected(val);
            onClick(val);
          }}
        />
      )} */}
      <PaymentRow
        name={getCardBrandName(card?.metadata?.brand)}
        imageComponent={<FontAwesomeIcon icon={faCreditCard} size="2x" />}
        value="card"
        detail={
          card
            ? t("translation:ends-with", { value: card.metadata.last4 })
            : null
        }
        selected={selected}
        onChange={(val) => {
          setSelected(val);
          onClick(val);
        }}
      />
    </div>
  );
};

export default PaymentRowsWrapper;
