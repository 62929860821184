import { useLocalStorage } from "@kanpla/system";
/* eslint-disable @typescript-eslint/ban-ts-comment */

interface Props {
  open: boolean;
  setLoading: (nextState: boolean) => void;
  onSuccess: () => void;
  rememberCard: boolean;
  onError: (err: { error: string }) => void;
}

const useChargeSession = (props: Props) => {
  const { setLoading, onError } = props;

  const [_, setReepaySessionId] = useLocalStorage(
    "reepay-session-id",
    null
  );

  const loadChargeSession = async (
    sessionId: string,
    isWindowSession = false,
    isMobilePay = false
  ) => {
    setLoading(true);
    setReepaySessionId(sessionId);

    try {
      // @ts-ignore
      window.rp = new Reepay.WindowCheckout(sessionId);
    } catch (err) {
      console.error(err);
      setLoading(false);
      onError(err);
      throw new Error(err);
    }
  };

  return { loadChargeSession };
};

export default useChargeSession;
