import { Admin } from "@kanpla/types";
import { fetchDocument } from "../firestore/fetchDocument";

interface Props {
  db: any; // firebase handler
  adminId?: string;
  userId: string;
  schoolId: string;
}

export const isUserAdmin = async (props: Props) => {
  const { db, adminId, userId, schoolId } = props;
  try {
    // Allow superadmin
    const superadminRef = await db.collection("superadmins").doc(userId).get();
    if (superadminRef.exists) return true;

    if (!adminId) return false;
    const admin = await fetchDocument<Admin>(
      db.collection("admins").doc(adminId)
    );
    // const school = await fetchDocument<School>(
    //   db.collection("schools").doc(schoolId)
    // );
    // const productBanks = await fetchCollection<ProductBank>(
    //   db.collection("productBanks").where("partnerId", "==", school.partnerId)
    // );

    const hasSchoolPermission =
      admin.scope?.generatedSchoolIds.includes(schoolId);

    return adminId && adminId === userId && hasSchoolPermission;
  } catch (err) {
    console.error(err);
    return false;
  }
};
