import { db, Timestamp } from "@kanpla/system";
import { Order } from "@kanpla/types";

interface Props {
  /** Unix timestamp of the midnight day to get the orders from */
  dateSeconds: number;
  moduleId: string;
  /** The schools from which to get the orders */
  schoolIds: string[];
}

/**
 * Returns all the mealplan orders for a target day in the module of the school(s)
 * @param props
 * @returns an array of `Orders`
 */
export const getMealplanOrders = async (props: Props) => {
  const { dateSeconds, moduleId, schoolIds } = props;
  const orders: Order[] = [];

  try {
    /* eslint-disable no-await-in-loop */
    for (const schoolId of schoolIds) {
      const res = await db
        .collection("orders")
        .where("schoolId", "==", schoolId)
        .where("dateSeconds", "==", dateSeconds)
        .where("moduleId", "==", moduleId)
        .get();
      const data = res.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as Order)
      );
      orders.push(...data);
    }
    /* eslint-enable no-await-in-loop */

    return orders.flat();
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};
