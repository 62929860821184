import { OrderInfo } from "@kanpla/types";
import { isEmpty } from "lodash";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  timeInput: OrderInfo["timeInput"];
}

export const TimeInputDisplay = (props: Props) => {
  const { timeInput } = props;
  const { t, i18n } = useTranslation(["libs"]);
  // Util to change the localization of moment.js
  if (!isEmpty(i18n)) moment.locale(i18n.language);

  if (!timeInput)
    return <div>{isEmpty(i18n) ? "Ingen tidspunkt" : t("libs:no-time")}</div>;

  return (
    <div>
      {isEmpty(i18n) ? "Tidspunkt:" : t("libs:time")}{" "}
      {moment.utc(timeInput * 1000).format("LT")}
    </div>
  );
};
