import { configLabel, getWeekDays } from "@kanpla/system";
import {
  CombinedOfferItem,
  FlexBulkStandard,
  OrderOrderProduct,
} from "@kanpla/types";
import { Form, message, Select } from "antd";
import { isEmpty, set, unset } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedValue } from "rooks";
import { Modal } from "../modal/Modal";

interface Props {
  /** State of the modal */
  open: boolean;
  /** Open/close the modal */
  setOpen: (newState: boolean) => void;
  /** All the products available for the standard choice */
  products: Array<CombinedOfferItem>;
  /** The current standard choice */
  standard: FlexBulkStandard["standard"];
  /** Target school */
  schoolId: string;
  /** Target school */
  moduleId: string;
  /** Current child, also used as a target for individual */
  childId: string;
  userId: string;
  /** Functions provider */
  fn: any;
  /** Individual or bulk */
  isBulk?: boolean;
  /** The edit is made by the admin */
  fromAdmin?: boolean;
  callback?: () => void;
}

export const EditStandardOrder = (props: Props) => {
  const {
    open,
    setOpen,
    products,
    standard: initialStandard,
    schoolId,
    fn,
    moduleId,
    childId,
    userId,
    isBulk,
    fromAdmin,
    callback = () => null,
  } = props;
  const { t, i18n } = useTranslation(["translation", "libs"]);
  const dictionaries = [
    t("libs:week-days.monday"),
    t("libs:week-days.tuesday"),
    t("libs:week-days.wednesday"),
    t("libs:week-days.thursday"),
    t("libs:week-days.friday"),
  ];
  const days = getWeekDays(isEmpty(i18n) ? undefined : dictionaries);
  const [standard, setStandard] =
    useState<FlexBulkStandard["standard"]>(initialStandard);

  // Load initial value
  useEffect(() => {
    if (isEmpty(standard) && !isEmpty(initialStandard))
      setStandard(initialStandard);
  }, [initialStandard]);

  // Update values
  const updateStandard = ({ amount, productId, dayIndex }) => {
    setStandard((oldValues) => {
      const newStandard = {
        ...(oldValues || {}),
      } as FlexBulkStandard["standard"];
      if (!isBulk) unset(newStandard, `${dayIndex}`);
      if (productId) set(newStandard, `${dayIndex}.${productId}`, { amount });
      return newStandard;
    });
  };

  // Submit values to db
  const submit = async () => {
    try {
      message.loading(
        isEmpty(i18n)
          ? "Standarder gemmes"
          : t("translation:message.loading.standards-are-saved"),
        0
      );
      setOpen(false);

      // Delete empty lunches before submitting
      delete standard?.[0]?.no_lunch;
      delete standard?.[1]?.no_lunch;
      delete standard?.[2]?.no_lunch;
      delete standard?.[3]?.no_lunch;
      delete standard?.[4]?.no_lunch;

      const submitToServer = fn.httpsCallable(
        isBulk ? "submitFlexBulkStandard" : "submitFlexStandard"
      );
      await submitToServer({
        standard,
        schoolId,
        moduleId,
        userId,
        childId,
        fromAdmin,
      });

      message.destroy();
      message.success(
        isEmpty(i18n)
          ? "Standarder opdateret."
          : t("translation:message.success.standards-updated")
      );
      callback();
    } catch (err) {
      console.log(err);
      message.error(
        isEmpty(i18n)
          ? "Ændringer kunne ikke gemmes."
          : t("translation:message.error.changes-could-not-be-saved")
      );
    }
  };

  const title = isBulk
    ? isEmpty(i18n)
      ? "Angiv standardbestilling"
      : t("libs:set-default-order")
    : isEmpty(i18n)
    ? "Angiv standardvalg"
    : t("translation:set-default-selection");
  const subtitle = isBulk
    ? isEmpty(i18n)
      ? "Rediger din virksomheds standardbestilling for de forskellige dage"
      : t("libs:edit-company-order")
    : isEmpty(i18n)
    ? "Rediger dit standardvalg for de forskellige dage"
    : t("libs:edit-default-choice");

  const BulkContent = () => (
    <div className="-mx-8 overflow-x-auto lg:mx-0">
      <table className="table text-left w-full">
        <thead>
          <tr>
            <th className="hidden md:table-cell"></th>
            {days.map((label, dayIndex) => (
              <th key={dayIndex}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <React.Fragment key={product.id}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <tr className="md:hidden separator">
                {/* @ts-ignore */}
                <td colSpan="100%">{product.name}</td>
              </tr>
              <tr>
                <th className="hidden md:table-cell	">{product.name}</th>
                {days.map((label, dayIndex) => (
                  <Cell
                    key={label}
                    amount={standard?.[dayIndex]?.[product.id]?.amount || 0}
                    setAmount={(amount) => {
                      updateStandard({
                        amount,
                        dayIndex,
                        productId: product.id,
                      });
                    }}
                  />
                ))}
              </tr>
            </React.Fragment>
          ))}

          <tr className="md:hidden separator">
            {/* @ts-ignore */}
            <td colSpan="100%">I alt:</td>
          </tr>
          <tr className="totals">
            <th className="hidden md:table-cell">i alt:</th>
            {days.map((label, dayIndex) => (
              <td key={dayIndex} className="bg-background-secondary">
                <div className="text-center font-semibold">
                  {Object.values(standard?.[dayIndex] || {}).reduce(
                    (a: number, c: OrderOrderProduct) => a + c.amount,
                    0
                  )}
                </div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );

  const IndividualContent = () => (
    <div className="pt-1">
      {days.map((label, dayIndex) => (
        <Day
          label={label}
          products={products}
          key={dayIndex.toString()}
          value={standard[dayIndex] || initialStandard?.[dayIndex]}
          setActive={(productId) =>
            updateStandard({ amount: 1, dayIndex, productId })
          }
        />
      ))}
    </div>
  );

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={title}
      subtitle={subtitle}
      className="max-w-xl"
      actions={[
        {
          label: isEmpty(i18n) ? "Bekræft" : t("translation:confirm"),
          onClick: submit,
          className: "primary",
        },
      ]}
    >
      {isBulk ? <BulkContent /> : <IndividualContent />}
    </Modal>
  );
};

interface CellProps {
  setAmount: (amount: number) => void;
  amount: number;
}

const Cell = ({ setAmount, amount }: CellProps) => {
  const [value, setValue] = useState(amount);
  const [debouncedValue] = useDebouncedValue<number>(value, 700);

  useEffect(() => {
    if (debouncedValue === amount) return;
    if (isNaN(debouncedValue) || debouncedValue < 0) {
      setAmount(0);
      return;
    }
    setAmount(debouncedValue);
  }, [debouncedValue]);

  return (
    <td style={{ padding: 0 }}>
      <input
        type="number"
        value={value}
        className="px-1 py-4 w-full h-full text-center focus:outline-none focus:font-semibold text-text-secondary focus:text-text-primary"
        onChange={(e) => {
          const newAmount = parseInt(e.target.value);
          setValue(newAmount);
        }}
      />
    </td>
  );
};

export const Day = ({ label, products, value, setActive }) => {
  const { t, i18n } = useTranslation(["translation"]);
  const productId = Object.keys(value || {})?.[0] || null;

  return (
    <Form.Item
      labelAlign="left"
      colon={false}
      label={label}
      labelCol={configLabel({ span: 6 })}
    >
      <Select
        value={productId || "no_lunch"}
        className={`${
          productId === null || productId === "no_lunch"
            ? "danger"
            : "secondary"
        } text-text-secondary`}
        onChange={(value) => setActive(value)}
      >
        {products.map((product) => (
          <Select.Option value={product.id} key={product.id}>
            {product.name}
          </Select.Option>
        ))}
        <Select.Option value="no_lunch">
          {isEmpty(i18n) ? "Ingen frokost" : t("translation:no-breakfast")}
        </Select.Option>
      </Select>
    </Form.Item>
  );
};
