import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { Button, ButtonProps } from "antd";

interface Props extends ButtonProps {
  label?: string;
}

export const ButtonAdd = (props: Props) => {
  const { label } = props;
  return (
    <Button htmlType="button" {...props}>
      <FontAwesomeIcon icon={faPlus} size="sm" className="" />
      {label && <span className="ml-2">{label}</span>}
    </Button>
  );
};
