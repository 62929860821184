import {
  db,
  filterModulesForChild,
  useCollectionListener,
} from "@kanpla/system";
import { Module } from "@kanpla/types";
import { useEffect, useState } from "react";

const UseModules = ({ schoolId, school, child }) => {
  // All modules available
  const [allModules] = useCollectionListener<Module>(
    db
      .collection("modules")
      .where(`scope.generatedSchoolIds`, "array-contains", schoolId)
  );

  // Modules that the child can access
  const modules =
    filterModulesForChild({ modules: allModules, school, child }) || [];

  // Current module in view
  const [moduleId, setModuleId] = useState<string | null>(null);
  const module = modules.find((m) => m.id === moduleId) || modules[0];

  // Default load moduleId
  useEffect(() => {
    if (module?.id && !moduleId) setModuleId(module?.id);
  }, [module?.id]);

  return { modules, module, moduleId, setModuleId, allModules };
};

export default UseModules;
