/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getDefaultDateSeconds } from "./getDefaultDateSeconds";
import { Timestamp } from "@kanpla/types";

interface Props {
  date?: Timestamp;
  deadline: number | false; // seconds
  deadlineWeekRelative?: boolean;
  deadlineExcludingWeekends?: boolean;
}

export const isDatePastDeadline = (props: Props) => {
  const { date, deadline, deadlineWeekRelative, deadlineExcludingWeekends } =
    props;
  if (deadline === false) return false;
  if (!date) return true;

  // Seconds of the first day available for order
  const defaultDate = getDefaultDateSeconds({
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  });

  // @ts-ignore
  const dateSeconds = date.seconds || date._seconds;

  const isPastDeadline = dateSeconds < defaultDate;

  return isPastDeadline;
};
