export const getWeekSeconds = (seconds: number) => {
  const thursdayModulus = 345600; // 4 days because 1.1.1970 is a Thursday
  const localModulus = seconds % 604800; // seconds to Thursday, 00:00
  let correctStart = seconds - localModulus + thursdayModulus; // go to the beginning of the week in CET

  // Subtract a week, if after thursday
  const shouldJumpWeekBack = localModulus < 86400 * 4;
  if (shouldJumpWeekBack) correctStart = correctStart - 604800;

  return correctStart;
};
