import { capitalizeFirstLetter } from "@kanpla/system";
import React, { ReactNode } from "react";

interface Props {
  name?: string | ReactNode;
  noMargin?: boolean;
  noMarginTop?: boolean;
  children: ReactNode;
  /** Pixels from top */
  top?: number;
}

export const Category = (props: Props) => {
  const { name = "—", children, noMargin = false, top, noMarginTop } = props;

  return (
    <div className={noMarginTop ? "" : "mt-8"}>
      <div
        className={`h400 md:text-xl ${noMargin ? "" : "px-2"} sticky ${
          top ? "" : "top-12"
        } flex items-center z-10`}
        style={{ top }}
      >
        <span className="block p-0 px-4 bg-background-primary rounded-br-lg -ml-8 pl-8 pt-6 lg:pb-0">
          {typeof name === "string" ? capitalizeFirstLetter(name) : name}
        </span>
      </div>
      {children}
    </div>
  );
};
