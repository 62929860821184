import { fb } from "../firebase.config";

export const uploadAntdFile = async ({
  file,
  onSuccess,
  onError,
  onProgress,
  path,
}) => {
  try {
    const storageRef = fb.storage().ref();
    const fileRef = storageRef.child(`${path}/${file.name}`);
    const uploadTask = fileRef.put(file);

    uploadTask.on(
      "state-changed",
      (snap) => {
        const progress = (snap.bytesTransferred / snap.totalBytes) * 100;
        onProgress(progress);
      },
      (err) => onError(err),
      async () => {
        console.log("Success");
        const url = await uploadTask.snapshot.ref.getDownloadURL();
        onSuccess(url);
      }
    );
  } catch (err) {
    console.error(err);
  }
};
