import { School, Supplier, User } from "@kanpla/types";
import { LANGUAGES } from "..";

interface Props {
  /** A user will have a language property */
  user?: User;
  /** A school will have a language property */
  school?: School;
  /** A supplier will have a language property */
  customBranding?: Supplier;
  /** To check if the navigator language is present in here */
  languages?: readonly string[];
}

export enum Entity {
  default = "default",
  customBranding = "customBranding",
  school = "school",
  user = "user",
}

const fromEnumToArray = (item: typeof Entity): Array<string> => {
  return Object.keys(item).map((key) => item[key]);
};

export const fromLevels = fromEnumToArray(Entity);
export const getLanguageLevel = (from: string): number =>
  fromLevels.indexOf(from);

type Return = {
  language?: string;
  from?: Entity.default | Entity.customBranding | Entity.school | Entity.user;
};

/**
 * Method that check the data flow to get the default language,
 * otherwise the default language will be DANISH
 */
export const checkLanguage = ({
  user,
  school,
  customBranding,
  languages = LANGUAGES,
}: Props): Return => {
  const firstPartOfTheLanguage = navigator.language.substring(
    0,
    navigator.language.indexOf("-")
  );
  switch (true) {
    case !!user?.language:
      return { language: user.language, from: Entity.user };
    case !!school?.language:
      return { language: school.language, from: Entity.school };
    case !!customBranding?.language:
      return { language: customBranding.language, from: Entity.customBranding };
    default:
      return {
        language: languages.includes(firstPartOfTheLanguage)
          ? firstPartOfTheLanguage
          : "da",
        from: Entity.default,
      };
  }
};
