import { fn } from "@kanpla/system";

// reepay functions
export const reepayPaymentChooseCall = fn?.httpsCallable(
  "reepayPaymentChooseCall"
);

// reepay firebase
export const reepayCardAdd = fn?.httpsCallable("reepayCardAdd");
export const reepayCardGetDefaultCall = fn?.httpsCallable(
  "reepayCardGetDefaultCall"
);
export const reepayCardRemove = fn?.httpsCallable("reepayCardRemove");

export const reepayCustomerCreateIfNeeded = fn?.httpsCallable(
  "reepayCustomerCreateIfNeeded"
);

export const recurringSession = fn?.httpsCallable("recurringSession");
export const getLastValidPayment = fn?.httpsCallable("getLastValidPayment");
// orders history
export const getOrdersHistory = fn?.httpsCallable("getOrdersHistory");

export const getInvoiceAmount = fn?.httpsCallable("getInvoiceAmount");
