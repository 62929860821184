export const setLabelsList = (props?) => ({
  vegan: {
    background: `linear-gradient(107.57deg, #48BB78 -18.83%, #38A169 124.05%)`,
    label: props ? props.vegan : "Vegan",
  },
  vegetarian: {
    background: `linear-gradient(107.57deg, #48BB78 -18.83%, #38A169 124.05%)`,
    label: props ? props.vegetarian : "Vegetar",
  },
  glutenFree: {
    background: `linear-gradient(114.18deg, #ED8936 -19.33%, #D69E2E 131.99%)`,
    label: props ? props["gluten-free"] : "Glutenfri",
  },
  lactoseFree: {
    background: `linear-gradient(114.18deg, #4299E1 -19.33%, #3182CE 131.99%)`,
    label: props ? props["lactose-free"] : "Lactosefri",
  },
  organic: {
    background: `linear-gradient(114.18deg, #E30613 -19.33%, #E30613 131.99%)`,
    label: props ? props.eco : "Øko",
  },
  almostOrganic: {
    background: `linear-gradient(114.18deg, #E30613 -19.33%, #E30613 131.99%)`,
    label: props ? props["partly-eco"] : "Delvist Øko",
  },
  popular: {
    background: `linear-gradient(114.18deg, #ECC94B -19.33%, #D69E2E 131.99%)`,
    label: props ? props.popular : "Populær",
  },
  leftovers: {
    background: `linear-gradient(114.18deg, #48BB78 -19.33%, #38A169 131.99%)`,
    label: props ? props["surplus-food"] : "Overskudsmad",
  },
  halal: {
    background: `linear-gradient(114.18deg, #FFFFFF -19.33%, #FFFFFF 131.99%)`,
    color: "#2F855A",
    label: props ? props.halal : "Halal",
  },
  new: {
    background: `linear-gradient(114.18deg, rgba(121,166,255,1) -19.33%, rgba(0,117,208,1) 131.99%)`,
    label: props ? props.news : "Nyhed",
  },
  onlyToday: {
    background: `linear-gradient(114.18deg, #ECC94B -19.33%, #D69E2E 131.99%)`,
    label: props ? props["only-today"] : "Kun i dag",
  },
  onlyThisWeek: {
    background: `linear-gradient(114.18deg, #ECC94B -19.33%, #D69E2E 131.99%)`,
    label: props ? props["only-this-week"] : "Kun i denne uge",
  },
  urgentSale: {
    background: `linear-gradient(114.18deg, rgba(192,159,255,1) -19.33%, rgba(176,93,255,1) 131.99%)`,
    label: props ? props["urgent-sale"] : "Først til mølle",
  },
  contactKitchen: {
    background: `linear-gradient(114.18deg, rgba(192,159,255,1) -19.33%, rgba(176,93,255,1) 131.99%)`,
    label: props ? props["contact-kitchen"] : "Kontakt Køkken",
  },
});
