import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";

interface Props {
  /** The event date in seconds  */
  eventTime: number;
  /** The interval of the timer in seconds  */
  interval: number;
}

const calculateDuration = (eventTime) =>
  moment.duration(
    Math.max(eventTime - Math.floor(Date.now() / 1000), 0),
    "seconds"
  );

export const useCountdown = (props: Props) => {
  const { eventTime, interval } = props;
  const [duration, setDuration] = useState(calculateDuration(eventTime));
  const timerRef = useRef(null);

  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(eventTime));
  }, [eventTime]);

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, interval * 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [eventTime]);

  return {
    days: duration.days(),
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
  };
};
