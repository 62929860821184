import { SwipeableDrawer } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { BottomSheet } from "../BottomSheet";
import { Actions } from "./Actions";
import { ActionType } from "../../modal/Modal";

export interface BottomDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  children: React.ReactChild;
  title?: string;
  subtitle?: string;
  showCloseButton?: boolean;
  drawerClassName?: string;
  useOldDrawer?: boolean;
  zIndex?: number;
  noPadding?: boolean;
  actions?: Array<ActionType>;
  floatingActions?: boolean;
}

export const BottomDrawer = (props: BottomDrawerProps) => {
  const {
    open,
    setOpen,
    children,
    title,
    subtitle,
    showCloseButton = false,
    drawerClassName = "bg-background-primary",
    useOldDrawer = false,
    zIndex = 40,
    actions,
    noPadding,
    floatingActions = false,
  } = props;

  if (useOldDrawer)
    return (
      <BottomSheet
        open={open}
        setOpen={setOpen}
        onDismiss={() => setOpen(false)}
        showCloseButton
        dismissableHeader
      >
        {children}
      </BottomSheet>
    );

  const hasActions = actions?.length > 0;

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      className="kanpla-bottom-drawer relative"
      style={{ zIndex }}
      PaperProps={{
        elevation: 0,
        style: {
          backgroundColor: "transparent",
          paddingTop: "10rem",
          zIndex: 9,
        },
      }}
    >
      <div
        className="bg-transparent w-full h-40 absolute inset-x-0 top-0"
        onClick={() => setOpen(false)}
      />
      <div
        className={`${drawerClassName} relative w-full pb-6`}
        style={{ borderRadius: "24px 24px 0 0" }}
      >
        {showCloseButton && (
          <CloseButton
            onClick={() => setOpen(false)}
            className="absolute top-4 right-4 z-50"
          />
        )}
        <header className="p-3 flex items-center justify-center relative z-50">
          <span className="block bg-background-secondary rounded-lg w-12 h-1"></span>
        </header>
        <main className="w-full flex flex-col justify-center mt-3 relative ">
          {title && (
            <h1 className="h700 text-primary-main text-center px-6 relative z-10">
              {title}
            </h1>
          )}
          {subtitle && (
            <h3 className="text-text-secondary text-center px-6">{subtitle}</h3>
          )}
          <div className={`${noPadding ? "p-0" : "pb-6 px-6 pt-6"}`}>
            {children}
          </div>
        </main>

        {hasActions && (
          <div
            className={`${
              floatingActions ? "sticky" : "relative"
            } bottom-4 mb-6 w-full bg-gradient-to-b from-main-transparent to-main-500`}
            style={{
              paddingBottom: `calc(var(--safe-inset-bottom) + 20px)`,
            }}
          >
            <div className="flex flex-wrap justify-center whitespace-nowrap w-full p-2 overflow-hidden">
              <Actions actions={actions} />
            </div>
          </div>
        )}
      </div>
    </SwipeableDrawer>
  );
};

const CloseButton = ({ onClick, className = "" }) => {
  return (
    <div
      className={`rounded-full bg-background-secondary hover:opacity-60 transition-opacity ease-in-out text-text-primary flex justify-center items-center p-4 w-8 h-8 cursor-pointer ${className}`}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faTimes} />
    </div>
  );
};
