import { faCameraSlash } from "@fortawesome/pro-duotone-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { priceFormatter } from "@kanpla/system";
import { School, Supplier } from "@kanpla/types";
import { isEmpty } from "lodash";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { FlexModule } from "../../flex/FlexModule";
import { Image } from "../Image";
import { Labels } from "../Labels";
import { ProductProps } from "./Product";
import { ProductPreviewRow } from "./ProductPreviewRow";

interface PreviewProps
  extends Pick<
    ProductProps,
    "product" | "isBig" | "disabled" | "stock" | "previewOnly"
  > {
  className?: string;
  children?: ReactNode;
  view?: "grid" | "table" | "menu";
  schools?: School[];
  isSelected?: boolean;
  showLabels?: boolean;
  onClick?: () => void;
  currentDayTimestamp?: number;
  customBranding?: Supplier;
}

export const ProductPreview = (props: PreviewProps) => {
  const {
    product,
    onClick = () => null,
    isBig = false,
    disabled = false,
    className = "",
    children = <></>,
    view,
    schools = [],
    isSelected = false,
    showLabels = true,
    stock = null,
    previewOnly,
    currentDayTimestamp,
    customBranding,
  } = props;

  const { t, i18n } = useTranslation(["libs"]);

  // For display more information about the product in the current day of a menu
  const currentDay = product?.dates?.[currentDayTimestamp];

  const handleClick = () => !disabled && onClick();

  const hasDiscount = product?.originalPrice || null;
  // For leftovers products with no discount (already applied to price)
  const discountIsSameAsPrice = product?.originalPrice === product?.price;
  const showDiscountUI = !discountIsSameAsPrice && hasDiscount;

  if (view === "menu")
    return (
      <FlexModule
        product={product}
        onClick={handleClick}
        dateSeconds={currentDayTimestamp}
        customBranding={customBranding}
      />
    );

  if (view === "table")
    return (
      <ProductPreviewRow
        product={product}
        onClick={onClick}
        disabled={disabled}
        className={className}
        schools={schools}
      >
        {children}
      </ProductPreviewRow>
    );

  const showStock =
    product?.isLeftover && stock !== Infinity && stock !== 0 && !isNaN(stock);

  const stockBg = () => {
    if (stock < 4) return "bg-danger-main";
    if (stock > 3 && stock < 6) return "bg-yellow-400";
    return "bg-primary-main";
  };

  return (
    <div
      onClick={handleClick}
      id={`product_preview_${product?.id}`}
      className={`w-full h-full flex flex-col justify-start relative group ${className} ${
        disabled ? "cursor-not-allowed" : previewOnly ? "" : "cursor-pointer"
      }`}
    >
      <div
        className="rounded-lg"
        style={{
          boxShadow:
            "0px 4px 84px rgba(0, 0, 0, 0.02), 0px 4px 14px -4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          className={`h-0 w-full overflow-hidden relative rounded-lg bg-gradient-to-bl from-gray-300 to-background-secondary`}
          style={{
            paddingBottom: isBig ? "54%" : "60%",
            WebkitMaskImage: "-webkit-radial-gradient(white, black)",
            backgroundImage: product?.color
              ? `linear-gradient(${product.color} 28.13%, ${product.color} 100%)`
              : `linear-gradient(rgba(0, 0, 0, 0) 28.13%, rgba(0, 0, 0, 0.45) 100%)`,
          }}
        >
          {isSelected && (
            <div className="absolute z-10 top-0 left-0 w-auto h-auto bg-primary-main rounded-br-md px-4 py-2 transition-all ease-in">
              <FontAwesomeIcon icon={faCheck} color="#FFFFFF" />
            </div>
          )}
          <div
            className={`absolute inset-0 transition-all duration-500 ${
              disabled
                ? "grayscale filter cursor-not-allowed opacity-60"
                : previewOnly
                ? ""
                : "cursor-pointer"
            } ${isSelected && "opacity-50"}`}
          >
            {product?.photo ? (
              <Image
                src={`${product.photo}?w=400`}
                alt={currentDay?.menu?.name || product?.name}
                className={`w-full ${
                  disabled ? "" : "group-hover:scale-105"
                } transform object-cover object-center rounded-md absolute inset-0 h-full transition-transform `}
                style={{ transition: "transform 0.2s ease" }}
                dataCy="product-img"
              />
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                <FontAwesomeIcon icon={faCameraSlash} size="2x" />
              </div>
            )}
          </div>

          {isBig && (
            <div
              className={`absolute inset-0 rounded-lg ${
                disabled
                  ? "cursor-not-allowed"
                  : previewOnly
                  ? ""
                  : "cursor-pointer"
              }`}
              style={{
                backgroundImage:
                  product.color ||
                  `linear-gradient(rgba(0, 0, 0, 0.08) 28.13%, rgba(0, 0, 0, 0.6) 100%)`,
              }}
            />
          )}

          {showLabels && <Labels labels={product?.labels} />}
          {showStock && !isBig && (
            <div className={`absolute bottom-2 right-2`}>
              <div
                className={`px-2 py-1 ${stockBg()} text-sm rounded-md text-background-primary flex items-center`}
              >
                {!isEmpty(i18n)
                  ? t("libs:value-back", { value: stock })
                  : `${stock} tilbage`}
              </div>
            </div>
          )}
        </div>
      </div>
      {isBig ? (
        <div className="absolute py-4 px-5 bottom-0 left-0">
          {showStock && (
            <div className="w-auto flex mb-1">
              <div
                className={`px-2 py-1 ${stockBg()} text-sm rounded-md text-background-primary`}
              >
                {!isEmpty(i18n)
                  ? t("libs:value-back", { value: stock })
                  : `${stock} tilbage`}
              </div>
            </div>
          )}
          <h3 className="text-white sm:text-lg pt-2 leading-tight font-medium">
            {currentDay?.menu?.name || product?.name}
          </h3>
          <div className="flex items-baseline">
            <p
              className={`text-white ${
                showDiscountUI ? "line-through" : ""
              } text-sm md:text-base font-semibold pt-1`}
            >
              {priceFormatter(
                showDiscountUI ? hasDiscount : (product?.price as number),
                { language: i18n?.language }
              )}
            </p>
            {showDiscountUI && (
              <p className="text-text-primary ml-2 font-semibold text-xs sm:text-sm pt-1">
                {priceFormatter(
                  product.price as number,
                  { language: i18n?.language },
                  product.multiplePrices
                )}
                {product.extraPriceLabelText || ""}
              </p>
            )}
          </div>
        </div>
      ) : (
        <div>
          <h3 className="text-text-primary font-semibold pt-2 leading-tight">
            {currentDay?.menu?.name || product?.name}
          </h3>
          <div className="flex items-baseline">
            <p
              className={`${
                showDiscountUI
                  ? "text-text-disabled line-through"
                  : "text-primary-main"
              } text-sm pt-1`}
            >
              {priceFormatter(
                (showDiscountUI ? hasDiscount : product?.price) as number,
                { language: i18n?.language },
                product?.multiplePrices
              )}
              {product.extraPriceLabelText || ""}
            </p>
            {showDiscountUI && (
              <p className="text-primary-main text-sm font-semibold ml-2">
                {priceFormatter(hasDiscount as number, {
                  language: i18n?.language,
                })}
              </p>
            )}
          </div>
        </div>
      )}

      {children}
    </div>
  );
};
