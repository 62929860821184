import { FirestoreDocument } from "@kanpla/types";
import { isEmpty } from "lodash";
import { db, fs } from "../firebase.config";
import { fetchCollection } from "./fetchCollection";
import { fetchDocument } from "./fetchDocument";

interface Options {
  db: any;
}

/**
 * Fetch multiple documents in the same collection by id
 * @typeParam T You can define a generic type here
 * @param collection The name of the collection of the query
 * @param docIds An array of document ids
 * @returns An array of documents data
 */
export const fetchMultipleDocuments = async <T extends FirestoreDocument>(
  collection: string,
  docIds: string[],
  options?: Options
): Promise<Array<T>> => {
  try {
    if (isEmpty(docIds)) return [];

    // If we need to fetch more than 10 documents
    const dataInPromises = docIds.flat().map((id) =>
      fetchDocument<T>(
        (options?.db || db).collection(collection).doc(id),
        false,
        {
          allowUnknown: true,
        }
      )
    );

    return Promise.all(dataInPromises);
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

export default fetchMultipleDocuments;
