/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from "react";
import { IntercomAPI } from "react-intercom";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

const UseIntercom = () => {
  const { school, child, menuOpen } = useContainer(AppContext);
  // Intercom bubble
  useEffect(() => {
    try {
      // @ts-ignore
      window.webkit?.messageHandlers?.intercomBubbleListener?.postMessage(
        "hide"
      );

      IntercomAPI("update", {
        hide_default_launcher: true,
      });
    } catch (err) {
      console.log(err);
    }

    // }
  }, [menuOpen]);

  // Send user information to intercom
  useEffect(() => {
    if (!window) return;

    // @ts-ignore
    window.intercomSettings = {
      // @ts-ignore
      ...window.intercomSettings,
      childName: child?.name || null,
      childId: child?.child_ref?.id || null,
    };
  }, [child]);

  useEffect(() => {
    if (!school) return;

    // @ts-ignore
    window.intercomSettings = {
      // @ts-ignore
      ...window.intercomSettings,
      schoolName: school?.name || null,
      schoolContactPhone: school?.contact?.tel || null,
      schoolContactEmail: school?.contact?.email || null,
      schoolContactName: school?.contact?.name || null,
    };
  }, [school]);

  return null;
};

export default UseIntercom;
