import { Child, ProductBank, School } from "@kanpla/types";
import { isUserAdmin } from "./isAdmin";

interface Props {
  db: any; // firebase handler
  adminId?: string;
  userId: string;
  childId: string;
}

export const isChildAdmin = async (props: Props): Promise<boolean> => {
  const { db, adminId = "", userId, childId } = props;

  const childRef = db.collection("children").doc(childId);
  const childData = await childRef.get().then((ch) => ch.data() as Child);
  const schoolId = childData.school_ref.id || childData.schoolId;
  const isAdmin = await isUserAdmin({
    schoolId,
    adminId,
    userId,
    db,
  });

  return isAdmin;
};
