import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addWeek,
  getWeekArray,
  subtractWeek,
  weekToDisplayString,
} from "@kanpla/system";
import { Week } from "@kanpla/types";
import { Button, DatePicker } from "antd";
import { PickerBaseProps } from "antd/lib/date-picker/generatePicker";
import moment, { Moment } from "moment";
import React, { Dispatch, SetStateAction } from "react";
import { useKey } from "rooks";

interface Props extends Partial<PickerBaseProps<Moment>> {
  /** Timestamp provider from the fb library */
  Timestamp: any;
  week: Week;
  setWeek: Dispatch<SetStateAction<Week>>;
  hasArrows?: boolean;
  className?: string;
  navigateWithArrowKeys?: boolean;
}

export const WeekSwitch = (props: Props) => {
  const {
    Timestamp,
    week,
    setWeek,
    hasArrows,
    className = "",
    navigateWithArrowKeys = false,
  } = props;

  const adjustWeek = (ahead: boolean) => {
    const newWeek = ahead ? addWeek(week) : subtractWeek(week);
    return setWeek(newWeek);
  };

  // Disabled for now, was causing issue

  // useKey("ArrowRight", () => {
  //   if (!navigateWithArrowKeys) return;
  //   adjustWeek(true);
  // });

  // useKey("ArrowLeft", () => {
  //   if (!navigateWithArrowKeys) return;
  //   adjustWeek(false);
  // });

  return (
    <div
      className={`border border-divider-main rounded-md flex items-center hover:border-primary-main overflow-hidden transition-colors ${className}`}
    >
      {hasArrows && (
        <Button
          className="border-r rounded-none -my-px flex items-center hover:text-primary-dark border-divider-main border-l-0 hover:bg-primary-light"
          type="text"
          onClick={() => adjustWeek(false)}
        >
          <FontAwesomeIcon icon={faChevronLeft} size="xs" />
        </Button>
      )}
      <DatePicker
        value={moment.utc(week[0].seconds * 1000)}
        onChange={(newValue) => {
          const newSeconds = newValue.utc().startOf("week").valueOf() / 1000;
          const newWeek = getWeekArray(newSeconds, Timestamp);
          setWeek(newWeek);
        }}
        allowClear={false}
        suffixIcon={!hasArrows}
        format={(m) => weekToDisplayString(week)}
        picker="week"
        bordered={false}
        dropdownAlign={{ points: ["tc", "bc"] }}
        style={{ border: 0, width: 150 }}
        {...props}
      />
      {hasArrows && (
        <Button
          className="border-l rounded-none -my-px flex items-center hover:text-primary-dark border-divider-main border-r-0 hover:bg-primary-light"
          type="text"
          onClick={() => adjustWeek(true)}
        >
          <FontAwesomeIcon icon={faChevronRight} size="xs" />
        </Button>
      )}
    </div>
  );
};
