/* eslint-disable @typescript-eslint/ban-ts-comment */
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/analytics";
import "firebase/compat/messaging";

import * as configJson from "./fb.json";
import { getAuth, connectAuthEmulator } from "firebase/auth";

export const config = configJson;

export const isReactRunningInEmulator = process.env.NODE_ENV === "development";
// export const isReactRunningInEmulator = false;

const initializeApp = () => {
  try {
    const newApp = firebase.initializeApp(config);

    if (
      (typeof window !== `undefined` && window["Cypress"]) ||
      isReactRunningInEmulator
    ) {
      // Emulate Functions
      newApp.functions("europe-west1").useEmulator("localhost", 5001);

      // Emulate Auth
      const newAuth = getAuth(fb);
      connectAuthEmulator(newAuth, "http://localhost:9099");

      // Emulate Storage
      const storage = firebase.storage();
      storage.useEmulator("localhost", 8100);

      // Emulate Firestore
      const firestoreSettings = {
        host: "localhost:8080",
        ssl: false,
        experimentalForceLongPolling: true,
      };
      // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
      // @ts-ignore
      console.debug(`Using Firestore emulator: ${firestoreSettings.host}`);

      firebase.firestore().settings(firestoreSettings);

      // firebase.firestore().useEmulator("localhost", 8080);
    }

    return newApp;
  } catch (err) {
    console.log("Config error:", err);
  }
};

console.log("###\n\n\n", firebase?.apps?.length);

export const fb = !firebase?.apps?.length ? initializeApp() : firebase.app();

export const auth = getAuth(fb);

// Emulator!!!
// fb.functions("europe-west1").useEmulator("localhost", 5001);
export const fn = fb?.functions("europe-west1");

export const Timestamp = firebase.firestore.Timestamp;
export const FieldValue = firebase.firestore.FieldValue;

export const db = firebase.firestore();

export const fs = firebase.firestore;
// db.enablePersistence()

export const storage = firebase.storage();

// Start analytics (only if window is defined)
if (typeof window !== `undefined` && "measurementId" in config) {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isMobileiOS =
    // @ts-ignore
    window.isRenderedInWebView || /iphone|ipod|ipad/.test(userAgent);

  // @ts-ignore
  if (isMobileiOS && window.trackingPermissionsGranted) {
    firebase.analytics();
  }

  if (!isMobileiOS) {
    firebase.analytics();
  }
}

export { firebase };

/** Constructor of type-safe onCall functions */
export const callFunction = async <FunctionType extends (data: any) => any>(
  functionName: string,
  props: Parameters<FunctionType>[0]
) => {
  const callable = fn.httpsCallable(functionName);
  const res = await callable(props);
  const data = res.data as Promise<ReturnType<FunctionType>>;
  return data;
};
