import { OrderOrderProduct } from "@kanpla/types";
import React, { useContext, useEffect } from "react";
import { VariantsContext } from ".";
import { InputAmount } from "../../inputs/Amount";

const NoLayer = () => {
  const { product, orderProduct, order, setOrder, maxAmount } =
    useContext(VariantsContext);

  const productAmount = orderProduct?.amount ?? 1;

  const setProductAmount = (newAmount: number) => {
    const newProduct: OrderOrderProduct = {
      name: product.name,
      amount: newAmount,
      price: 0,
    };

    setOrder({
      ...order,
      [product.productId]: newProduct,
    });
  };

  useEffect(() => {
    const orderProduct = order?.[product?.productId];
    if (orderProduct?.amount > 1) return;

    setProductAmount(1);
  }, []);

  return (
    <div>
      <InputAmount
        amount={productAmount}
        setAmount={setProductAmount}
        minAmount={1}
        maxAmount={maxAmount}
      />
    </div>
  );
};

export default NoLayer;
