import { useLocationHostname } from "@kanpla/system";
import { useEffect } from "react";
import { hotjar } from "react-hotjar";

export const HotJar = () => {
  const hotjarIds = {
    "serwiz.kanpla.dk": "2864708",
    "kanpla.dk": "2206586",
    "admin.kanpla.dk": "2736081",
  };
  const hostname = useLocationHostname({ defaultValue: null });
  const id = hotjarIds?.[hostname];

  useEffect(() => {
    if (!id) return;

    hotjar.initialize(id, 6);

    //   const script = document.createElement("script");

    //   const widgetCode = `(function(h,o,t,j,a,r){
    //   h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    //   h._hjSettings={hjid:${id},hjsv:6};
    //   a=o.getElementsByTagName('head')[0];
    //   r=o.createElement('script');r.async=1;
    //   r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    //   a.appendChild(r);
    // })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    // `;

    //   script.innerHTML = widgetCode;
    //   document.body.appendChild(script);
  }, [id]);

  return null;
};
