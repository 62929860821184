import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAppleWhole,
  faCartShopping,
  faCroissant,
  fad,
  faHouse,
  faPlateUtensils,
  IconName,
  IconPrefix,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BreakfastIcon,
  HomeIcon,
  LunchIcon,
  SnacksIcon,
  TakeawayIcon,
} from "@kanpla/ui";
import { isArray } from "lodash";
import React, { ReactNode } from "react";
import { size } from "../../icons/IconSizes";

library.add(fad);

export interface ModuleIcon {
  name: string;
  icon: ReactNode;
  key: string;
}

export const getTabIcons = (size?: size, color?: string) => [
  {
    name: "Home",
    icon: <FontAwesomeIcon icon={faHouse} />,
    // icon: <HomeIcon size={size} color={color} />,
    key: "home",
  },
  {
    name: "Lunch",
    icon: <FontAwesomeIcon icon={faPlateUtensils} />,
    // icon: <LunchIcon size={size} color={color} />,
    key: "lunch",
  },
  {
    name: "Breakfast",
    icon: <FontAwesomeIcon icon={faCroissant} />,
    // icon: <BreakfastIcon size={size} color={color} />,
    key: "breakfast",
  },
  {
    name: "Snacks",
    icon: <FontAwesomeIcon icon={faAppleWhole} />,
    // icon: <SnacksIcon size={size} color={color} />,
    key: "snacks",
  },
  {
    name: "Takeaway",
    icon: <FontAwesomeIcon icon={faCartShopping} />,
    // icon: <TakeawayIcon size={size} color={color} />,
    key: "takeaway",
  },
];

export const tabIcons: ModuleIcon[] = [
  {
    name: "Home",
    icon: <HomeIcon color="currentColor" />,
    key: "home",
  },
  {
    name: "Lunch",
    icon: <LunchIcon color="currentColor" />,
    key: "lunch",
  },
  {
    name: "Breakfast",
    icon: <BreakfastIcon color="currentColor" />,
    key: "breakfast",
  },
  {
    name: "Snacks",
    icon: <SnacksIcon color="currentColor" />,
    key: "snacks",
  },
  {
    name: "Takeaway",
    icon: <TakeawayIcon color="currentColor" />,
    key: "takeaway",
  },
];

interface Props {
  name: string;
  iconKey: string | [IconPrefix, IconName];
  active?: boolean;
  disabled?: boolean;
  isFirst?: boolean;
  className?: string;
  truncateText?: boolean;
}

export const ModuleTab = (props: Props) => {
  const {
    name,
    iconKey,
    active = false,
    disabled = false,
    className = "",
    truncateText = false,
  } = props;

  const icon = !isArray(iconKey) ? (
    tabIcons.find((icon) => icon.key === iconKey)?.icon
  ) : (
    <FontAwesomeIcon icon={iconKey} />
  );

  return (
    <div
      className={`flex items-center flex-nowrap justify-center w-auto px-6 py-2 rounded-full transition-colors ${
        active ? "bg-gray-300" : ""
      } ${className}`}
    >
      {icon}
      <h1
        className={`ml-2 text-lg font-semibold text-text-secondary whitespace-nowrap truncate`}
        style={truncateText ? { maxWidth: 150 } : {}}
      >
        {name}
      </h1>
    </div>
  );
};
