import { Module, DayIndex } from "@kanpla/types";

export const isProductAvailable = (
  productId: string,
  module: Module,
  schoolId: string,
  dayIndex?: DayIndex
) => {
  // In case dayIndex is specified
  if (typeof dayIndex === `number`)
    return (
      module?.products?.[productId]?.active &&
      !module.products?.[productId]?.days?.[dayIndex]?.disabled &&
      !module.products?.[productId]?.schools?.[schoolId]?.disabled &&
      !module.products?.[productId]?.schools?.[schoolId]?.days?.[dayIndex]
        ?.disabled
    );

  // Otherwise, ignore dayIndex
  return (
    module?.products?.[productId]?.active &&
    !module.products?.[productId]?.schools?.[schoolId]?.disabled
  );
};
