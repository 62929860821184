import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";

export const useAppPadding = () => {
  const [isNewWrapper, setIsNewWrapper] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window === "undefined" || !isMobile) return;
    // @ts-ignore
    if (window?.isRenderedInWebView) setIsNewWrapper(true);
  }, []);

  return { shouldAddPadding: !isNewWrapper };
};
