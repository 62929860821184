export const redirectVismaAuth = (
  client_id: string,
  scope: Array<string> = [],
  redirect_uri: string,
  code_challenge: string,
  state: Object = {},
  navigateTo: string
) => {
  const builtURL = (window.location.href = `https://connect.visma.com/connect/authorize?client_id=${client_id}&response_type=code&response_mode=query&scope=${scope.join(
    " "
  )}&redirect_uri=${redirect_uri}&code_challenge=${code_challenge}&state=${JSON.stringify(
    { ...state, navigateTo }
  )}&code_challenge_method=S256`);

  return builtURL;
};
