import { faUpload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { storage } from "@kanpla/system";
import { Button, message, Upload } from "antd";
import React from "react";
import { FontInputProps } from ".";

const CustomFontUpload = ({
  Head,
  value,
  fonts,
  onChange,
  placeholder,
  partnerId,
}: FontInputProps) => {
  const beforeUpload = (file) => {
    console.log(file);
    const isWoff2 = file.name.includes(".woff2");
    if (!isWoff2) {
      message.error("Du kan kun uploade .woff2 filer");
    }
    return true;
  };

  const upload = async ({
    onError,
    onSuccess,
    file,
    onProgress,
  }: {
    onError?: (error: any) => void;
    onSuccess?: (success: any, task: any) => void;
    onProgress?: (progress: any) => void;
    file: any;
  }) => {
    try {
      const storageRef = storage.ref();
      const timestamp = new Date().getTime();

      const fileRef = storageRef.child(
        `branding/fonts/${partnerId}/${timestamp}`
      );

      const uploadTask = fileRef.put(file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snap) =>
            onProgress &&
            onProgress({
              percent: (snap.bytesTransferred / snap.totalBytes) * 100,
            }),
          (err) => {
            onError && onError(err);
            reject();
          },
          async () => {
            const uploadedRef = uploadTask.snapshot.ref;
            const url = await uploadedRef.getDownloadURL();
            const name = uploadedRef.name;

            onChange({
              family: `CUSTOMFONT_${name}`,
              category: "sans-serif",
              variant: "regular",
              src: url,
              type: "custom",
            });
            message.success("Font er uploadet");
            resolve(url);
          }
        );
      });
    } catch (e) {
      console.error(e);
      message.error(e?.message);
    }
  };

  const props = {
    name: "customFont",
    accept: "font/woff2",
    beforeUpload,
    customRequest: upload,
    showUploadList: false,
    // onChange(info) {
    //   if (info.file.status !== "uploading") {
    //     console.log(info.file, info.fileList);
    //   }
    //   if (info.file.status === "done") {
    //     message.success(`${info.file.name} file uploaded successfully`);
    //   } else if (info.file.status === "error") {
    //     message.error(`${info.file.name} file upload failed.`);
    //   }
    // },
  };

  return (
    <Upload {...props}>
      <Button className="text-sm">
        <FontAwesomeIcon icon={faUpload} className="mr-2" />
        {value
          ? `Uploaded as ${value?.family}`
          : "Click to upload (only .woff2)"}
      </Button>
    </Upload>
  );
};

export default CustomFontUpload;
