import { useFetchDocument } from "@kanpla/system";
import { School } from "@kanpla/types";
import { useLocalstorageState } from "rooks";

export const useSchool = () => {
  const [schoolId, setSchoolId] = useLocalstorageState<string | null>(
    "kanpla:schoolId",
    null
  );
  const [school] = useFetchDocument<School>({
    collection: "schools",
    id: schoolId,
  });

  const { partnerId = null } = school || {};

  return { school, schoolId, setSchoolId, partnerId };
};
