import React, { useState, useEffect } from "react";

// Returns three dots that animate to indicate loading...

export const DotDotDot = () => {
  const [text, setText] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setText((text) => (text.length < 3 ? text + "." : ""));
    }, 250);

    return () => clearInterval(interval);
  }, []);

  return <span>{text}</span>;
};
