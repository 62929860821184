import { DateTime } from "luxon";
import {
  flexBulkOrder,
  OrderChange,
  OrderOrder,
  Timestamp,
} from "@kanpla/types";

interface Props {
  order: flexBulkOrder;
  date: Timestamp;
  deadline: number;
  softDeadlineMaxAmount: number;
  localOrder: OrderOrder;
}

export const getLastFlexOrderChange = (props: Props) => {
  const {
    order,
    date,
    deadline,
    softDeadlineMaxAmount,
    localOrder = {},
  } = props;

  try {
    const changes = order?.changes || [];

    const CETOffset = DateTime.local().setZone("Europe/Copenhagen").offset * 60;

    const deadlineSeconds: number = date.seconds + deadline - CETOffset;

    const lastChange = changes.reduce((acc: OrderChange, curr: OrderChange) => {
      const currChangeSeconds = curr?.timestamp?.seconds;
      const accChangeSeconds = acc?.timestamp?.seconds;

      // ignore changes past deadline
      if (currChangeSeconds > deadlineSeconds) return acc;

      // ignore earlier changes
      if (currChangeSeconds < accChangeSeconds) return acc;

      return curr;
    }, null);

    const lastOrder: OrderOrder = lastChange?.order || {};

    if (!lastChange) return { availableChanges: 0, lastOrder };

    const changesMade: number = Object.keys(localOrder).reduce(
      (acc, productId) => {
        const productDiff = Math.abs(
          (lastOrder?.[productId]?.amount || 0) - localOrder[productId].amount
        );
        return acc + productDiff;
      },
      0
    );

    const availableChanges: number = softDeadlineMaxAmount - changesMade;

    return { availableChanges, lastOrder };
  } catch (err) {
    console.error(err);
  }
};
