import { faClock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCountdown } from "@kanpla/system";
import { isEmpty } from "lodash";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

export const DeadlineTimer = ({ deadline }) => {
  const { t, i18n } = useTranslation(["libs"]);
  // Util to change the localization of moment.js
  if (!isEmpty(i18n)) moment.locale(i18n.language);
  const todaySeconds = moment().utc().utcOffset(2).startOf("day").unix();
  const eventTime = moment.unix(todaySeconds + deadline).unix();

  const { hours, minutes, seconds } = useCountdown({ eventTime, interval: 1 });
  const hasEnded = hours === 0 && minutes === 0 && seconds === 0;

  if (!deadline) return null;

  const customBackround = () => {
    if (hours === 0 && minutes < 59) return "bg-danger-main";
    if (hours > 0 && hours < 2) return "bg-yellow-400";
    return "bg-primary-main";
  };

  return (
    <div
      className={`w-full ${customBackround()} px-4 py-2 flex justify-center items-center text-background-primary font-semibold`}
    >
      <div className="flex items-center">
        <FontAwesomeIcon icon={faClock} color="#ffffff" />
        {!hasEnded && (
          <p className="ml-2">
            {isEmpty(i18n)
              ? `Salget slutter om ${hours}:${
                  minutes < 10 ? `0${minutes}` : minutes
                }:${seconds < 10 ? `0${seconds}` : seconds} ${
                  hours === 0 ? "minutter" : "timer"
                }`
              : t("libs:plural.timing", {
                  count: hours,
                  hours,
                  minutes: minutes < 10 ? `0${minutes}` : minutes,
                  seconds: seconds < 10 ? `0${seconds}` : seconds,
                })}
          </p>
        )}
        {hasEnded && (
          <p className="ml-2">
            {isEmpty(i18n) ? "Salget er slut" : t("libs:sale-over")}
          </p>
        )}
      </div>
    </div>
  );
};

export default DeadlineTimer;
