import { Billy } from "@kanpla/types";
import { BillyClient } from ".";

export const getContacts = async (client: BillyClient, enhanced?: boolean) => {
  const res = (await client.request("GET", "/contacts")) as Billy.Response<
    Billy.Contact[]
  >;

  if (!enhanced) return res.contacts;

  const contacts = res.contacts;

  const promises = contacts.map(async (contact) => {
    if (contact.type === "person") return contact;

    const { contactPersons } = (await client.request(
      "GET",
      `/contactPersons?contactId=${contact.id}`
    )) as Billy.Response<Billy.ContactPerson[]>;

    return {
      ...contact,
      contactPersons,
    };
  });

  const enhancedContacts = await Promise.all(promises);

  return enhancedContacts;
};
