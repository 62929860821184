import { AES, enc } from "crypto-js";

export const key = process.env.CRYPTO_STRING || "Tristatricettri";

export const decryptURI = (cipherText: string) => {
  const reb64 = enc.Hex.parse(cipherText);
  const bytes = reb64.toString(enc.Base64);
  const decrypt = AES.decrypt(bytes, key);
  const plain = decrypt.toString(enc.Utf8);
  return plain;
};
