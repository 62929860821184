import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

export const ignoredMessages = [
  "ikke nok kredit",
  "forkert kodeord",
  "wrong-password",
  "ikke nok produkter",
  "for sent at bestille",
  "brugeren findes ikke",
  "user-not-found",
  "not-enough-products",
  "not-enough-credit",
  "no-admin-order-credit",
  "observer loop limit exceeded",
  "network error",
];

Sentry.init({
  dsn: SENTRY_DSN,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  beforeSend: (event) => {
    // console.log("SERVER", event);

    if (event.environment === "development") return null;
    if (event.level !== "error") return null;
    if (
      ignoredMessages.some((message) =>
        event.message?.toLowerCase()?.includes(message)
      )
    )
      return null;

    return event;
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
