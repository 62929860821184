import { Child, Module, OfferProduct, School } from "@kanpla/types";
import { getTaxPercentage } from "..";

export const getBasePrice = (
  product: Partial<OfferProduct>,
  module: Module,
  school: School,
  child: Child
) => {
  const taxSettings = module?.plugins?.taxSettings;
  const defaultTax = taxSettings?.defaultTax;
  const areTaxesActive = taxSettings?.active;

  if (!areTaxesActive) return product.price;

  if (areTaxesActive && taxSettings?.productPrice === "includes") {
    return (1 / (1 + defaultTax)) * product.price;
  }

  if (areTaxesActive && taxSettings?.productPrice === "excludes") {
    return product.price;
  }

  if (areTaxesActive && taxSettings?.productPrice === "includesAndKeeps") {
    const taxPercentage = getTaxPercentage(module, school, child);
    return (1 / (1 + taxPercentage)) * product.price;
  }

  return product.price;
};
