import { Child, Selector } from "@kanpla/types";

type Props = {
  options: Selector["options"];
  /** Index of the layer */
  index: number;
  selectors: Child["selectors"];
};

export const getAllowedOptions = ({ options, index, selectors }: Props) =>
  options.filter((option) => {
    // Don't allow hidden options
    if (option?.hidden) return false;

    // Allow all on first index (layer)
    if (index === 0) return true;

    const disabledEntries = Object.entries(option.disabled || {});
    const allowed = disabledEntries.reduce(
      (acc, [selectorName, disabledValues]) => {
        const selectedValue = selectors?.[selectorName];
        const shouldDisallow = disabledValues.includes(selectedValue);
        if (shouldDisallow) return false;
        return acc;
      },
      true
    );
    return allowed;
  });
