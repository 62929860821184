import { Billy } from "@kanpla/types";
import { BillyClient } from ".";

export const getOrganizationId = async (client: BillyClient) => {
  const res = (await client.request(
    "GET",
    "/organization"
  )) as Billy.Response<Billy.Organization>;

  return res.organization;
};
