import { getConfigNameAndId } from "@kanpla/system";
import { OrderOrderProduct } from "@kanpla/types";
import { groupBy, sum } from "lodash";

/** used when:
 * - adding products to an existing order */

export const mergeConfigs = (configs: Array<OrderOrderProduct["config"]>) => {
  // 1. Put all configs together
  const allConfigItems = configs.flat().filter((i) => i);

  // 2. Group them by the same option-choices
  const itemGroups = groupBy(
    allConfigItems,
    (item) => getConfigNameAndId(item.options)?.id + (item?.deliveryTime || "")
  );

  // 3. Reduce all the option-choices (sum .amount and .taken)
  const reducedItems: OrderOrderProduct["config"] = Object.values(
    itemGroups
  ).map((items) => {
    const validItems = items.filter((i) => i);
    const totalAmount = sum(validItems.map((i) => i.amount));
    const totalTaken = validItems.map((i) => i.taken || []).flat();

    return {
      ...validItems[0],
      amount: totalAmount,
      taken: totalTaken,
    };
  });

  return reducedItems;
};
