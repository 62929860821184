import { callFunction, Timestamp } from "@kanpla/system";
import { LoadOfferProps, LoadOfferReturn } from "@kanpla/types";
import { message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIntervalWhen } from "rooks";

const defaultData: LoadOfferReturn = {
  items: [],
  holidayDates: {},
  deadlineInfo: {
    deadline: 27000,
    deadlineSoft: 27000,
    deadlineWeekRelative: false,
    deadlineExcludingWeekends: false,
    defaultDateSeconds: null,
    defaultSoftDateSeconds: null,
  },
  mealOptions: [],
};

interface Props extends LoadOfferProps {
  setLoading?: (newValue: boolean) => void;
}

const stringifyProps = (props: LoadOfferProps) =>
  `${props?.schoolId}.${props?.moduleId}.${props?.allDateSeconds?.join(".")}`;

export const UseOffers = (props: Props) => {
  const { setLoading = () => null } = props;
  const { t } = useTranslation(["components"]);
  const [data, setData] = useState<LoadOfferReturn>(defaultData);
  const [offersLoading, setOffersLoading] = useState(true);

  const stringifiedProps = stringifyProps(props);

  const [tempData, setTempData] =
    useState<{ result: LoadOfferReturn; request: LoadOfferProps }>();
  const tempDataTrigger = JSON.stringify(tempData);

  useEffect(() => {
    const propsUpToDate =
      stringifyProps(tempData?.request) === stringifiedProps;

    if (!propsUpToDate) return setOffersLoading(false);
    setData(tempData.result);
    setOffersLoading(false);
  }, [tempDataTrigger]);

  const fetchOffers = async (offerProps?: { withLoading?: boolean }) => {
    const request = {
      moduleId: props.moduleId,
      schoolId: props.schoolId,
      allDateSeconds: props.allDateSeconds,
      groupName: props.groupName,
    };

    // Reset if props not provided
    if (!request.moduleId || !request.schoolId) {
      setData(defaultData);
      setOffersLoading(false);
      return;
    }

    if (offerProps && offerProps?.withLoading) {
      setOffersLoading(true);
    }
    try {
      const result: LoadOfferReturn = await callFunction(
        "offers-loadOffer",
        request
      );

      result.deadlineInfo.defaultDate = new Timestamp(
        result.deadlineInfo.defaultDateSeconds,
        0
      );
      result.deadlineInfo.defaultSoftDate = new Timestamp(
        result.deadlineInfo.defaultSoftDateSeconds,
        0
      );

      setTempData({ result, request });
    } catch (err) {
      console.log(err);
      message.error(t("components:message.error.products-could-not-be-loaded"));
    } finally {
      setOffersLoading(false);
    }
  };

  useEffect(() => {
    fetchOffers({ withLoading: true });
  }, [stringifiedProps]);

  useIntervalWhen(() => {
    fetchOffers({ withLoading: false });
  }, 60000);

  useEffect(() => {
    setLoading(offersLoading);
  }, [offersLoading]);

  useEffect(() => {
    return () => {
      setOffersLoading(false);
      setLoading(false);
    };
  }, []);

  return {
    ...data,
    offersLoading,
    mealOptions: data.mealOptions || [],
    fetchOffers,
  };
};
