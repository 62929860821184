import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faFont } from "@fortawesome/pro-light-svg-icons";
import { FontSelectTypes } from "@kanpla/types";

interface ReturnProps {
  value: FontSelectTypes;
  label: string;
  icon: IconProp;
}

export const fontTypes: Array<ReturnProps> = [
  {
    value: "google",
    label: "Google",
    icon: faGoogle,
  },
  {
    value: "custom",
    label: "Din egen",
    icon: faFont,
  },
];
