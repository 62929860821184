import { DocumentReference as FirestoreDocRef } from "@firebase/firestore-types";

interface Options {
  allowUnknown?: boolean;
}

/**
 * Function to make your querying easier
 * @typeParam T You can define a generic type here
 * @param query Typical firestore **DOCUMENT** query without `.get()` at the end
 * @param includeRef Whether or not should the function add ref to the mix
 * @returns Data for the query including ID and possibly REF
 */
export const fetchDocument = async <T>(
  query: FirestoreDocRef | FirebaseFirestore.DocumentReference,
  includeRef?: boolean,
  options?: Options
): Promise<T> => {
  if (!query) return;

  const doc = await query.get();
  const data = doc.data();

  // // @ts-ignore (Admin SDK uses 'exists', JavaScript SDK uses 'exists()')
  // const exists = typeof doc.exists === `boolean` ? doc.exists : doc.exists;
  // if (!exists) throw new Error("No document found");
  if (!doc.exists && !options?.allowUnknown)
    throw new Error("No document found");

  if (!doc.exists && options?.allowUnknown) return null;

  return {
    ...data,
    id: doc.id,
    ...(includeRef && { ref: doc.ref }),
  } as unknown as T;
};
