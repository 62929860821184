import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import { Button, ButtonProps } from "antd";

interface Props extends ButtonProps {
  label?: string;
  saved?: boolean;
  onClick?: () => void;
}

export const ButtonSave = (props: Props) => {
  const { label, saved, onClick } = props;

  if (saved)
    return (
      <Button disabled {...props}>
        <FontAwesomeIcon icon={faSave} className="mr-2" /> Gemt
      </Button>
    );

  return (
    <Button type="primary" onClick={onClick} {...props} data-cy="save-btn">
      <FontAwesomeIcon icon={faSave} className="mr-1" /> {label ? label : "Gem"}
    </Button>
  );
};
