import { VismaTokenReturn } from "@kanpla/types";
import { getVismaToken } from "./getVismaToken";
import { redirectVismaAuth } from "./redirectVismaAuth";
import { FirebaseFirestore } from "@firebase/firestore-types";
import isEmpty from "lodash/isEmpty";

export const useVismaAuth = async (
  callbackRelativeURI: string,
  navigateTo: string,
  verifier: string,
  challenge: string,
  db: FirebaseFirestore,
  partnerId: string
): Promise<string | VismaTokenReturn> => {
  // Create callback URI
  const callbackURI =
    typeof window !== "undefined" &&
    `${window.location.protocol}//${window.location.hostname}${callbackRelativeURI}`;

  const token = (
    await db.collection("partnersIntegration").doc(partnerId).get()
  ).data();

  if (token.dineroToken && new Date() < token.expire.toDate())
    return {
      navigateTo,
      content: {
        type: "success",
        msg: "Token already exists",
      },
    };

  if (
    new URL(window.location.href).searchParams.has("isAuth") &&
    new URL(window.location.href).searchParams.has("code")
  ) {
    const code = new URL(window.location.href).searchParams.get("code");
    const state = JSON.parse(
      new URL(window.location.href).searchParams.get("state")
    );

    try {
      const token = getVismaToken(
        callbackURI,
        code,
        "isv_kanpla-invoice",
        state?.verifier,
        navigateTo,
        db,
        partnerId
      );
      return token;
    } catch (e) {
      return {
        navigateTo,
        content: {
          type: "error",
          msg: "Error getting Visma token",
          data: e,
        },
      };
    }
  } else {
    // Redirect to Visma Auth flow
    return redirectVismaAuth(
      "isv_kanpla-invoice",
      ["openid", "tenants", "dineropublicapi:read", "dineropublicapi:write"],
      callbackURI,
      challenge,
      {
        verifier: verifier,
      },
      navigateTo
    );
  }
};
