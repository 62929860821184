import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-solid-svg-icons";

interface Props {
  productName: string;
  image: string;
  rating: string;
}

export const FavoriteProductsPreview = () => {
  const products: Props[] = [
    {
      productName: "Sandwich",
      image:
        "https://images.unsplash.com/photo-1619096252214-ef06c45683e3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1325&q=80",
      rating: "4.8",
    },
    {
      productName: "Frisk salat",
      image:
        "https://images.unsplash.com/photo-1571047399553-603e2138b646?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
      rating: "4.5",
    },
    {
      productName: "Pasta carbonara",
      image:
        "https://images.unsplash.com/photo-1612874742237-6526221588e3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
      rating: "4.9",
    },
  ];

  return (
    <div className="flex w-full flex-row flex-nowrap overflow-hidden ml-4 mt-8">
      {products.map((p) => {
        return <ProductPreviewCard {...p} />;
      })}
    </div>
  );
};

const ProductPreviewCard = (props: Props) => {
  const { productName, rating, image } = props;

  return (
    <div className="w-full min-w-fit mr-6 h-auto flex shadow-xl flex-col p-2 rounded-xl bg-white bg-opacity-50 backdrop-blur-md backdrop-filter">
      <img
        src={image}
        alt={productName}
        className="rounded-xl h-20 object-cover object-center"
      />
      <div className="flex w-full justify-between items-center mt-2">
        <h1 className="text-md font-semibold">{productName}</h1>
        <div className="flex items-center text-sm ml-2">
          {rating}
          <FontAwesomeIcon
            icon={faStar}
            size="1x"
            className="text-yellow-500 ml-1"
          />
        </div>
      </div>
    </div>
  );
};
