import { Child, Module, OfferProduct, School } from "@kanpla/types";
import { isEmpty, isNumber } from "lodash";

/**
 * Function to return the tax percentage based on the tax settings
 * @param module Module
 * @param school School
 * @param child Child
 * @returns Possibly a tax percentage
 */
export const getTaxPercentage = (
  module: Module,
  school: School,
  child?: Child
): number | undefined => {
  const defaultModuleTax = module?.plugins?.taxSettings?.defaultTax;
  const moduleProductPrice = module?.plugins?.taxSettings?.productPrice;

  const schoolTaxes = school?.modules?.[module?.id]?.taxes;
  const selectorTaxes = schoolTaxes?.selectorGroups;
  const defaultSchoolTax = schoolTaxes?.standard?.[0]?.taxPercentage;

  // If has group and fit it
  const groupName = child?.groupName;

  // Set including for inclusive taxes
  const includeFormula = (percentage: number) =>
    (1 + percentage) / (1 + defaultModuleTax) - 1;

  if (groupName && groupName !== "" && !isEmpty(selectorTaxes)) {
    const activeGroup = selectorTaxes.find((taxGroup) =>
      taxGroup.groups?.includes(groupName)
    );
    if (!isEmpty(activeGroup)) {
      if (moduleProductPrice === "includes")
        return includeFormula(activeGroup.taxPercentage);
      return activeGroup.taxPercentage;
    }
  }

  // If it has module/school tax
  if (isNumber(defaultSchoolTax)) {
    if (moduleProductPrice === "includes")
      return includeFormula(defaultSchoolTax);
    return defaultSchoolTax;
  }

  // If it has default tax
  if (isNumber(defaultModuleTax)) {
    if (moduleProductPrice === "includes")
      return includeFormula(defaultModuleTax);
    return defaultModuleTax;
  }

  return 0.25;
};
