/* eslint-disable @typescript-eslint/no-namespace */
import { FontProps, CustomSupplierData } from "@kanpla/types";
import tinycolor from "tinycolor2";

const neededOptions = [
  {
    titles: ["primary", "secondary", "danger", "warning", "success", "info"],
    values: [
      { title: "main", fn: (color: string) => color },
      { title: "light", fn: (color: string) => tinycolor(color).lighten() },
      { title: "dark", fn: (color: string) => tinycolor(color).darken() },
      {
        title: "contrast",
        fn: (color: string) =>
          tinycolor(color).darken().isDark() ? "#fff" : "#1A202C",
      },
    ],
    required: "main",
  },
];

export namespace CustomStylesheet {
  /**
   * @deprecated
   * Use `getFontObject` instead, as it supports both custom and Google fonts
   */
  export const getFontUrl = (props: FontProps, noProps?: boolean) => {
    const familyString = props.family.trim().split(" ").join("+");

    const base = `https://fonts.googleapis.com/css?family=${familyString}`;

    if (noProps) return base;

    // Hack for Inter, loading multiple weights
    if (familyString === "Inter") return `${base}:400,500,600,700&display=swap`;

    return `${base}:${props.variant}&display=swap`;
  };

  interface ObjectOptions {
    /** Get pure Google font url */
    noProps?: boolean;
  }

  /** Generates object to import and load fonts
   * Google: uses @import
   * Custom: defines @font-face
   */
  export const getFontObject = (props: FontProps, options?: ObjectOptions) => {
    if (props.type === "google" || !props.type)
      return `@import url('${CustomStylesheet.getFontUrl(
        props,
        options?.noProps
      )}');`;

    if (props.type === "custom" && props.src && props.family)
      return `
      @font-face {
        font-family: "${props.family}";
        src: url("${props.src}") format("woff2");
      }
    `;

    return "";
  };

  export const getFontWeight = (
    variant: FontProps["variant"],
    important?: boolean
  ) =>
    parseInt(variant)
      ? `font-weight: ${parseInt(variant)} ${important ? "!important" : ""};`
      : "";

  export const getFontStyle = (variant: FontProps["variant"]) =>
    variant.replace(/[0-9]/g, "")
      ? `font-style: ${variant.replace(/[0-9]/g, "")} !important;`
      : "";

  export const generateVarPalette = (
    object: Partial<CustomSupplierData["palette"]> = {}
  ) =>
    Object.entries(object || {})
      .map(([entryName, entry]) => {
        const option = neededOptions.find((o) => o.titles.includes(entryName));

        if (option?.required && !Object.keys(entry).includes(option?.required))
          return null;

        const defaultColor = entry[option?.required];
        const defaultList =
          option?.values?.reduce((acc, { title, fn }) => {
            acc[title] = fn(defaultColor);

            return acc;
          }, {}) || {};

        const generated = Object.entries({ ...defaultList, ...entry }).map(
          ([valueName, value]) => {
            const rgb = tinycolor(value).toRgb();
            return `--palette-${entryName}-${valueName}: rgb(${rgb.r}, ${rgb.g}, ${rgb.b});\n
                    --palette-${entryName}-${valueName}-rgb: ${rgb.r} ${rgb.g} ${rgb.b}`;
          }
        );

        return generated;
      })
      .flat()
      .filter((e) => e)
      .join(";");
}
