import { Moment } from "moment";

export const getDaysInRange = (
  startDate: Moment,
  endDate: Moment,
  format: "date" | "unix" = "date"
) => {
  const now = startDate.clone(),
    dates = [];
  while (now.isSameOrBefore(endDate)) {
    dates.push(format === "unix" ? now.unix() : now.toDate());
    now.add(1, "days");
  }

  if (format === "unix") return dates as Array<number>;

  return dates as Array<Date>;
};
