import React from "react";

interface Props {
  color: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  type: "plus" | "minus";
}

const SingleButton = ({ color, onClick, disabled, type }: Props) => {
  return (
    <button
      type="button"
      className={`w-14 border-2 h-9 bg-white border-primary-main flex items-center justify-center group relative text-2xl  ${
        type === "minus" ? "rounded-l-lg" : "rounded-r-lg"
      } overflow-hidden text-primary-main ${
        disabled ? "pointer-events-none opacity-50" : ""
      }`}
      style={{
        color: color,
        borderColor: color,
        marginRight: type === "minus" && -2,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="absolute inset-0 opacity-0 bg-current group-hover:opacity-10 transition-opacity"></div>
      {type === "minus" ? "-" : "+"}
    </button>
  );
};

export default SingleButton;
