import { flexBulkOrder, FlexStandard, OrderOrder, Week } from "@kanpla/types";
import moment from "moment";

interface Props {
  schoolId: string;
  moduleId: string;
  /** Database provider */
  db: any;
  /** Day of the order */
  week: Week;
  /** Standard order as a fallback, if no document was found */
  standard: FlexStandard["standard"];
}

interface WeekOrders {
  [dayIndex: number]: OrderOrder;
}
interface WeekDocuments {
  [dayIndex: number]: flexBulkOrder;
}

export const getFlexBulkWeeklyOrders = async (
  props: Props
): Promise<{ weekOrders: WeekOrders; weekDocuments: WeekDocuments }> => {
  const { schoolId, moduleId, db, week, standard } = props;

  const ref = db
    .collection("flexBulkOrders")
    .where("schoolId", "==", schoolId || null)
    .where("moduleId", "==", moduleId || null)
    .where(`date`, "in", week);

  const result = await ref
    .get()
    .then((res) =>
      res.docs.map((d) => ({ ...d.data(), id: d.id, ref: d.ref }))
    );

  const allOrders = result.filter((res) => !res.deleted) || [];

  const weekDocumentsEntries = week.map((date, dayIndex) => {
    // Get the first document
    const orderDocument = allOrders.find(
      (res) => res.date.seconds === date.seconds
    );

    return [dayIndex, orderDocument];
  });

  const weekOrdersEntries = weekDocumentsEntries.map(
    ([dayIndex, orderDocument]) => {
      const isPastDate = moment
        .unix(week[dayIndex]?.seconds || 0)
        .isBefore(moment(), "day");
      // If no document exists, return standard order if it's not before today
      const noOrderDocument = typeof orderDocument === `undefined`;
      const standardOrder = isPastDate ? 0 : standard[dayIndex] || {};
      const documentOrderOrder = orderDocument?.order || {};
      const order: OrderOrder = noOrderDocument
        ? standardOrder
        : documentOrderOrder;
      // const orderInfo = orderDocument?.info || {};

      return [dayIndex, order];
    }
  );

  const weekOrders: WeekOrders = Object.fromEntries(weekOrdersEntries);
  const weekDocuments: WeekDocuments = Object.fromEntries(weekDocumentsEntries);

  return { weekOrders, weekDocuments };
};
