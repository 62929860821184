import { Modal } from "@kanpla/ui";
import { useTranslation } from "react-i18next";
import React from "react";

const Confirm = ({ open, onSubmit, onClose, title, children }) => {
  const { t } = useTranslation(["translation"]);
  const [state, setState] = React.useState();
  return (
    <Modal
      zMax
      open={open}
      setOpen={(o) => !o && onClose()}
      title={title}
      style={{ maxWidth: "24rem" }}
      actions={[
        {
          label: t("translation:cancel"),
          onClick: onClose,
          className: "",
          type: "default",
        },
        {
          label: t("translation:confirm"),
          onClick: () => onSubmit(state),
          className: "primary",
        },
      ]}
    >
      {children &&
        React.cloneElement(children, {
          ...children.props,
          setState,
        })}
    </Modal>
  );
};

const ConfirmationServiceContext = React.createContext(Promise.reject);

export const useConfirmation = () =>
  React.useContext(ConfirmationServiceContext);

export const ConfirmProvider = ({ children }) => {
  const [confirmationState, setConfirmationState] = React.useState(null);

  const awaitingPromiseRef = React.useRef<any>();

  const openConfirmation = (options) => {
    setConfirmationState(options);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  const handleSubmit = (res) => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(res);
    }

    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={openConfirmation}
        children={children}
      />
      <Confirm
        open={Boolean(confirmationState)}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  );
};
