import { fetchMultipleDocuments } from "@kanpla/system";
import { Child, School } from "@kanpla/types";
import { useState, useEffect } from "react";

interface Props {
  isChildSalesplace: boolean;
  userId: string;
  children: Array<Child>;
}

const useChildSalesplace = (props: Props) => {
  const { isChildSalesplace, children } = props;
  const [childSchools, setChildSchools] = useState<School[]>([]);

  // Extra check, if `isMainChild` is defined then we're sure we're handling salesplaces
  const isThereAMainChild = children.some((child) => child?.isMainChild);

  const getChildSalesplaces = async () => {
    try {
      const childrenSchoolsIds = children.map((child) => child.schoolId);

      const schoolsFound = await fetchMultipleDocuments<School>(
        "schools",
        childrenSchoolsIds
      );

      setChildSchools(schoolsFound);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!isChildSalesplace && !isThereAMainChild) return;
    getChildSalesplaces();
  }, [children.length]);

  return {
    childSalesplaceSchools: childSchools,
  };
};

export default useChildSalesplace;
