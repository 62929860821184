import { Billy } from "@kanpla/types";
import { BillyClient } from ".";

export const getProducts = async (client: BillyClient) => {
  const res = (await client.request("GET", "/products")) as Billy.Response<
    Billy.Product[]
  >;

  return res.products;
};
