import { faUnsplash } from "@fortawesome/free-brands-svg-icons";
import { faImages } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fn, uploadImageToStorage } from "@kanpla/system";
import { Admin, Image as ImageType, UnsplashImage } from "@kanpla/types";
import { message, Modal, Radio, Space, Tabs } from "antd";
import React, { useState } from "react";
import KanplaPhotos from "./tabs/KanplaPhotos";
import UnsplashPhotos from "./tabs/UnsplashPhotos";

const { TabPane } = Tabs;

interface Props {
  partnerId: string;
  onSelect: (src: string) => void;
  admin: Admin;
  /** Cloud Storage folder where the images will be stored */
  folder?: string;
}

/**
 * Send in partner ID and a callback function that returns the image URL
 * @returns React Gallery component
 */
export const Gallery = ({
  partnerId,
  onSelect,
  admin,
  folder = null,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [view, setView] = useState<"kanpla" | "unsplash">("kanpla");
  const [selectedImage, setSelectedImage] = useState<string>(null);

  const [images, setImages] = useState<ImageType[] | UnsplashImage[]>([]);

  const onOk = async () => {
    const findImage = images.find((im) => im.id === selectedImage);

    /**
     * Download Unsplash photo and upload it to Firestore bucket
     * required to get app verified for production on Unsplash
     * see: https://help.unsplash.com/en/articles/2511245-unsplash-api-guidelines
     */
    if (findImage.url.includes("unsplash")) {
      try {
        setLoading(true);

        const downloadPhoto = fn.httpsCallable("unsplash-downloadPhoto");
        const { data: base64 } = await downloadPhoto({
          url: (findImage as UnsplashImage).downloadUrl,
        });

        // Convert base64 to blob
        const blob = await (
          await fetch(`data:image/jpeg;base64,${base64}`)
        ).blob();

        // Upload picture to starage and get full url
        const url = await uploadImageToStorage({
          userId: admin?.adminId || admin.id,
          folder,
          file: blob,
          onError: (err) => {
            console.error(err);
          },
        });

        onSelect(url);
      } catch (err) {
        console.error(err);
        message.error(err?.message);
      } finally {
        setLoading(false);
      }
    } else {
      onSelect(findImage.url);
    }

    setSelectedImage(null);
    setOpen(false);
  };

  return (
    <>
      <div>
        <p className="mb-1 text-text-secondary text-sm">Vælg fra</p>
        <Radio.Group
          className="w-full"
          optionType="button"
          buttonStyle="solid"
          value={null}
        >
          <Radio.Button
            onClick={() => setOpen(true)}
            type="secondary"
            value="gallery"
          >
            <Space>
              <FontAwesomeIcon icon={faImages} />
              Galleriet
            </Space>
          </Radio.Button>
          <Radio.Button
            onClick={() => {
              setView("unsplash");
              setOpen(true);
            }}
            value="unsplash"
          >
            <Space>
              <FontAwesomeIcon icon={faUnsplash} />
              Unsplash
            </Space>
          </Radio.Button>
        </Radio.Group>
      </div>
      <Modal
        visible={open}
        onCancel={() => {
          setSelectedImage(null);
          setOpen(false);
        }}
        title="Vælg fra galleriet"
        width="80vw"
        className="max-w-none"
        bodyStyle={{ width: "80vw" }}
        okButtonProps={{
          disabled: !selectedImage,
          loading,
        }}
        onOk={onOk}
        centered
      >
        <Tabs
          defaultActiveKey="kanpla"
          activeKey={view}
          onChange={(key: "kanpla" | "unsplash") => {
            setView(key);
          }}
          className="-mt-4"
        >
          <TabPane tab="Kanpla" key="kanpla">
            <KanplaPhotos
              partnerId={partnerId}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              setImages={setImages}
              view={view}
            />
          </TabPane>
          <TabPane
            tab={
              <>
                <FontAwesomeIcon icon={faUnsplash} className="mr-2" />
                Unsplash
              </>
            }
            key="unsplash"
          >
            <UnsplashPhotos
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              setImages={setImages}
              view={view}
              admin={admin}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};
