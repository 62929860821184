import { SubscriptionPeriod } from "@kanpla/types";
import { Moment } from "moment";

/**
 * This method get the delivery dates.
 * Used twice in the subscriptions.
 */
export const getDeliveryDates = (
  startDate: Moment,
  selectedPeriod?: SubscriptionPeriod
) => {
  return selectedPeriod?.deliveryDates.filter(
    (d) => d.seconds >= startDate.valueOf() / 1000
  );
};
