import React from "react";
import { Badge as AntdBadge } from "antd";
import { FC } from "react";

type BadgeColorType = "primary" | "secondary";

interface BadgeProps {
  count: number;
  type?: BadgeColorType;
  hidden?: boolean;
  overflowCount?: number;
  size?: "default" | "small";
  offset?: [number, number];
  dot?: boolean;
  showZero?: boolean;
}

export const Badge: FC<BadgeProps> = ({
  count,
  children,
  hidden = false,
  type = "primary",
  overflowCount = Infinity,
  size = "default",
  offset,
  dot,
  showZero,
}) => (
  <AntdBadge
    dot={dot}
    showZero={showZero}
    size={size}
    offset={offset}
    count={hidden ? 0 : count}
    overflowCount={overflowCount}
    className={`custom-badge ${type}`}
    data-cy="basket-badge"
  >
    {children}
  </AntdBadge>
);
