import { Tree } from "@kanpla/types";

export const getNestedIds = (
  nodes: Tree["productBanks"] | Tree["schools"],
  id: string
) => {
  const values = Object.values(nodes || {});
  return values
    .filter((node) => Object.keys(node.parents || {}).includes(id))
    .map((node) => node.id);
};
