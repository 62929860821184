import { Timestamp } from "@kanpla/types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getWeekSeconds } from "..";

interface Props {
  deadline: number;
  date: Timestamp;
  deadlineWeekRelative: boolean;
  deadlineExcludingWeekends: boolean;
}

// Takes in number of seconds relative to UTC midnight of that day
export const deadlineFormatter = (props: Props) => {
  const { t, i18n } = useTranslation(["translation"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const { deadline, date, deadlineWeekRelative, deadlineExcludingWeekends } =
    props;
  let deadlineSeconds = date?.seconds + deadline;

  // Account for excluding weekend
  if (deadlineExcludingWeekends) {
    const mondayBeforeDate = getWeekSeconds(date.seconds);
    const mondayBeforeToday = getWeekSeconds(deadlineSeconds);
    const differenceOfMondays = mondayBeforeDate - mondayBeforeToday;
    const weeksCrossed = differenceOfMondays / 604800;

    const weekendSeconds = 86400 * 2;
    const secondsToSubtract = weeksCrossed * weekendSeconds;
    deadlineSeconds = deadlineSeconds - secondsToSubtract;
  }

  const deadlineDisplay = moment.utc(deadlineSeconds * 1000).calendar(null, {
    sameDay: `[${t("translation:today").toLowerCase()}][, ${
      i18n.language !== "en" ? "kl." : ""
    }] LT`,
    nextDay: `[${t("translation:tomorrow")}][, ${
      i18n.language !== "en" ? "kl." : ""
    }] LT`,
    nextWeek: `dddd[, ${i18n.language !== "en" ? "kl." : ""}] LT`,
    lastDay: `[${t("translation:yesterday")}][, ${
      i18n.language !== "en" ? "kl." : ""
    }] LT`,
    lastWeek: `[${t("translation:last")}] dddd[, ${
      i18n.language !== "en" ? "kl." : ""
    }] LT`,
    sameElse: `dddd, D/M[, ${i18n.language !== "en" ? "kl." : ""}] LT`,
  });

  return deadlineDisplay;
};
