import { db, safeStringify, useCollectionListener } from "@kanpla/system";
import { Child } from "@kanpla/types";
import { sortBy } from "lodash";
import { useEffect, useState } from "react";
import { useLocalstorageState } from "rooks";
import { useRouter } from "next/router";

export function useChild(userId: string) {
  const router = useRouter();

  const [rawChildren = [], loadingChildren] = useCollectionListener<Child>(
    db.collection("children").where("userId", "==", userId)
  );

  const children = rawChildren.map((c) => ({
    ...c,
    child_ref: c.ref,
  }));

  const [child, setChild] = useState<Child>(null);

  // Remember the last active child
  const [id, setId] = useLocalstorageState<string>("current_child_id");

  // When child changes, store the ID to the local storage
  useEffect(() => {
    if (child && child.id) return setId(child.id);
  }, [child?.id]);

  // Set default kid
  useEffect(() => {
    if (!userId) return;

    // Return if the child is already selected or if there are no children
    if (!children || !children.length) return;

    // Try to recover child from schoolId from router (Anonymous users fix)
    const childFromQuery = children.find((child) =>
      router.asPath.includes(child.schoolId)
    );

    // Try to get the last child from local storage
    const recoveredChild =
      id &&
      children.find(
        (ch) => (ch.child_ref && ch.child_ref.id === id) || ch.id === id
      );

    const firstChild = sortBy(children, "school_name")[0];

    const newChild = childFromQuery || recoveredChild || firstChild;

    const oldChildData = safeStringify(child || {});
    const newChildData = safeStringify(newChild);

    if (oldChildData === newChildData && newChildData && oldChildData) return;

    // set the child from the local storage or the first one
    setChild(newChild);
  }, [loadingChildren, userId]);

  useEffect(() => {
    if (userId === undefined) {
      setChild(null);
      return;
    }
  }, [userId]);

  const childId = child?.id || null;

  const setChildId = (newId: string) => {
    setId(newId || null);
    const targetChild = children.find((ch) => ch.id === newId);
    setChild(targetChild || null);
  };

  return {
    children,
    child,
    childId,
    setChild,
    setChildId,
    childLoading: loadingChildren,
  };
}
