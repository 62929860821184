import { cloneDeep } from "lodash";
import moment from "moment";

interface Props {
  deadline: number;
  deadlineWeekRelative: boolean;
  deadlineExcludingWeekends: boolean;
  // displayStringDay?: boolean;
}

export const deadlineDisplay = ({
  deadline,
  deadlineWeekRelative,
  deadlineExcludingWeekends,
}: // displayStringDay = true,
Props) => {
  const daysBefore = Math.floor((deadline || 0) / 86400);
  const timeModulus = (deadline || 0) % 86400;

  let time = timeModulus;

  if (timeModulus < 0) {
    const date = moment().startOf("day").add(deadline, "seconds");
    const midnight = cloneDeep(date).startOf("day");

    time = date.unix() - midnight.unix();
  }

  const getDayRelativeDisplay = () => {
    if (daysBefore === 0) return "samme dag";
    if (daysBefore === -1) return "dagen før";
    return `${-daysBefore} dage før`;
  };

  const getWeekRelativeDisplay = () => {
    const dayNames = {
      6: "søndag",
      5: "lordag",
      4: "fredag",
      3: "torsdag",
      2: "onsdag",
      1: "tirsdag",
      0: "mandag",
    };

    const dayIndex = daysBefore + 7;
    return `${dayNames[dayIndex]} ugen før`;
  };

  const displayDay = deadlineWeekRelative
    ? getWeekRelativeDisplay()
    : getDayRelativeDisplay();
  const displayTime = moment.utc(time * 1000 || 0).format("HH:mm");

  return `kl. ${displayTime}, ${displayDay}`;
};
