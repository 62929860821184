import { useEffect, useState } from "react";
import { db, useLocalStorage, fb } from "@kanpla/system";
import { User } from "@kanpla/types";
import { isExpoPushToken } from "./isExpoPushToken";

interface Props {
  user: User;
  auth: any;
}

export const useNotifications = (props: Props) => {
  const { user, auth } = props;

  const [token, setToken] = useLocalStorage("notifications-token", null);
  const [tokenType, setTokenType] = useState<string>(null);
  const [notification, setNotification] = useState(null);

  const init = async () => {
    try {
      if (typeof window === `undefined`) return;

      if (token !== null) {
        return false;
      }

      const messaging = fb.messaging();
      await Notification.requestPermission();

      const newToken = await messaging.getToken();
      setToken(newToken);
      setTokenType("fcm");

      messaging.onMessage((payload) => {
        setNotification(payload.notification);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  // Fetch token from iOS
  useEffect(() => {
    const timer = setInterval(() => {
      const fcmToken = (window as any).iOSToken;
      const expoToken = (window as any).iOSTokenExpo;

      if (fcmToken && !isExpoPushToken(fcmToken)) {
        setToken(fcmToken);
        setTokenType("fcm");
        clearInterval(timer);
      }

      // Temporarly checking if fcmToken is an Expo token since in the published Expo wrapper of some apps `window.iOSToken` has the same name of the swift wrapper
      if (expoToken || (fcmToken && isExpoPushToken(fcmToken))) {
        setToken(isExpoPushToken(fcmToken) ? fcmToken : expoToken);
        setTokenType("expo");
        clearInterval(timer);
      }
    }, 1000);

    // Clear interval after 5 minutes if no token is detected
    setTimeout(() => {
      clearInterval(timer);
    }, 300000);

    return () => clearInterval(timer);
  }, []);

  // Save to firestore when Token or User changes
  useEffect(() => {
    if (!token || !user || !auth.user) return;

    const currentTokens =
      (tokenType === "fcm" ? user.fcmTokens : user.expoTokens) || {};

    if (!currentTokens[token]) {
      const userDoc = db.collection("users").doc(auth.user.uid);
      const tokens = { ...currentTokens, [token]: true };

      if (tokenType === "fcm") {
        userDoc.update({ fcmTokens: tokens });
      } else {
        userDoc.update({ expoTokens: tokens });
      }
    }
  }, [token, user, auth.user, tokenType]);

  return { token, notification };
};

export default useNotifications;
