import React, { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { useLocalstorageState } from "rooks";
import moment from "moment";
import { isMobileSafari } from "react-device-detect";

const CustomAppBanner = () => {
  const { customBranding } = useContainer(AppContext);

  const [closed, setClosed] = useState(true);

  const [customAppBannerLastShown, setCustomAppBannerLastShown] =
    useLocalstorageState("custom-app-banner-last-shown", null);

  const kanplaAppLogo =
    "https://is5-ssl.mzstatic.com/image/thumb/Purple112/v4/f3/cb/a0/f3cba095-bf74-9582-d2d7-1e956974db10/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/460x0w.png";

  const calculateDaysDifference = () => {
    const now = moment();
    const lastShown = moment(customAppBannerLastShown as string);

    const diff = now.diff(lastShown, "days");
    // Show the banner again after it's been closed for 7 days
    if (diff < 7) return;
    setClosed(false);
  };

  useEffect(() => {
    calculateDaysDifference();
  }, []);

  const openOnAppStore = () => {
    const script = document.createElement("script");

    // Redirect to app if user is viewing from iOS mobile
    if (isMobileSafari && !window?.["isRenderedInWebView"]) {
      script.innerHTML = `
        window.location.replace("${customBranding?.url || "kanpla"}://");
        setTimeout(() => {
          window.location.replace("https://itunes.apple.com/app/${
            customBranding?.appstoreId || "id1500582659"
          }");
        }, 25);  
      `;
      document.body.appendChild(script);
    }

    setClosed(true);
  };

  if (
    (typeof window !== "undefined" && window?.["isRenderedInWebView"]) ||
    !isMobileSafari ||
    closed
  )
    return null;

  return (
    <div className="sticky z-60 w-full h-auto p-4 text-gray-900 bg-gray-200 border-y border-gray-400 flex justify-between items-center shadow-xl">
      <div className="flex items-center">
        <FontAwesomeIcon
          icon={faClose}
          size="lg"
          onClick={() => {
            setCustomAppBannerLastShown(moment().toISOString());
            setClosed(true);
          }}
        />
        <div className="w-9 h-9 ml-4 rounded-lg border border-gray-400 overflow-clip">
          <img
            src={`${customBranding?.appIconUrl || kanplaAppLogo}`}
            alt={`${customBranding?.name || "Kanpla"} app logo`}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div className="flex flex-col ml-3">
          <h1 className="text-lg font-semibold">
            {customBranding?.appName || customBranding?.name || "Kanpla"}
          </h1>
          {customBranding?.name && (
            <h2 className="text-gray-700 text-sm">powered by Kanpla</h2>
          )}
        </div>
      </div>
      <Button type="primary" onClick={openOnAppStore}>
        Open
      </Button>
    </div>
  );
};

export default CustomAppBanner;
