import { useState, useEffect } from "react";
import { db } from "../firebase.config";
import { fetchDocument } from "./fetchDocument";

type Props = {
  collection: string;
  id: string;
};

type Return<T> = [
  document: T,
  loadDocument: () => Promise<void>,
  loading: boolean,
  loadingError?: Error
];

export const useFetchDocument = <T>({ id, collection }: Props): Return<T> => {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<Error>(null);
  const [document, setDocument] = useState<T>(null);

  const loadDocument = async () => {
    try {
      setLoading(true);
      setLoadingError(null);
      if (!id || !collection) {
        setDocument(null);
        return;
      }
      const newData = await fetchDocument<T>(
        db.collection(collection).doc(id),
        true,
        {
          allowUnknown: true,
        }
      );

      setDocument(newData);
    } catch (err) {
      console.log("useFetchDocument error", err);
      setLoadingError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadDocument();
  }, [collection, id]);

  return [document, loadDocument, loading, loadingError];
};
