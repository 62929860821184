import React, { useState, useEffect } from "react";
import { Choice } from "../..";

interface Props {
  variants: any;
  setState: any;
}

export const ProductVariants = (props: Props) => {
  const { variants, setState } = props;

  const [selected, setSelected] = useState({});
  const [byType, setByType] = useState<any>({});

  const set = (category, variant) => {
    const updater = (state) => ({
      ...state,
      [category]: variant,
    });

    setSelected(updater);
    setState(updater);
  };

  useEffect(() => {
    const reduced = variants.reduce((acc, variant) => {
      const key = variant.type || "variant";

      if (typeof variant.price !== `number` || isNaN(variant.price))
        variant.price = undefined;

      if (!acc[key]) acc[key] = [];
      acc[key].push(variant);
      return acc;
    }, {});

    setByType(reduced);

    Object.entries(reduced).map(([type, variants]) => {
      if (selected[type]) return;
      if (!variants[0]) return;
      setSelected((s) => ({ ...s, [type]: variants[0] }));
      setState((s) => ({ ...s, [type]: variants[0] }));
    });
  }, [variants, selected]);

  return (
    <div>
      {Object.entries(byType).map(([name, items], index) => (
        <div key={index} className="mb-3">
          <h2 className="h300 text-text-primary mb-2">{name}</h2>
          {(items as any[]).map((variant, index) => (
            <Choice
              key={index}
              title={variant.name}
              active={selected[name]?.name === variant.name}
              onClick={() => set(name, variant)}
              tag={
                variant.price > 0
                  ? `+ ${variant.price} kr.`
                  : variant.price < 0
                  ? `- ${variant.price} kr.`
                  : null
              }
            />
          ))}
        </div>
      ))}
    </div>
  );
};
