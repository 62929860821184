import axios from "axios";
import { config } from "..";
import { google } from "@kanpla/types";

export const getGoogleFonts = async (): Promise<
  Array<google.fonts.WebfontFamily>
> => {
  try {
    const request = await axios.get<google.fonts.WebfontImport>(
      `https://www.googleapis.com/webfonts/v1/webfonts?key=${config.apiKey}&sort=alpha`
    );

    return request.data.items;
  } catch (e) {
    console.error(e);
    throw new Error(`Error getting Google fonts: ${e?.message}`);
  }
};
