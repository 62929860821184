import { FirestoreDocument } from "@kanpla/types";

/**
 * Function to make a new entry to the database
 */
export const addDocument = async <T extends FirestoreDocument>(
  db: any,
  collection: string,
  data: Omit<T, "id" | "ref">
): Promise<Array<T>> => await db.collection(collection).add(data);
