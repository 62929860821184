import { OrderConfig, OrderOptions } from "@kanpla/types";
import { Dictionary, groupBy, isNumber, sortBy } from "lodash";

export const getConfigNameAndId = (options: {
  [choiceId: string]: OrderOptions;
}) => {
  const sorted = sortBy(Object.entries(options || {}), (e) => e[0]);
  const name = sorted
    .map(
      ([key, option]) =>
        `${option.amount > 1 ? `${option.amount}x ` : ""}${option.name}`
    )
    .join(", ");

  const id = sorted
    .map(
      ([key, option]) =>
        `${option.amount > 1 ? `${option.amount}x` : ""}${key || option.id}`
    )
    .join(",");

  const extraName = sorted
    .map(
      ([key, option]) =>
        `${option.amount > 1 ? `${option.amount}x ` : ""}${option.extraName}`
    )
    .join(", ");

  return { name, id, extraName };
};

// needs refactoring

export const getConfig = (
  byDayName: Dictionary<any>,
  displayMode: "products" | "earnings" | "users"
) => {
  return Object.values(
    groupBy(
      Object.entries(byDayName).reduce((acc, [dayName, order]) => {
        acc = [
          ...acc,
          ...order
            ?.filter((ord) => ord.config)
            .flat()
            .map((o) => {
              const { dayName, config } = o;
              const fixSingleConfig = config[0]?.options
                ? config
                : ([
                    {
                      amount: 1,
                      options: config.reduce((confAcc, conf: any) => {
                        confAcc[conf.id] = { ...conf };
                        return confAcc;
                      }, {}),
                    },
                  ] as OrderConfig[]);
              return Object.values(fixSingleConfig || {}).map(
                (oc: OrderConfig) => {
                  const { name, id } = getConfigNameAndId(oc.options);

                  const extraPrice = sortBy(
                    Object.values(oc.options || {}),
                    "name"
                  ).reduce((priceAcc, priceDoc) => {
                    priceAcc =
                      (priceAcc || 0) +
                      (priceDoc.extraPrice || 0) * priceDoc.amount;
                    return priceAcc;
                  }, 0);

                  return {
                    [name]: {
                      idString: id,
                      [dayName]: {
                        amount: oc.amount,
                        extraPrice: extraPrice,
                      },
                    },
                  };
                }
              );
            })
            .flat()
            .reduce((groupedAcc, ov) => {
              const option = Object.entries(
                ov as {
                  ovName: {
                    idString: number;
                  };
                }
              ).map(([ovName, { idString, ...rest }]) => {
                return {
                  name: ovName,
                  day: rest,
                  idString: idString,
                };
              })[0];

              groupedAcc.push(option);
              return groupedAcc;
            }, []),
        ];

        return acc;
      }, []),
      "name"
    )
  )
    .map((option) =>
      option.reduce((acc, doc) => {
        const docDay: [string, { amount?: number; extraPrice?: number }] =
          Object.entries(doc.day)[0];

        const getAmount = () => {
          switch (displayMode) {
            case "earnings":
              return (docDay[1].amount || 0) * (docDay[1].extraPrice || 0);
            default:
              return docDay[1].amount || 0;
          }
        };

        acc = {
          name: doc.name,
          idString: doc.idString,
          days: {
            ...(acc.days || {}),
            [docDay[0]]:
              (acc.days && isNumber(acc.days[docDay[0]])
                ? acc.days[docDay[0]]
                : 0) + getAmount(),
          },
          total: (acc.total || 0) + getAmount(),
        };

        return acc;
      }, [])
    )
    .filter((docs) => docs.name && docs.name !== "");
};
