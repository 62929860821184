import { Drawer as DrawerOriginal, Button, DrawerProps } from "antd";
import { useWindowSize } from "rooks";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

export const Drawer: React.FC<DrawerProps> = (props) => {
  const { onClose, title = null, children, width = 800 } = props;

  const { innerWidth } = useWindowSize();

  const fullWidthTopBreakpoint = (width as number) * 1.2;

  return (
    <DrawerOriginal
      title={
        <div className="flex justify-between items-center">
          {innerWidth < 1000 && (
            <Button onClick={(e) => onClose(e as any)} className="mr-3">
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
          )}
          {title}
        </div>
      }
      {...props}
      width={
        innerWidth < 650
          ? null
          : innerWidth < fullWidthTopBreakpoint
          ? "80%"
          : width
      }
    >
      {children}
    </DrawerOriginal>
  );
};
