import { OrderInfo, Plugins } from "@kanpla/types";
import { Select } from "antd";
import { default as React, FC, useCallback, useEffect, useMemo } from "react";
import { hours, minutes } from "../elements/ReceiptItem/timeInputData";

const SEPARATOR = " : ";

const getHour = (timeInputValue: TimeInputType): number =>
  Math.floor(timeInputValue / 3600);

const getMinutes = (timeInputValue: TimeInputType): number =>
  Math.floor((timeInputValue % 3600) / 60) || 0;

const getTimeOption = (hours: number, minutes: number): string => {
  return [
    hours.toString().padStart(2, "0"),
    SEPARATOR,
    minutes.toString().padStart(2, "0"),
  ].join("");
};

const getAvailableHours = (startAt: number, endAt: number): number[] =>
  hours.filter((hour) => {
    return hour >= Math.floor(startAt / 3600) && hour <= endAt / 3600;
  });

const getAvailableMinutes = (
  selectedHour: number,
  interval: number,
  startAt: number,
  endAt: number
): number[] => {
  return minutes
    .filter((minute) => minute % interval === 0)
    .map((minute) => selectedHour * 60 + minute)
    .filter(
      (hour) =>
        hour >= Math.floor(startAt / 60) && hour <= Math.ceil(endAt / 60)
    )
    .map((minute) => minute - selectedHour * 60);
};

const getAvailableOptions = (
  startAt: number,
  endAt: number,
  interval: number
): string[] => {
  const availableHours = getAvailableHours(startAt, endAt);
  return availableHours.flatMap((h) =>
    getAvailableMinutes(h, interval, startAt, endAt).map((m) =>
      getTimeOption(h, m)
    )
  );
};

type TimeInputType = OrderInfo["timeInput"];

export interface TimeSelectProps {
  interval: Plugins.MinuteInterval;
  endAt: number;
  startAt: number;
  value: TimeInputType;
  onChange: (newValue: TimeInputType) => void;
}

export const TimeSelect: FC<TimeSelectProps> = ({
  onChange,
  value,
  interval = 30,
  startAt = 27000,
  endAt = 57600,
}) => {
  const selectedTimeOption = useMemo(
    () => getTimeOption(getHour(value), getMinutes(value)),
    [value]
  );
  const availableOptions: string[] = useMemo(
    () => getAvailableOptions(startAt, endAt, interval),
    [startAt, endAt, interval]
  );

  const updateTime = useCallback(
    (newValue: string) => {
      const [h, m] = newValue.split(SEPARATOR).map((item) => Number(item));
      const newTime = h * 3600 + m * 60;

      if (value === newTime) return;

      const validTime =
        (newTime < startAt && startAt) || (newTime > endAt && endAt) || newTime;

      onChange(validTime);
    },
    [endAt, startAt, value, onChange]
  );

  useEffect(() => {
    if (value) updateTime(selectedTimeOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="w-full h-full relative flex items-center text-text-primary"
      id="time-picker-wrapper"
    >
      <Select
        className="w-fit"
        value={selectedTimeOption}
        onChange={(e) => updateTime(e)}
      >
        {availableOptions.map((timeOpt) => {
          return (
            <Select.Option value={timeOpt} key={timeOpt}>
              {timeOpt}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};
