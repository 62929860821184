import { OrderOrder, OrderOrderProduct } from "@kanpla/types";

export const calculateAmountOfOrderItems = (order: OrderOrder) =>
  Object.values(order || {}).reduce(
    (orderAcc: number, i: OrderOrderProduct) => {
      // Calculate extra price for configurations

      return orderAcc + (i.amount || 0);
    },
    0
  );
