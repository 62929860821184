import { Module, OrderInfo, Plugins } from "@kanpla/types";
import { isEmpty } from "lodash";
import React, { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";
import { TextInputDisplay, TimeInputDisplay } from "..";

interface Props {
  children: ReactNode; // React children (element)
  wrapperChildren?: ReactNode; // React children (element)
  pulseCounter?: number; // used to trigger receipt pulse animaiton
  center?: boolean;
  className?: string;
  isSaving?: boolean;
  withPadding?: boolean;
  svgColor?: string;
  fullWidth?: boolean;
}

export const ReceiptWrapper = (props: Props) => {
  const {
    children,
    wrapperChildren = <></>,
    pulseCounter = 0,
    center = false,
    className,
    isSaving,
    withPadding = false,
    svgColor,
    fullWidth,
  } = props;

  // Animation
  const [animationProps, setAnimationProps] = useSpring(() => ({
    transform: `translateY(0px)`,
  }));
  useEffect(() => {
    if (pulseCounter === 0) return;
    setAnimationProps({
      transform: `translateY(-50px)`,
      config: config.gentle,
    });
    setAnimationProps({
      transform: `translateY(0px)`,
      delay: 600,
      config: { mass: 2, tension: 170, friction: 20 },
    });
  }, [pulseCounter]);

  return (
    <div className="-mx-2 md:-mx-6">
      <div
        className={`${
          center
            ? "md:mx-auto"
            : "md:float-right md:pl-8 lg:pl-12 xl:pl-32 md:pb-16 md: pt-4 md:pt-0"
        } ${fullWidth ? "w-full" : "w-full md:w-1/2 lg:w-3/7 xl:w-1/2"}  ${
          className || ""
        } md:pr-4 relative overflow-hidden`}
        style={{ zIndex: 11 }}
      >
        {wrapperChildren}
        <div
          className={`relative rounded-t lg:px-3 overflow-hidden text-text-primary -mt-1 ${
            withPadding ? "pb-20" : "pb-4 md:pb-20"
          } ${isSaving ? "pointer-events-none animate-pulse" : ""}`}
        >
          <div className="left-0 mx-3 right-0 absolute -top-1 h-3 bg-gradient-to-b from-black opacity-20 rounded-full z-10 hidden md:block"></div>

          <animated.div
            className="select-none relative mb-8 w-full px-4"
            style={{
              ...animationProps,
            }}
          >
            <div className="absolute inset-0 h-full mx-4 shadow-lg"></div>
            <div className="relative">
              <div className="bg-receipt-background px-5 md:px-6 lg:px-8 pb-6 border-divider-main rounded-t-lg w-full pt-20 -mt-8 ">
                {children}
              </div>

              <div
                className="absolute inset-x-0 bottom-0 -mb-1 w-full"
                style={{
                  height: 4,
                  backgroundRepeat: "repeat-x",
                  backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='4' viewBox='0 0 12 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 0H0V4H2C2 1.79086 3.79086 0 6 0C8.20914 0 10 1.79086 10 4H12V0H10H6H2Z' fill='%23${(
                    svgColor || "#ffffff"
                  ).replace("#", "")}'/%3E%3C/svg%3E%0A")`,
                }}
              />
            </div>
          </animated.div>
        </div>
      </div>
    </div>
  );
};

interface InfoDisplayProps {
  orderInfo: OrderInfo;
  module: Module;
}

ReceiptWrapper.InfoDisplay = ({ orderInfo, module }: InfoDisplayProps) => {
  const { t, i18n } = useTranslation(["translation"]);
  return (
    <div className="text-xs text-text-primary">
      {module?.plugins?.multipleOrders?.active && (
        <div>
          {isEmpty(i18n) ? "Navn" : t("translation:name")}: {orderInfo?.name}
        </div>
      )}
      {module?.plugins?.timeInput?.active && (
        <TimeInputDisplay timeInput={orderInfo?.timeInput} />
      )}
      {module?.plugins?.invoiceReference?.active &&
        orderInfo?.reference &&
        orderInfo?.reference !== "" && (
          <div>Reference: {orderInfo?.reference}</div>
        )}
      <TextInputDisplay
        textInput={orderInfo?.textInput}
        plugin={module?.plugins?.textInput as Plugins.TextInput}
      />
    </div>
  );
};

interface FooterProps {
  total?: number;
  children?: ReactNode;
  /** @deprecated */
  wasPaidWithMobilePay?: boolean;
}

ReceiptWrapper.Footer = (props: FooterProps) => {
  const { total = null, children = null } = props;
  const { t, i18n } = useTranslation(["libs"]);

  return (
    <div className="bg-receipt-background pb-6 border-divider-main rounded-t w-full relative">
      <div className=" flex justify-between text-xs text-text-primary">
        <div className="pt-4">{children}</div>
        {total !== null && (
          <div className="flex flex-col items-end">
            <div className="ml-auto pt-4">
              {" "}
              {isEmpty(i18n)
                ? `${total} kr. i alt`
                : t("libs:kr-total", { value: total })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
