import {
  DineroOrganization,
  EconomicGetResponse,
  Partner,
  VismaProcessContent,
} from "@kanpla/types";
import axios from "axios";

export const getDineroOrganizations = async (
  partner: Partner
): Promise<VismaProcessContent> => {
  const requestURI = `https://api.dinero.dk/v1/organizations`;
  try {
    const response = await axios.get<DineroOrganization>(requestURI, {
      headers: {
        Authorization: `Bearer ${partner.integrations?.dineroToken}`,
        Accept: "application/json",
      },
    });

    // const response = await fetch(requestURI, {
    //   method: "GET",
    //   headers: new Headers({
    //     Authorization: `Bearer ${partner.integrations?.dineroToken}`,
    //     Accept: "application/json",
    //     "Content-Type": "application/x-www-form-urlencoded",
    //     "Access-Control-Allow-Origin": "true",
    //   }),
    // });

    console.log("status", response.status);

    const data = response;

    return {
      type: "success",
      data,
    };
  } catch (e) {
    return {
      type: "error",
      msg: "Error getting organizations from Dinero",
      data: e,
    };
  }

  // try {
  //   const response = await axios.get<EconomicGetResponse>(
  //     "https://restapi.e-conomic.com/customers?pagesize=500",
  //     {
  //       headers: {
  //         "X-AgreementGrantToken": token,
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   );
  //   const pagination = response.data?.pagination;
  //   if (!pagination) return;
  //   const { pageSize, results } = pagination;
  //   const maxCalls = Math.ceil(results / pageSize);
  //   const customerResults = [];
  //   for (let pageIndex = 0; pageIndex < maxCalls; pageIndex++) {
  //     const push = await axios
  //       .get(
  //         `https://restapi.e-conomic.com/customers?pagesize=${pageSize}&skippages=${pageIndex}`,
  //         {
  //           headers: {
  //             "X-AppSecretToken": economicAppSecretToken,
  //             "X-AgreementGrantToken": token,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       )
  //       .then((r) => {
  //         customerResults.push(...(r.data?.collection || {}));
  //         if (pageIndex === maxCalls - 1) return cb(customerResults);
  //       })
  //       .catch((e) => console.log("Visma error", e));
  //   }
  // } catch (e) {
  //   console.log("Visma error", e);
  // }
};
