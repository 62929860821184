import { ProductBank, School, Scope, Tree } from "@kanpla/types";
import { filterTopLevel } from "./filterTopLevel";

type Props = {
  scope: Scope;
  tree: Tree;
};

export const getScopeHeadsFromScope = ({ scope, tree }: Props) => {
  const { generatedSchoolIds, generatedProductBankIds } = scope || {};
  const topLevelSchoolIds = (generatedSchoolIds || []).filter((schoolId) =>
    filterTopLevel(tree?.schools, schoolId, generatedProductBankIds)
  );
  const topLevelProductBankIds = (generatedProductBankIds || []).filter(
    (productBankId) =>
      filterTopLevel(tree?.productBanks, productBankId, generatedProductBankIds)
  );

  const scopeHeads: string[] = [
    ...topLevelSchoolIds,
    ...topLevelProductBankIds,
  ];
  return scopeHeads;
};
