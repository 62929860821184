import { db, fetchDocument, useLocationHostname } from "@kanpla/system";
import { OrderOrder, Payment } from "@kanpla/types";
import { message } from "antd";
import moment from "moment";
import { useRouter } from "next/router";
import { parseDomain, ParseResultType } from "parse-domain";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../components/contextProvider";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { getInvoiceAmount, reepayCardAdd } from "../../lib/firebase.functions";

interface Props {
  mode: "order" | "credit" | "subscription";
  setReceiptTime?: Dispatch<SetStateAction<number>>;
  setCheckoutItems?: Dispatch<SetStateAction<OrderOrder>>;
  setReceiptOpen?: Dispatch<SetStateAction<boolean>>;
  callback?: () => void;
}

const useWindowPayment = (props: Props) => {
  const { t, i18n } = useTranslation(["mealplan2", "translation", "payment"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const {
    mode: modeProp,
    setReceiptOpen,
    setCheckoutItems,
    setReceiptTime,
    callback = () => null,
  } = props;
  const {
    loadCards,
    setBalance,
    balance,
    schoolId,
    moduleId,
    setOpenBasket,
    setIsSaving,
    setBasket,
  } = useContainer(AppContext);

  /** Indicate if the result was processed and reported to avoid double messages */
  const [reported, setReported] = useState(false);

  const router = useRouter();

  const [baseUrl, setBaseUrl] = useState(null);

  const [amountShownTakeFirstBanner, setAmountShownTakeFirstBanner] =
    useLocalStorage("amount_shown_take_first_banner");

  // Retrieve Reepay session id
  const [reepaySessionId, setReepaySessionId] =
    useLocalStorage("reepay-session-id");

  // Prevent handle function to be fired consecutively
  const [isBeingProcessed, setIsBeingProcessed] = useLocalStorage(
    "reepay-in-progress",
    false
  );

  const hostname = useLocationHostname({});
  const parsed = parseDomain(hostname);

  const getPartnerUrl = () => {
    if (parsed.type === ParseResultType.Listed) {
      const { subDomains } = parsed;
      const newUrl = subDomains.pop();
      if (!newUrl) return "kanpla";
      return newUrl;
    } else {
      return "kanpla";
    }
  };

  const partnerUrl = getPartnerUrl();

  useEffect(() => {
    if (typeof window === undefined || partnerUrl === undefined) return;

    // @ts-ignore
    if (isMobile && window.isRenderedInWebView) {
      setBaseUrl(`${partnerUrl !== "kanpla" ? partnerUrl : "kanpla"}://`);
    } else {
      setBaseUrl(`${window.location.origin}/`);
    }
  }, [partnerUrl]);

  const resetRouter = async () => {
    // remove queries from the url
    await router.replace(
      `${baseUrl}app/s/${schoolId}/m/${moduleId}`,
      undefined,
      { shallow: true }
    );
  };

  const retrieveAndSubmitOrder = async ({ paymentId }) => {
    const data = await fetchDocument<Payment>(
      db.collection("payments").doc(paymentId)
    );

    const { order, valid } = data;
    if (!valid) throw new Error(t("payment:message.payment-not-valid"));

    // open receipt / handle kanpla go stuff
    setAmountShownTakeFirstBanner(amountShownTakeFirstBanner + 1);
    setOpenBasket(false);
    setReceiptTime(moment().unix());
    setCheckoutItems(order.order);
    setReceiptOpen(true);
  };

  const handleReepayFallback = async () => {
    const {
      id: sessionId,
      invoice: invoiceId,
      payment_method: cardHandle,
      orderId: paymentId,
      cancel,
      mode,
      error,
    } = router.query;

    try {
      if (reported) return;
      if (!reepaySessionId) return;

      setIsBeingProcessed(true);
      setIsSaving(1);

      if (error)
        throw new Error(
          `${t("mealplan2:message.error.error-code", { value: error })}`
        );

      if (cancel && cancel === "true") {
        setIsBeingProcessed(false);
        await resetRouter();
        throw new Error(t("payment:message.your-payment-cancelled"));
      }

      if (!sessionId && !invoiceId) {
        setIsBeingProcessed(false);
        return;
      }

      if (mode === "order" && !paymentId) throw new Error("Incorrect mode");
      if (reepaySessionId !== sessionId)
        throw new Error("Session er ikke gyldig");

      // if payment is not settled from direct order, update the balance
      if (modeProp === "credit" && mode === "credit") {
        // get invoice
        const { data: validAmount } = await getInvoiceAmount({ invoiceId });
        setBalance(balance + validAmount);
      }

      if (mode === "order") {
        await retrieveAndSubmitOrder({ paymentId });
      }

      // store user card
      if (cardHandle) {
        await reepayCardAdd({
          cardId: cardHandle,
        });

        loadCards();
      }

      setBasket({});

      // This is force-cleaning the basket! Should be refactored
      setTimeout(() => setBasket({}), 300);
      setTimeout(() => setBasket({}), 500);
      setTimeout(() => setBasket({}), 700);
      setTimeout(() => setBasket({}), 1000);

      callback();
      message.success({
        key: "payment-successful",
        content: t("translation:message.success.payment-successful"),
      });
    } catch (err) {
      message.error({
        key: "payment-cancelled",
        content: t("mealplan2:message.error.payment-failed", {
          value: err.message,
        }),
      });
      console.error(err);
      setOpenBasket(true);
    } finally {
      await resetRouter();
      setIsSaving(0);
      setReported(true);
      setReepaySessionId(null);
      setIsBeingProcessed(false);
    }
  };

  useEffect(() => {
    if (
      !schoolId ||
      !moduleId ||
      typeof setReceiptOpen === "undefined" ||
      typeof setCheckoutItems === "undefined" ||
      typeof setReceiptTime === "undefined" ||
      typeof setBalance === "undefined" ||
      typeof loadCards === "undefined"
    )
      return;

    if (
      Object.values(router?.query).length === 0 ||
      Boolean(isBeingProcessed) ||
      reepaySessionId === null
    ) {
      return;
    }

    // Since this hook gets called right after the refresh, some functions and values are still undefined
    // we first check that we have them available, depending on the mode of the payment made
    if (
      modeProp === "order" &&
      (typeof setReceiptOpen === "undefined" ||
        typeof setReceiptTime === "undefined" ||
        typeof setCheckoutItems === "undefined")
    )
      return;

    if (
      modeProp === "credit" &&
      (typeof setBalance === "undefined" ||
        typeof loadCards === "undefined" ||
        !balance)
    ) {
      return;
    }

    setOpenBasket(false);

    handleReepayFallback();
  }, [
    setReceiptOpen,
    setCheckoutItems,
    setReceiptTime,
    setBalance,
    loadCards,
    balance,
    schoolId,
    moduleId,
    router?.query,
    isBeingProcessed,
    reepaySessionId,
  ]);

  return {
    callbackUrl: `${baseUrl}app/s/${schoolId}/m/${moduleId}`,
  };
};

export default useWindowPayment;
