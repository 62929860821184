import React from "react";
import { getPixelValues, size } from "./IconSizes";

interface Props {
  color?: string;
  size?: size;
}

export const TakeawayIcon = ({ color = null, size = "regular" }: Props) => {
  const dimension = getPixelValues(size);

  return (
    <svg
      width={dimension}
      height={dimension}
      viewBox="0 0 57 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.4082 12.8416C53.3492 12.6648 53.1135 12.6648 53.0545 12.8416L48.2806 34.4717C48.2216 34.7664 48.4574 35.0022 48.7521 34.9433L56.0604 32.6447C56.355 32.5268 56.5908 32.2321 56.5319 31.8785L53.4082 12.8416Z"
        fill={color || "#4A5568"}
      />
      <path
        d="M50.756 6.18143H44.5675V4.00073C44.5675 1.87897 42.8583 0.11084 40.6776 0.11084H36.3751C34.2534 0.11084 32.4853 1.82003 32.4853 4.00073V6.18143H25.2948C24.9412 6.18143 24.5876 6.41718 24.5287 6.82974L22.348 16.6134C24.5876 17.2028 26.2968 18.9709 26.4736 21.2695V21.3284C27.7113 21.741 28.6543 22.9787 28.6543 24.3343C28.6543 28.2241 26.7094 31.9372 23.4088 34.4715V35.0609C23.4088 35.2967 23.4088 35.5914 23.3499 35.8271H44.5675C44.9801 35.8271 45.2747 35.5324 45.3337 35.1788L51.5221 7.18337C51.64 6.65293 51.2864 6.18143 50.756 6.18143ZM41.8564 6.18143H35.1964V4.00073C35.1964 3.35242 35.7268 2.82198 36.3751 2.82198H40.6776C41.3259 2.82198 41.8564 3.35242 41.8564 4.00073V6.18143Z"
        fill={color || "#4A5568"}
      />
      <path
        d="M21.8175 18.9706C21.5228 18.9117 21.1692 18.8528 20.8156 18.8528C20.403 18.8528 19.9904 18.9117 19.5779 19.0296C19.2242 17.9687 18.0455 17.1436 16.631 17.1436C15.629 17.1436 14.745 17.5561 14.1556 18.1455C13.2126 17.7919 12.1517 17.6151 11.0319 17.6151C9.26377 17.6151 7.73139 18.0276 6.55263 18.7349C6.14007 18.4402 5.66857 18.2634 5.19707 18.2634C3.90043 18.2634 2.83956 19.5011 2.72168 21.0334H23.9982C23.7035 20.0904 22.8784 19.3243 21.8175 18.9706Z"
        fill={color || "#4A5568"}
      />
      <path
        d="M25.4127 23.5679H1.24827C0.835704 23.5679 0.541016 23.9215 0.541016 24.2751C0.541016 27.9293 2.60384 31.1119 5.78647 33.1158V35.0607C5.78647 35.4733 6.1401 35.768 6.49373 35.768H20.1673C20.5798 35.768 20.8745 35.4144 20.8745 35.0607V33.1747C24.0572 31.1708 26.12 27.9293 26.12 24.3341C26.1789 23.9215 25.8253 23.5679 25.4127 23.5679Z"
        fill={color || "#4A5568"}
      />
      <path
        d="M6.72945 13.4898L7.08307 14.3739C7.25989 14.7864 7.67245 15.0811 8.08501 15.0811C8.20289 15.0811 8.3797 15.0811 8.49758 14.9633C9.08696 14.7275 9.32271 14.0792 9.08696 13.4898L8.73333 12.6057C8.49758 12.0164 8.55651 11.3091 8.96908 10.8376C10.03 9.54098 10.1478 7.71391 9.26377 6.24046L8.79227 5.53321C8.49758 5.00277 7.79032 4.82596 7.25988 5.17958C6.72944 5.47427 6.55263 6.18152 6.90626 6.71196L7.37776 7.41922C7.79032 8.06753 7.73139 8.83372 7.25988 9.4231C6.37582 10.6019 6.14007 12.1342 6.72945 13.4898Z"
        fill={color || "#4A5568"}
      />
      <path
        d="M11.916 13.4898L12.2696 14.3739C12.4464 14.7864 12.859 15.0811 13.2715 15.0811C13.3894 15.0811 13.5662 15.0811 13.6841 14.9633C14.2735 14.7275 14.5092 14.0792 14.2735 13.4898L13.9199 12.6057C13.6841 12.0164 13.743 11.3091 14.1556 10.8376C15.2165 9.54098 15.3344 7.71391 14.4503 6.24046L13.9788 5.53321C13.6841 5.00277 12.9768 4.82596 12.4464 5.17958C11.916 5.53321 11.7392 6.18152 12.0928 6.71196L12.5643 7.41922C12.9768 8.06753 12.9179 8.83372 12.4464 9.4231C11.5623 10.6019 11.3266 12.1342 11.916 13.4898Z"
        fill={color || "#4A5568"}
      />
      <path
        d="M17.1615 13.4898L17.5151 14.3739C17.6919 14.7864 18.1045 15.0811 18.517 15.0811C18.6349 15.0811 18.8117 15.0811 18.9296 14.9633C19.519 14.7275 19.7547 14.0792 19.519 13.4898L19.1653 12.6057C18.9296 12.0164 18.9885 11.3091 19.4011 10.8376C20.462 9.54098 20.5798 7.71391 19.6958 6.24046L19.2243 5.53321C18.9296 5.00277 18.2223 4.82596 17.6919 5.17958C17.1615 5.47427 16.9846 6.18152 17.3383 6.71196L17.8098 7.41922C18.2223 8.06753 18.1634 8.83372 17.6919 9.4231C16.8078 10.6019 16.5721 12.1342 17.1615 13.4898Z"
        fill={color || "#4A5568"}
      />
    </svg>
  );
};
