import { Holiday } from "@kanpla/types";
import moment from "moment";
import { HolidaysRecurring } from "./../../../types/src/documents/holidaysRecurring";

export interface GetHolidaysData {
  //   dayIndexes: Array<DayIndex>;
  //   dateSeconds: Array<number>;
  holidays: Array<Holiday>;
  holidaysRecurring: Array<HolidaysRecurring>;
}

interface Props {
  db: any;
  schoolId: string;
  moduleId: string;
  /** Only use on server functions */
  allExistingDocuments?: boolean;
}

export const getHolidays = async (props: Props): Promise<GetHolidaysData> => {
  const { schoolId = "—", db, moduleId, allExistingDocuments = false } = props;

  // Helper
  const getDocuments = async (query) => {
    const limitedQuery = allExistingDocuments
      ? query
      : query.where("schoolIds", "array-contains", schoolId);

    const allDocuments = await limitedQuery
      .get()
      .then((res) =>
        res.docs.map((d) => ({ ...d.data(), id: d.id, ref: d.ref }))
      );

    const filteredDocuments = allDocuments.filter(
      (holiday: Holiday | HolidaysRecurring) => {
        const notDeleted = !holiday.deleted;
        const forSelectedModule =
          allExistingDocuments || holiday?.moduleIds?.includes(moduleId);

        return notDeleted && forSelectedModule;
      }
    );

    return filteredDocuments;
  };

  // 1. Fetch data
  const holidays = await getDocuments(
    db
      .collection("holidays")
      .where("to", ">=", moment().startOf("day").utc().toDate())
  );

  const holidaysRecurring = await getDocuments(
    db.collection("holidaysRecurring")
  );

  return {
    holidays,
    holidaysRecurring,
  };
};
