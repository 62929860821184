import { intersection } from "lodash";
import { ProductBank, Scope, School, Tree } from "@kanpla/types";
import { getSchoolIdsFromScope } from "./getSchoolIdsFromScope";

type Props = {
  scopes: Scope[];
  tree: Tree;
};

export const scopesIntersection = ({ scopes, tree }: Props) => {
  const scopesGeneratedSchoolIds = scopes.map((scope) => {
    if (scope.generatedSchoolIds) return scope.generatedSchoolIds;

    const newlyGeneratedSchoolIds = getSchoolIdsFromScope({
      scope,
      tree,
    });
    return newlyGeneratedSchoolIds;
  });

  const hasIntesection = intersection(...scopesGeneratedSchoolIds).length > 0;
  return hasIntesection;
};
