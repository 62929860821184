import { flexBulkOrder, FlexOrder } from "@kanpla/types";
const calculateDelivery = ({ school, individualDocs, bulkDocs }) => {
  const deliveryCost = school?.contract?.deliveryPrice?.[0]?.price || 0;
  const orderIsNotEmpty = (o: FlexOrder | flexBulkOrder) =>
    Object.values(o.order || {}).reduce((acc, m) => acc + m.amount, 0) > 0;

  // Number of days
  const allOrders: Array<FlexOrder | flexBulkOrder> = [
    ...individualDocs,
    ...bulkDocs,
  ];
  const onlyFullOrders = allOrders.filter((order) => orderIsNotEmpty(order));
  const allDates = onlyFullOrders.map((order) => order.date.seconds);
  const uniqueDates = [...new Set(allDates)];
  const numberOfDates = uniqueDates.length;
  // console.log(deliveryCost);
  return {
    deliveryCost: deliveryCost * numberOfDates,
    days: numberOfDates,
    singleCost: deliveryCost,
  };
};
export default calculateDelivery;
