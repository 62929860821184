import React from "react";

type Size = "small" | "medium" | "large";

interface Props {
  size?: Size;
  color?: string;
  className?: string;
  useCurrentColor?: boolean;
}

export const Spinner = ({
  size = "medium",
  color = "#48BB78",
  className,
  useCurrentColor,
}: Props) => {
  const getSize = (size: Size): string => {
    if (size === "small") return "h-5 w-5";
    if (size === "medium") return "h-10 w-10";

    return "h-20 w-20";
  };

  return (
    <svg
      className={`animate-spin ${getSize(size)} ${
        useCurrentColor ? "" : "text-background-primary"
      } ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className={`opacity-25 ${useCurrentColor ? "stroke-current" : ""}`}
        cx="12"
        cy="12"
        r="10"
        stroke={!useCurrentColor ? color : undefined}
        strokeWidth="4"
      ></circle>
      <path
        className={`opacity-75 ${useCurrentColor ? "fill-current" : ""}`}
        fill={!useCurrentColor ? color : undefined}
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

export default Spinner;
