import { FirebaseFirestore } from "@firebase/firestore-types";
import { PartnersInvoices, UniformOrderDataForInvoices } from "@kanpla/types";
import { isEmpty } from "lodash";
import { fetchCollection } from "../firestore/fetchCollection";
import { getMidnightSeconds } from "../time/getMidnightSeconds";

interface Props {
  db: FirebaseFirestore | any;
  schoolId: string;
  partnerId: string;
  settingsBlockId?: string;
}

export const getLastInvoiceMidnight = async (props: Props) => {
  const { db, schoolId, partnerId, settingsBlockId } = props;

  // 1. Get last invoice from the database
  const lastUniformInvoices = settingsBlockId
    ? await fetchCollection<UniformOrderDataForInvoices>(
        db
          .collection("uniformInvoicing")
          .where("settings_snapshot.id", "==", settingsBlockId)
          .orderBy("created_at", "desc")
          .limit(1)
      )
    : null;

  /**
   * Get the latest invoice from the database and return the last midnight
   * If doesn't exist yet, continue with the old data structure
   */
  if (lastUniformInvoices && !isEmpty(lastUniformInvoices)) {
    const lastUniformInvoice = lastUniformInvoices[0];

    const lastInvoiceSeconds = lastUniformInvoice.created_at;
    const lastInvoiceMidnight = getMidnightSeconds(lastInvoiceSeconds);

    if (lastInvoiceMidnight > 0) return lastInvoiceMidnight;
  }

  const res = await db
    .collection("partnersInvoices")
    .where("partnerId", "==", partnerId)
    .where("schoolId", "==", schoolId)
    .orderBy("createdAt", "desc")
    .limit(1)
    .get();

  // 2. Extract document data
  const lastDocumentData: PartnersInvoices | undefined = res.docs.map((d) =>
    d.data()
  )[0];

  // 3. Get UTC Midnight seconds of the last invoice
  const getLastInvoiceSeconds = () => {
    const lastInvoiceSeconds = lastDocumentData?.createdAt?.seconds || 0;
    const lastInvoiceMidnight = getMidnightSeconds(lastInvoiceSeconds);
    return lastInvoiceMidnight;
  };

  // 4. If no last invoice, return the beggining of all times
  const secondsFrom: number = lastDocumentData ? getLastInvoiceSeconds() : 0;

  return secondsFrom;
};
