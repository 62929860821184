interface Props {
  allowedOrderIds: Array<string> | null | undefined;
  ignoredOrderIds: Array<string>;
  orderId: string;
}

export const isOrderIgnored = ({
  allowedOrderIds,
  ignoredOrderIds,
  orderId,
}: Props) => {
  const notAllowed =
    Array.isArray(allowedOrderIds) && !allowedOrderIds?.includes(orderId);
  const isIgnored = ignoredOrderIds.includes(orderId);

  if (notAllowed || isIgnored) {
    return true;
  }

  return false;
};
