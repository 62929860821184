export const getMondaySeconds = (seconds: number) => {
  const thursdayModulus = 345600; // 4 days because 1.1.1970 is a Thursday
  const localModulus = seconds % 604800; // seconds to Thursday, 00:00
  const shouldJumpWeekBack = localModulus < 86400 * 4;
  let correctStart = seconds - localModulus + thursdayModulus; // go to the beginning of the week in CET
  if (shouldJumpWeekBack) correctStart = correctStart - 604800;
  return correctStart;
};

export default getMondaySeconds;
