import { FirestoreDocument, Scope, Tree } from "@kanpla/types";
import { chunk, get, uniqBy } from "lodash";
import { fetchCollection } from "../firestore/fetchCollection";
import { getScopeHeadsFromScope } from "./getScopeHeadsFromScope";
import { scopesIntersection } from "./scopesIntersection";

type Props = {
  query: any;
  scope: Scope;
  queryScopePath?: string;
  tree: Tree;
};

export const fetchByScope = async <T extends FirestoreDocument>({
  query,
  scope,
  tree,
  queryScopePath = "scope",
}: Props) => {
  const generatedScopeHeads = getScopeHeadsFromScope({
    scope,
    tree,
  });

  const batches = chunk(generatedScopeHeads, 10);

  const promises = batches.map(
    async (batchScopeHeads) =>
      await fetchCollection<T>(
        query.where(
          `${queryScopePath}.generatedQueryTargets`,
          "array-contains-any",
          batchScopeHeads
        ),
        true
      )
  );
  const allDocuments = (await Promise.all(promises)).flat();

  // remove duplicates (from batches, edge case when 10+ scopeHeads)
  const uniqueDocuments = uniqBy(allDocuments, (d) => d.id);

  // filter by scope (for edge case, when different schools within the same scopeHead)
  const onlyWithin = uniqueDocuments.filter((document) => {
    const documentScope = get(document, queryScopePath);
    const isWithin = scopesIntersection({
      scopes: [scope, documentScope],
      tree,
    });
    return isWithin;
  });

  return onlyWithin;
};
