/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  faCheckCircle,
  faCreditCard,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { callFunction, getReepayErrorText } from "@kanpla/system";
import { DotDotDot, DrawerOrModal, Spinner } from "@kanpla/ui";
import { Checkbox, message } from "antd";
import { useTranslation } from "react-i18next";
import { default as React, useState } from "react";
import { useContainer } from "unstated-next";
import { reepayPaymentChooseCall } from "../../../lib/firebase.functions";
import { AppContext } from "../../contextProvider";
import PaymentAmount from "../../forms/paymentAmount";
import useWindowPayment from "../../mealplan2/useWindowPayment";
import DisplayDefaultCard from "../../payment/design/DisplayDefaultCard";
import CreditCardInfo from "./CreditCardInfo";
import ErrorModal, { CardErrorProps } from "./ErrorModal";
import RefillSettings from "./RefillSettings";
import useChargeSession from "../../../lib/payment/UseChargeSession";
import { useLocalstorageState } from "rooks";
import { PaymentProvider } from "@kanpla/types";

interface Props {
  open: boolean;
  setOpen: (nextState: boolean) => void;
}

const Divider = () => {
  const { t } = useTranslation(["translation"]);
  return (
    <div className="pt-8 pb-2">
      <div className="border-b w-full border-divider-main flex items-center justify-center h-0 select-none">
        <div className="px-2 relative bg-background-primary text-text-secondary text-opacity-70 italic text-xs">
          {t("translation:or")}
        </div>
      </div>
    </div>
  );
};

const PaymentModal = (props: Props) => {
  const { open, setOpen } = props;
  const { t } = useTranslation(["translation", "modals"]);
  const { user, card, balance, setBalance, mobile } = useContainer(AppContext);

  const [defaultPaymentMethod, setDefaultPaymentMethod] =
    useLocalstorageState<PaymentProvider>("last-payment-method", "card");

  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [refillOpen, setRefillOpen] = useState(false);
  const [rememberCard, setRememberCard] = useState(true);
  const [paymentMethod, setPaymentMethod] =
    useState<PaymentProvider>(defaultPaymentMethod);

  const { callbackUrl } = useWindowPayment({
    mode: "credit",
    setReceiptOpen: () => true,
    setReceiptTime: () => true,
    setCheckoutItems: () => true,
  });

  const [cardError, setCardError] = useState<CardErrorProps>({
    text: t("translation:payment-failed"),
  });

  const [cardErrorModal, setCardErrorModal] = useState(false);

  const hasAutomaticBestilling =
    user &&
    typeof user.refillBelow === `number` &&
    typeof user.refillAmount === `number`;

  // On successful one time payment
  const { loadChargeSession } = useChargeSession({
    open,
    onSuccess: () => {
      const validAmount = parseInt(amount);
      setBalance(balance + validAmount);
      setOpen(false);
    },
    onError: (err: any) => {
      setCardError(getReepayErrorText(err.error || err?.message));
      setCardErrorModal(true);
      setLoading(false);
    },
    setLoading,
    rememberCard,
  });

  const chargeCustomer = async () => {
    setLoading(true);

    const { cardId } = card;

    try {
      const validAmount = parseInt(amount);
      const { data } = await reepayPaymentChooseCall({
        cardId,
        amount: validAmount,
      });

      // If the payment wasn't right
      if (data === "success") {
        setBalance(balance + validAmount);
        message.success(t("translation:message.success.payment-successful"));
      } else {
        console.error(data);
        setCardError(getReepayErrorText(data));
        setCardErrorModal(true);
      }
    } catch (err) {
      console.error(err);
      setCardErrorModal(true);
      message.error(t("translation:payment-failed"));
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };

  const chargeOneTime = async () => {
    setLoading(true);

    try {
      const validAmount = parseInt(amount);

      const isCardPayment = paymentMethod === "card";

      const sessionId = await callFunction("reepayPaymentOnce", {
        amount: validAmount,
        recurring: isCardPayment ? rememberCard : false,
        paymentMethod,
        isWindowSession: true,
        callbackUrl,
        mode: "credit",
      });

      // Remember payment method
      setDefaultPaymentMethod(paymentMethod);

      await loadChargeSession(
        sessionId,
        // isWindow
        true,
        paymentMethod === "mobilepay"
      );
      setLoading(false);
    } catch (err) {
      console.error(err);
      setCardErrorModal(true);
      message.error(t("translation:payment-failed"));
      setLoading(false);
    }
  };

  const validateAmount = (newAmount: number) => {
    if (!newAmount) {
      message.error(t("modals:message.error.please-select-the-amount"));
      return false;
    }
    if (newAmount < 50) {
      message.info(t("modals:message.info.choose-the-amount-over-50"));
      return false;
    }
    return true;
  };

  const disabledForNoPaymentMethod = !paymentMethod && !card;

  const actions = loading
    ? []
    : [
        {
          className: "w-full pb-8",
          type: "primary",
          disabled: disabledForNoPaymentMethod,
          onClick: async () => {
            const valid = validateAmount(parseInt(amount));
            if (!valid) return;

            if (card && paymentMethod === "card") {
              await chargeCustomer();
            } else {
              await chargeOneTime();
            }
          },
          label: (
            <>
              <FontAwesomeIcon
                icon={faCreditCard}
                className="mr-2 fa-swap-opacity"
              />
              {t("modals:deposit-short")}
            </>
          ),
        },
      ];

  return (
    <>
      <DrawerOrModal
        open={open}
        setOpen={setOpen}
        title={t("translation:insert-money")}
        subtitle={t("modals:modal.subtitles.how-much-credit")}
        showCloseButton
        actions={actions}
        actionsClassName={`${!mobile && !!card && "pb-16"}`}
      >
        {loading ? (
          <div className="my-8 max-w-xs m-auto pb-6 flex flex-col items-center justify-center">
            <div className="py-3">
              <Spinner size={"medium"} />
            </div>
            <p className="text-center text-sm text-text-secondary pt-4">
              <span role="img" aria-label="tid">
                ⌛
              </span>{" "}
              {t("translation:payment-can-be-slow")}
              <DotDotDot />
            </p>
          </div>
        ) : (
          <>
            <div className="mt-8 max-w-xs m-auto">
              <PaymentAmount amount={amount} setAmount={setAmount} />
              <DisplayDefaultCard
                card={card}
                onSelect={(method: PaymentProvider) => {
                  setPaymentMethod(method);
                  method !== "card" && setRememberCard(false);
                }}
                label="select"
                className="py-2 mt-5"
                defaultPaymentMethod={defaultPaymentMethod}
              />
              <div className="flex justify-center pt-5 border-divider-main" />
              {!card && paymentMethod === "card" && (
                <div className="flex justify-center mt-3">
                  <Checkbox
                    onChange={() => setRememberCard(!rememberCard)}
                    checked={rememberCard}
                    className="text-text-primary"
                  >
                    {t("translation:save-card-for-later-payment")}
                  </Checkbox>
                </div>
              )}
            </div>
            {card && <Divider />}
            <div className="pt-1 text-base">
              {!hasAutomaticBestilling && card && (
                <button
                  className="block text-primary-main mx-auto font-semibold py-2"
                  onClick={() => setRefillOpen(true)}
                >
                  {t("modals:setup-automatic-refueling")}
                </button>
              )}
              {hasAutomaticBestilling && (
                <button
                  className="block text-primary-main mx-auto font-semibold py-2"
                  onClick={() => setRefillOpen(true)}
                >
                  <FontAwesomeIcon icon={faCheckCircle} />{" "}
                  {t("modals:automatic-refuelling")}
                  <p className="text-text-secondary text-sm font-normal">
                    {t("modals:under-kr", {
                      refillBelow: user && user.refillBelow,
                      refillAmount: user.refillAmount,
                    })}
                  </p>
                  <p className="text-text-secondary text-sm font-semibold underline mt-1">
                    {t("translation:edit").toLowerCase()}
                  </p>
                </button>
              )}
            </div>
          </>
        )}
        {card && (
          <div className="pt-9 md:pt-0">
            <CreditCardInfo />
          </div>
        )}
      </DrawerOrModal>
      {/* ERROR MODAL */}
      <ErrorModal
        open={cardErrorModal}
        setOpen={setCardErrorModal}
        cardError={cardError}
        setCardError={setCardError}
      />
      {user && <RefillSettings open={refillOpen} setOpen={setRefillOpen} />}
    </>
  );
};

export default PaymentModal;
