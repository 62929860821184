import { Steps } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import { DrawerOrModal } from "./BottomDrawer/DrawerOrModal";
const { Step } = Steps;

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  subtitle?: string;
  data: Data[];
}

interface Data {
  title: string;
  description: string;
}

export const IntroModal = ({ open, setOpen, data, title, subtitle }: Props) => {
  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={title}
      subtitle={subtitle}
      actions={[
        {
          label: "Let's go!",
          onClick: () => setOpen(false),
          id: "#kanpla_go_intro_close",
        },
      ]}
      // innerClassName={`h-full bg-gray-100`}
    >
      <div className="rounded-none flex flex-col justify-center items-center h-full">
        <div className="px-2 max-w-md">
          <Steps direction="vertical" current={-1}>
            {data.map((content, i) => {
              return (
                <Step
                  key={i}
                  title={
                    <span className="text-text-primary font-semibold">
                      {content.title}
                    </span>
                  }
                  description={
                    <span className="text-text-secondary">
                      {content.description}
                    </span>
                  }
                />
              );
            })}
          </Steps>
        </div>
      </div>
    </DrawerOrModal>
  );
};

export default IntroModal;
