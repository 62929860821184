import React, { CSSProperties, useState } from "react";
import Imgix from "react-imgix";

interface Props {
  className?: string;
  src: string;
  alt: string;
  style?: CSSProperties;
  loadedAnyway?: boolean;
  dataCy?: string;
}

export const Image = ({
  className = "",
  src,
  alt,
  style = {},
  loadedAnyway = false,
  dataCy = "",
}: Props) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const finalLoaded = loadedAnyway || imageLoaded;

  if (!src) return null;

  if (src.includes("imgix.net")) {
    return (
      <Imgix
        src={src}
        className={className}
        htmlAttributes={{ alt, "data-cy": dataCy }}
        sizes="70vw"
      />
    );
  }

  return (
    <img
      src={src}
      alt={alt}
      onLoad={() => setImageLoaded(true)}
      className={`${
        finalLoaded && src ? "opacity-100" : "loader bg-background-secondary"
      } ${className}`}
      style={{ transition: "opacity .2s ease-out", ...style }}
      data-cy={dataCy}
    />
  );
};
