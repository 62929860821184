import { configLabel, rules } from "@kanpla/system";
import { Form, Input } from "antd";
import React from "react";

interface Props {
  type?: string;
  placeholder?: string;
  label: string;
  value: string;
  setValue: (param: string) => any;
  note?: string;
  warning?: string;
  required?: boolean;
  containerClassName?: string;
  name?: string;
}

export const FormInput = (props: Props) => {
  const {
    type,
    placeholder,
    label,
    value,
    setValue,
    note,
    warning,
    name,
    required = false,
    containerClassName = "",
  } = props;

  return (
    <Form.Item
      className={`${containerClassName}`}
      label={label}
      labelCol={configLabel({ span: 24 })}
      requiredMark={false}
      rules={[rules({ rule: "required", ruleValue: required, message: "" })]}
      initialValue={value}
      name={name}
    >
      <Input
        placeholder={placeholder}
        type={type || "text"}
        onChange={(e) => setValue(e.target.value)}
        disabled={warning ? true : false}
      />
      {note && (
        <p className="text-sm text-text-secondary mt-1 opacity-70">{note}</p>
      )}
      {warning && (
        <p className="text-sm font-medium text-warning-main mt-1">{warning}</p>
      )}
    </Form.Item>
  );
};

export default FormInput;
