type I18nProps = {
  /** Language to use to format the price or the amount */
  language?: string;
  /** Dictionaries to use to translate some pieces of the formatted price */
  dictionaries?: { [key: string]: string };
};

export const priceFormatter = (
  value: number,
  i18n?: I18nProps,
  hasMultiple?: boolean
) => {
  const price = new Intl.NumberFormat(i18n?.language || "da-DK", {
    style: "currency",
    currency: "DKK",
    minimumFractionDigits: value % 1 === 0 ? 0 : 2,
    maximumFractionDigits: value % 1 === 0 ? 0 : 2,
  }).format(value);

  if (!hasMultiple) return price;

  return `${i18n?.dictionaries?.from || "fra"} ${price}`;
};
