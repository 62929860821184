import { FlexOption, MenuItemVariants, OrderConfig } from "@kanpla/types";
import { ProductSettingsHeader } from "@kanpla/ui";
import { isEmpty, pickBy, sum } from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Choice from "./Choice";
import { InternalRewrittenProduct } from "./Options";

interface Props {
  option: FlexOption;
  color?: string;
  setError: (erroredModuleId: string, add: boolean) => void;
  orderChoices: OrderConfig["options"];
  variantNames?: MenuItemVariants;
  product: InternalRewrittenProduct;
  onChange: (newValue: OrderConfig["options"]) => void;
}

const Option = ({
  option,
  color,
  onChange,
  orderChoices,
  variantNames,
  product,
}: Props) => {
  const { t, i18n } = useTranslation(["libs"]);
  const { minimum, maximum } = option;
  const required = minimum && minimum > 0;
  const choiceIds = option.choices.map((ch) => `${ch.id}`);

  const choicesWithinThisOption = pickBy(orderChoices, (value, key) =>
    choiceIds.includes(`${key}`)
  );
  const otherChoices = pickBy(
    orderChoices,
    (value, key) => !choiceIds.includes(`${key}`)
  );
  const amountChosen = sum(
    Object.values(choicesWithinThisOption).map((ch) => ch.amount)
  );

  const getAmountText = () => {
    const textData = [];
    if (required)
      textData.push(isEmpty(i18n) ? "påkrævet" : t("libs:text-data.required"));
    if (minimum && minimum > 1)
      textData.push(
        isEmpty(i18n)
          ? `i det mindste ${minimum}`
          : t("libs:text-data.at-least", { value: minimum })
      );
    if (maximum)
      textData.push(
        isEmpty(i18n)
          ? `vælg op til ${maximum}`
          : t("libs:text-data.select-up-to", { value: maximum })
      );
    const amountText = textData.join(", ");
    return amountText;
  };

  const setChoice = (
    name: string,
    id: number,
    amount: number,
    price: number,
    extraName?: string,
    removeOtherChoices?: boolean
  ) => {
    const restOfChoices = pickBy(orderChoices, (v, k) => {
      const isCurrentChoice = `${k}` === `${id}`;
      const hasAmount = v.amount > 0;

      const isSameOptionGroup = choiceIds.includes(k);

      return removeOtherChoices
        ? !isSameOptionGroup && hasAmount
        : !isCurrentChoice && hasAmount;
    });

    const newValue = {
      ...restOfChoices,
      [id]: {
        name,
        extraName,
        extraPrice: price,
        amount: amount,
      },
    };

    onChange(newValue);
  };

  const setMinimumChoices = () => {
    if (required) {
      const hasSelectedEnough = amountChosen >= minimum;
      if (hasSelectedEnough) return;

      const firstChoice = option.choices[0];
      const newMinimumChoice: OrderConfig["options"] = {
        ...otherChoices,
        [firstChoice.id]: {
          name: firstChoice.name,
          extraPrice: firstChoice.price || 0,
          amount: minimum,
        },
      };

      onChange(newMinimumChoice);
    }
  };

  const orderChoicesTrigger = JSON.stringify(otherChoices);
  useEffect(() => {
    setMinimumChoices();
  }, [required, minimum, orderChoicesTrigger]);

  return (
    <div className="my-4 w-full text-left">
      <ProductSettingsHeader title={option.name} subtitle={getAmountText()} />
      <ul>
        {option.choices?.map((choice) => (
          <Choice
            key={choice.id}
            id={choice.id}
            title={choice.name}
            subtitle={variantNames?.[option.id]?.[choice.id]}
            price={choice.price}
            taxPercentage={product?.taxPercentage}
            color={color}
            max={choice.max}
            amount={orderChoices?.[choice?.id]?.amount}
            leftPerOption={option.maximum && option.maximum - amountChosen}
            disableMinus={minimum && minimum >= amountChosen}
            option={option}
            setChoice={setChoice}
          />
        ))}
      </ul>
    </div>
  );
};

export default Option;
