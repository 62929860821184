interface Props {
  /** col that the label have to fill */
  span: number;
}

interface Return {
  /** an object with the configurations */
  span: number;
}

export const configLabel = ({ span }: Props): Return => {
  return { span };
};
