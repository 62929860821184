import { ProductOptionExtension } from "@kanpla/types";
import { KanplaError } from "..";

export const getOptions = async (
  productBankIds: Array<string> = ["-"],
  db: any,
  extension?: ProductOptionExtension
) => {
  try {
    const productPromises = productBankIds.map(async (productBankId) => {
      const basicQuery = db
        .collection("productOptions")
        .where("productBankId", "==", productBankId);

      if (extension) {
        const data = await basicQuery
          .where("extension", "array-contains", extension)
          .get();

        return data?.docs?.map((d) => d.data());
      }

      const data = await basicQuery.get();
      return data?.docs?.map((d) => d.data());
    });

    const options = await Promise.all(productPromises);

    const finished = options.flat();

    return finished;
  } catch (e) {
    throw new KanplaError(e?.code, e?.message);
  }
};
