/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ReactNode } from "react";
import { ActionButton, ActionButtonProps } from "./ActionButton";

export type Action = ReactNode | ActionButtonProps;

interface Props {
  actions: Action[];
}

export const Actions = ({ actions }: Props) => {
  return (
    <div className={`w-full flex gap-2 p-3`}>
      {actions.map((action, index) => {
        // If element
        const isElement = React.isValidElement(action);
        if (isElement) {
          return <>{action as ReactNode}</>;
        }

        // If button
        return (
          <ActionButton
            key={`action-button-${index}`}
            {...(action as ActionButtonProps)}
          />
        );
      })}
    </div>
  );
};
