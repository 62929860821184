import { Billy } from "@kanpla/types";
import axios, { Method } from "axios";
import { createInvoice } from "./createInvoice";
import { getContacts } from "./getContacts";
import { getOrganizationId } from "./getOrganizations";
import { getProducts } from "./getProducts";
import { postProduct } from "./postProduct";

export class BillyClient {
  apiToken: string;
  static getOrganizationId: (
    client: BillyClient
  ) => Promise<Billy.Organization>;
  static getContacts: (
    client: BillyClient,
    enhanced?: boolean
  ) => Promise<Billy.Contact[]>;
  static createInvoice: (
    client: BillyClient,
    data: any
  ) => Promise<Billy.InvoiceResponse>;
  static getProducts: (client: BillyClient) => Promise<Billy.Product[]>;
  static postProduct: (
    client: BillyClient,
    name: string
  ) => Promise<Billy.Product>;

  constructor(apiToken) {
    this.apiToken = apiToken;
  }

  async request(method: Method, url: string, body?: any) {
    try {
      const res = await axios({
        baseURL: "https://api.billysbilling.com/v2",
        method,
        url,
        headers: {
          "X-Access-Token": this.apiToken,
          "Content-Type": "application/json",
        },
        data: body,
      });

      if (res.status >= 400) {
        throw new Error(
          `${method}: ${url} failed with ${res.status} - ${res.data}`
        );
      }

      return res.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}

BillyClient.getOrganizationId = getOrganizationId;
BillyClient.getContacts = getContacts;
BillyClient.createInvoice = createInvoice;
BillyClient.getProducts = getProducts;
BillyClient.postProduct = postProduct;
