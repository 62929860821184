import { Module } from "@kanpla/types";

type Props = {
  module: Module;
};

export const getActivePlugins = ({ module }: Props) => {
  const activePlugins = Object.fromEntries(
    Object.entries(module?.plugins || {})
      .filter(([k, v]) => v.active === true)
      .map(([k]) => [k, true])
  );
  if (module?.flow === "meeting") activePlugins.advancedVariants = true;

  return activePlugins;
};
