export const isBelowMinimum = ({ school, localOrder }) => {
  const { flexBulkMinimum } = school?.contract || {};
  if (typeof flexBulkMinimum !== `number`) return false;

  const orderAmount = Object.values(localOrder).reduce(
    (acc: number, item: { [amount: string]: number }) => acc + item.amount,
    0
  );
  return orderAmount < flexBulkMinimum;
};
