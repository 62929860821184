export type size = "big" | "regular" | "small";

export const getPixelValues = (size: size) => {
  switch (size) {
    case "big":
      return 64;
    case "regular":
      return 24;
    case "small":
      return 16;
    default:
      return 16;
  }
};
