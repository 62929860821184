import { faBell } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isExpoPushToken, useNotifications } from "@kanpla/system";
import { ButtonWithLoading, Modal } from "@kanpla/ui";
import "firebase/messaging";
import React, { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

export default () => {
  const { user, auth } = useContainer(AppContext);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  const { token, notification } = useNotifications({ user, auth });

  const init = async () => {
    try {
      if (typeof window === `undefined`) return;
      if (token !== null) return;
      if (!isExpoPushToken(token)) return;

      const { title, body } = notification;

      setTitle(title);
      setBody(body);
      setOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  // Init
  useEffect(() => {
    init();
  }, []);

  return (
    <Modal open={open} setOpen={setOpen} title={title}>
      <div className="absolute top-0 right-0 mt-2 mr-3 text-primary-main">
        <FontAwesomeIcon icon={faBell} />
      </div>
      <p className="text-center mb-2">{body}</p>

      <ButtonWithLoading text="OK" onClick={() => setOpen(false)} />
    </Modal>
  );
};
