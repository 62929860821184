/* eslint-disable @typescript-eslint/ban-ts-comment */
// import Intercom from "react-intercom";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";
const Intercom = dynamic(() => import("react-intercom"));

const IntercomProvider = () => {
  const { child, school, auth } = useContainer(AppContext);
  const [isNative, setIsNative] = useState(false);

  // Set child properties
  useEffect(() => {
    if (!window) return;

    // @ts-ignore
    window.intercomSettings = {
      // @ts-ignore
      ...window.intercomSettings,
      childName: child?.name || null,
      childId: child?.child_ref?.id || null,
    };
  }, [child]);

  // Set school properties
  useEffect(() => {
    if (!school) return;

    // @ts-ignore
    window.intercomSettings = {
      // @ts-ignore
      ...window.intercomSettings,
      schoolName: school?.name || null,
      schoolContactPhone: school?.contact?.tel || null,
      schoolContactEmail: school?.contact?.email || null,
      schoolContactName: school?.contact?.name || null,
    };
  }, [school]);

  useEffect(() => {
    if ((window as any).isRenderedInWebView) {
      setIsNative(true);
      return;
    }

    const timer = setTimeout(() => {
      if (navigator.platform.substr(0, 2) === "iP") {
        // iOS detected
        if ((window as any).webkit && (window as any).webkit.messageHandlers) {
          setIsNative(true);
        }
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  if (isNative) return null;

  // For logged in users
  if (auth.user && typeof window !== "undefined")
    return (
      <Intercom
        appID="f1qnpluk"
        user_id={auth.user && auth.user.uid}
        email={auth.user && auth.user.email}
      />
    );

  // For landing page
  return <Intercom appID="f1qnpluk" />;
};

export default IntercomProvider;
