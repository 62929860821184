import { DateTime } from "luxon";
import { getWeekSeconds } from "../time/getWeekSeconds";

interface Props {
  deadline: number; // seconds relative to UTC midnight
  deadlineWeekRelative?: boolean; // day relative: 3 days ago, week relative: before friday
  deadlineExcludingWeekends?: boolean; // Friday is the day before monday
}

export const getDefaultDateSeconds = (props: Props) => {
  const { deadline, deadlineWeekRelative, deadlineExcludingWeekends } = props;
  const CETOffset = DateTime.local().setZone("Europe/Copenhagen").offset * 60;

  const now = new Date();
  const nowSeconds = now.getTime() / 1000;

  const getDayRelative = () => {
    const nowOrderTil = nowSeconds - deadline + CETOffset;

    const nowOrderTilTime = nowOrderTil % 86400;
    const resDateSeconds: number = nowOrderTil - nowOrderTilTime + 86400;
    return resDateSeconds;
  };

  const getWeekRelative = () => {
    const thisWeek = getWeekSeconds(nowSeconds);
    const nextWeek = thisWeek + 604800;
    const deadlineForNextWeek = nextWeek + deadline - CETOffset; // e.g. friday 00:00

    const isPastDeadline = nowSeconds > deadlineForNextWeek;
    const nextNextWeek = nextWeek + 604800;

    const resDateSeconds: number = isPastDeadline ? nextNextWeek : nextWeek;
    return resDateSeconds;
  };

  let defaultDateSeconds: number = deadlineWeekRelative
    ? getWeekRelative()
    : getDayRelative();

  // Account for excluding weekend
  if (deadlineExcludingWeekends) {
    const fridayBeforeDate = getWeekSeconds(defaultDateSeconds + 86400 * 2);
    const fridayBeforeToday = getWeekSeconds(nowSeconds + 86400 * 2);
    const differenceOfFridays = fridayBeforeDate - fridayBeforeToday;
    const weeksCrossed = differenceOfFridays / 604800;

    const weekendSeconds = 86400 * 2;
    const secondsToAdd = weeksCrossed * weekendSeconds;
    defaultDateSeconds = defaultDateSeconds + secondsToAdd;
  }

  // Adjust for weekend
  const mondaySeconds = getWeekSeconds(defaultDateSeconds);
  const isWeekend = defaultDateSeconds - mondaySeconds > 4 * 86400;
  if (isWeekend) {
    const nextMondaySeconds = mondaySeconds + 604800;
    defaultDateSeconds = nextMondaySeconds;
  }

  return defaultDateSeconds;
};
