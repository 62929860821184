const danishErrors = {
  /**
   * Firebase errors
   */
  "auth/weak-password": "Kodeord skal være minimum 6 tegn",
  "auth/claims-too-large":
    "Datamængden har overskredet den maximale tilladte størrelse på 1000 bytes.",
  "auth/email-already-exists":
    "Denne email er allerede i brug af en existerende bruger.",
  "auth/id-token-expired": "Database ID'et er udløbet.",
  "auth/id-token-revoked": "Database ID'et er blevet ophævet.",
  "auth/insufficient-permission":
    "The credential used to initialize the Admin SDK has insufficient permission to access the requested Authentication resource. Refer to Set up a Firebase project for documentation on how to generate a credential with appropriate permissions and use it to authenticate the Admin SDKs.",
  "auth/internal-error":
    "Autentificeringsserveren stødte på en uventet fejl under forsøget på at behandle anmodningen. Fejlmeddelelsen bør indeholde yderligere oplysninger fra serveren. Hvis fejlen fortsætter, kan du rapportere problemet til vores supportkanal.",
  "auth/invalid-argument":
    "Der blev givet et ugyldigt argument til en godkendelsesmetode. Fejlmeddelelsen bør indeholde yderligere oplysninger.",
  "auth/invalid-claims": "Attributterne er ugyldige.",
  "auth/invalid-continue-uri": "URL'en er ugyldig.",
  "auth/invalid-creation-time": "Ugyldig oprettelsestiden.",
  "auth/invalid-credential":
    "The credential used to authenticate the Admin SDKs cannot be used to perform the desired action. Certain Authentication methods such as createCustomToken() and verifyIdToken() require the SDK to be initialized with a certificate credential as opposed to a refresh token or Application Default credential. See Initialize the SDK for documentation on how to authenticate the Admin SDKs with a certificate credential.",
  "auth/invalid-disabled-field": "Den angivne værdi er ugyldig.",
  "auth/invalid-display-name": "Den angivne værdi er ugyldig.",
  "auth/invalid-dynamic-link-domain": "Den angivne værdi er ugyldig.",
  "auth/invalid-email": "Mailadressen har det forkerte format.",
  "auth/invalid-email-verified": "Den angivne værdi er ugyldig.",
  "auth/invalid-id-token": "Det angivne ID er ikke et gyldigt database ID.",
  "auth/invalid-last-sign-in-time":
    "Formatet af den sidste login-tid er ikke gyldig.",

  "auth/invalid-page-token":
    "The provided next page token in listUsers() is invalid. It must be a valid non-empty string.",
  "auth/invalid-password":
    "The provided value for the password user property is invalid. It must be a string with at least six characters.",
  "auth/invalid-password-hash":
    "The password hash must be a valid byte buffer.",
  "auth/invalid-password-salt": "The password salt must be a valid byte buffer",
  "auth/invalid-phone-number":
    "The provided value for the phoneNumber is invalid. It must be a non-empty E.164 standard compliant identifier string.",
  "auth/invalid-photo-url":
    "The provided value for the photoURL user property is invalid. It must be a string URL.",
  "auth/invalid-provider-data":
    "The providerData must be a valid array of UserInfo objects.",
  "auth/invalid-provider-id":
    "The providerId must be a valid supported provider identifier string.",
  "auth/invalid-oauth-responsetype":
    "Only exactly one OAuth responseType should be set to true.",
  "auth/invalid-session-cookie-duration":
    "The session cookie duration must be a valid number in milliseconds between 5 minutes and 2 weeks.",
  "auth/invalid-uid":
    "The provided uid must be a non-empty string with at most 128 characters.",
  "auth/invalid-user-import": "The user record to import is invalid.",
  "auth/maximum-user-count-exceeded":
    "The maximum allowed number of users to import has been exceeded.",
  "auth/missing-android-pkg-name":
    "An Android Package Name must be provided if the Android App is required to be installed.",
  "auth/missing-continue-uri":
    "A valid continue URL must be provided in the request.",
  "auth/missing-hash-algorithm":
    "Importing users with password hashes requires that the hashing algorithm and its parameters be provided.",
  "auth/missing-ios-bundle-id": "The request is missing an iOS Bundle ID.",
  "auth/missing-uid": "A uid identifier is required for the current operation.",
  "auth/missing-oauth-client-secret":
    "The OAuth configuration client secret is required to enable OIDC code flow.",
  "auth/operation-not-allowed":
    "The provided sign-in provider is disabled for your Firebase project. Enable it from the Sign-in Method section of the Firebase console.",
  "auth/phone-number-already-exists":
    "The provided phoneNumber is already in use by an existing user. Each user must have a unique phoneNumber.",
  "auth/project-not-found":
    "No Firebase project was found for the credential used to initialize the Admin SDKs. Refer to Set up a Firebase project for documentation on how to generate a credential for your project and use it to authenticate the Admin SDKs.",
  "auth/reserved-claims":
    "One or more custom user claims provided to setCustomUserClaims() are reserved. For example, OIDC specific claims such as (sub, iat, iss, exp, aud, auth_time, etc) should not be used as keys for custom claims.",
  "auth/session-cookie-expired": "Session-cookien er udløbet.",
  "auth/session-cookie-revoked": "Session-cookien er blevet tilbagekaldt.",
  "auth/uid-already-exists":
    "Den angivne bruger ID er allerede i brug af en eksisterende bruger. Hver bruger skal have et unikt bruger ID.",
  "auth/unauthorized-continue-uri":
    "The domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase Console.",
  "auth/user-not-found": "Brugeren findes ikke.",
  "auth/wrong-password": "Forkert kodeord.",
  "auth/too-many-requests":
    "Kontoen er midlertidigt deaktiveret grundet for mange forkerte log in forsøg. Du kan enten nulstille adgangskoden, eller vente til senere for at tilgå kontoen igen.",
  /***
   * Kanpla Errors
   */
  "kanpla/unauthorized": "Uautoriseret",
  "kanpla/not-enough-credit": "Ikke nok kredit.",
  "kanpla/not-enough-products": "Ikke nok produkter.",
  "kanpla/no-admin-order-credit": "Du kan ikke bestille med kredit endnu",
  "kanpla/missing-full-order": "Mangler den komplete ordre",
  "kanpla/couldnt-update": "Vi kunne ikke opdatere data",
  "kanpla/fill-email": "Indtast venligst din email!",
  "kanpla/fill-password": "Indtast venligst din adgangskode!",
  "kanpla/fill-canteen-id": "Indtast venligst kantinens ID!",
  "kanpla/wrong-canteen-id": "Forkert kantine ID",

  /***
   * POSONE Errors
   */
  "posone/login-issue": "Vi kunne ikke logge dig ind.",
  "posone/cant-create-token": "Vi kunne ikke oprette din PosOne konto.",
  "posone/not-enough-credit": "Ikke nok kredit på din PosOne konto.",

  /**
   * Reepay errors
   *
   */
  "reepay/declined_by_acquirer":
    "Transaktion er desværre afvist. Prøv igen senere eller kontakt din bank eller Kanpla.",
  "reepay/credit_card_expired":
    "Dit kreditkort er udløbet. Kontakt venligst din bank for vejledning eller prøv igen med et andet kort.",
  "reepay/insufficient_funds":
    "Der er desværre ikke kredit nok på din konto. Venligst tjek din konto og prøv igen senere.",
  "reepay/other":
    "Systemet kunne ikke finde fejlen for indbetaling. Kontakt os venligst, så vi kan finde en løsning på problemet ved indbetaling.",

  else: undefined,
};

export default danishErrors;
