/**
 * This method return dynamic rules for a single form control
 */

interface Props {
  /** rule name */
  rule: string;
  /** rule value */
  ruleValue: string | boolean;
  /** message to show for the validation */
  message: string;
}
interface Return {
  /** an object to help to enable the validation */
  [rule: string]: string | boolean;
  message: string;
}
export const rules = ({ rule, ruleValue, message }: Props): Return => {
  return {
    [rule]: ruleValue,
    message,
  };
};
