import { Button, message, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  Editor,
  EditorState,
  AtomicBlockUtils,
  ContentBlock,
  convertToRaw,
  getDefaultKeyBinding,
  convertFromRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import Option from "./Option";
import { editorToString } from "./editorToString";
import { useOutsideClickRef } from "rooks";
import { antdFilterOptionSearch } from "@kanpla/system";
import { TextBlockEditorProps } from "@kanpla/types";

interface Props {
  value?: TextBlockEditorProps;
  onChange?: (val: TextBlockEditorProps) => void;
  options: Array<{ label?: string; value: string }>;
}

const getLabel = (value: string, options: Props["options"]) => {
  const data = options.find((o) => o.value === value);

  return data?.label || value;
};

const convertFromString = (data: string) => {
  const blocksFromHTML = convertFromHTML(data);
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return state;
};

export const TextAreaWithOptions = ({
  value = { string: "", data: null },
  onChange,
  options = [],
}: Props) => {
  const [overlayShown, setOverlayShown] = useState(false);
  const dropdownRef = useRef(null);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const editorRef = useRef(null);

  /**
   * Initialise editor with data -- if it exists
   */
  useEffect(() => {
    const state = value?.data
      ? convertFromRaw(value.data)
      : convertFromString(value?.string || "");

    const stateWithContent = EditorState.createWithContent(state);
    const currentSelection = editorState.getSelection();
    const stateWithContentAndSelection = EditorState.forceSelection(
      stateWithContent,
      currentSelection
    );

    setEditorState(stateWithContentAndSelection);
  }, [value.string]);

  /** Transform content and save it in the field */
  const saveContent = () => {
    const content = editorState.getCurrentContent();
    const rawState = convertToRaw(content);

    const rawString = editorToString(rawState);

    onChange({
      string: rawString,
      data: rawState,
    });
  };

  /** Controls overlay when using / command */
  const showOverlay = () => {
    setOverlayShown(true);
    dropdownRef.current.focus();
  };

  const hideOverlay = () => {
    dropdownRef.current.blur();
    setOverlayShown(false);
  };

  const [overlayRef] = useOutsideClickRef(hideOverlay);

  /** Adding a block */
  const onButtonClick = async (value: string) => {
    try {
      editorRef.current.focus();

      await new Promise((resolve) => setTimeout(resolve, 500));

      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        "customContentData",
        "IMMUTABLE",
        { value: value, label: getLabel(value, options) }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      });

      const currentSelection = editorState.getSelection();
      const stateWithContentAndSelection = EditorState.forceSelection(
        newEditorState,
        currentSelection
      );

      setEditorState(
        AtomicBlockUtils.insertAtomicBlock(
          stateWithContentAndSelection,
          entityKey,
          getLabel(value, options)
        )
      );
    } catch (e) {
      if (
        e?.message ===
        `can't access property "getEntityAt", startBlock is undefined`
      )
        return;

      message.error(e?.message);
      console.error(e);
    }
  };

  /**
   * New lines were reset because of how draft.js works (creating blocks forced them on new line)
   * Therefore it's disabled (visually put next to each other using `float` in custom.scss file)
   * This adds a block with `isEnter` boolean, so that we can later transform it into a `\n` escaped string
   */
  const addSpace = () => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "ENTER",
      "IMMUTABLE",
      { isEnter: true }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    const currentSelection = editorState.getSelection();
    const stateWithContentAndSelection = EditorState.forceSelection(
      newEditorState,
      currentSelection
    );

    setEditorState(
      AtomicBlockUtils.insertAtomicBlock(
        stateWithContentAndSelection,
        entityKey,
        " "
      )
    );
  };

  /** Controlling custom key actions */
  const bindKeys = (e: any) => {
    if (e.key === "/") return "show-options";
    if (e.key === "Enter") return "do-enter";

    return getDefaultKeyBinding(e);
  };

  /** And handling the commands */
  const handleKeyCommand = (command: string) => {
    if (command === "show-options") {
      showOverlay();
      // Perform a request to save your contents, set
      // a new `editorState`, etc.
      return "handled";
    }

    if (command === "do-enter") {
      addSpace();
      return;
    }

    hideOverlay();

    return "not-handled";
  };

  /** Trigger custom renderer on blocks */
  const myBlockRenderer = (contentBlock: ContentBlock) => {
    const type = contentBlock.getType();
    if (type === "atomic") {
      return {
        component: Option,
        editable: true,
      };
    }
  };

  return (
    <div className="flex flex-col-reverse xl:grid grid-cols-2 gap-2 bg-background-secondary rounded-lg">
      <div className="p-1 px-1.5 block inset-0 border shadow-sm hover:border-divider-main transition rounded-lg w-full cursor-text text-area-options relative bg-background-primary">
        <div
          ref={overlayRef}
          className={`absolute w-4/5 right-0 top-0 -translate-y-full transform ${
            overlayShown ? "" : "scale-0"
          }`}
        >
          <Select
            options={options}
            ref={dropdownRef}
            showSearch
            filterOption={antdFilterOptionSearch}
            open={overlayShown}
            onSelect={(e) => {
              onButtonClick(e);
              hideOverlay();
            }}
          />
        </div>
        <Editor
          ref={editorRef}
          editorState={editorState}
          onChange={setEditorState}
          blockRendererFn={myBlockRenderer}
          onBlur={saveContent}
          keyBindingFn={bindKeys}
          handleKeyCommand={handleKeyCommand}
        />
      </div>
      <div className="bg-background-secondary rounded-lg p-3 flex gap-2 flex-wrap">
        {options.map((o) => {
          if (o.label)
            return (
              <Button
                size="small"
                className="text-sm text-text-secondary"
                onClick={() => onButtonClick(o.value)}
              >
                {o.label}
              </Button>
            );

          return (
            <Button
              size="small"
              className="text-sm text-text-secondary"
              onClick={() => onButtonClick(o.value)}
            >
              {o.value}
            </Button>
          );
        })}
      </div>
    </div>
  );
};
