import danishErrors from "./da/errors";

export const getReepayErrorText = (text: string) => {
  if (!text) {
    return {
      text: danishErrors["reepay/other"],
    };
  }

  if (text.includes("declined_by_acquirer"))
    return {
      text: danishErrors["reepay/declined_by_acquirer"],
    };

  if (text.includes("credit_card_expired"))
    return {
      text: danishErrors["reepay/credit_card_expired"],
    };

  if (text.includes("insufficient_funds"))
    return {
      text: danishErrors["reepay/insufficient_funds"],
    };

  return {
    text: danishErrors["reepay/other"],
    extra: text,
  };
};
