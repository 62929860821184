import { calculateConfigTotal, priceFormatter } from "@kanpla/system";
import { CustomOrderContent } from "@kanpla/types";
import { message } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DrawerOrModal, InputAmount, ProductProps } from "../..";
import PurchaseAnimation from "../PurchaseAnimation";
import { ProductDetailsContent } from "./ProductDetailsContent";

const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  return { width };
};

interface Props extends ProductProps {
  setOpen: (nextState: boolean) => void;
  open: boolean;
  orderButtonDisabled?: boolean;
  currentDayTimestamp?: number;
}

export const ProductDetails = (props: Props) => {
  const {
    fromAdmin,
    hideAmount,
    maxAmount,
    disabled,
    orderButtonDisabled,
    purchaseLabel,
    product,
    currentDayTimestamp,
    tooltip,
    hidePriceInLabel,
  } = props;
  const { i18n } = useTranslation();
  const currentLanguage = isEmpty(i18n) ? "da" : i18n.language;
  const [data, setData] = useState<CustomOrderContent>(Object);
  const { open, setOpen } = props;
  const { width } = useViewport();
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (!open) return setShowConfirm(false);
  }, [open]);

  const onPurchase = async () => {
    await props.onPurchase(props.product, data);
  };

  const amount = data?.productAmount || props.initialAmount || 1;

  const updateAmount = (
    newAmount: number,
    newOptions?: Props["initialOptions"]
  ) => {
    if (newOptions) {
      setData((data) => ({
        ...data,
        optionChoices: newOptions,
      }));
    }

    if (newAmount === data?.productAmount) return;
    setData((data) => ({
      ...data,
      productAmount: newAmount,
    }));
  };

  useEffect(() => {
    if (!props?.initialAmount) return;
    updateAmount(props.initialAmount, props?.initialOptions);
  }, [props?.initialAmount, props?.initialOptions]);

  useEffect(() => {
    updateAmount(amount);
  }, [amount]);

  const optionsPrice = calculateConfigTotal({
    options: data.optionChoices,
    amount,
  });

  /**
   * If the periodPrice is truthy, it means that the product come from a subscription,
   * and the total price will be the price per day of the subscription times
   * the number of dates selected. Otherwise the total price will be the price of the product plus
   * some options of the price, times the amount of it.
   */
  const totalPrice = product.periodPrice
    ? product.periodPrice
    : amount * (product.price + optionsPrice);

  const actions = [
    !hideAmount ? (
      <InputAmount
        amount={amount}
        setAmount={updateAmount}
        minAmount={1}
        maxAmount={maxAmount}
      />
    ) : (
      <></>
    ),
    {
      label: purchaseLabel ? (
        <p>
          {purchaseLabel}{" "}
          {!hidePriceInLabel && (
            <span className="hidden md:inline">
              {priceFormatter(totalPrice, { language: currentLanguage })}
            </span>
          )}
        </p>
      ) : (
        `Bestil (${priceFormatter(totalPrice, { language: currentLanguage })})`
      ),
      onClick: async () => {
        try {
          await onPurchase();
          setShowConfirm(true);
          withAnimation
            ? setTimeout(() => setOpen(false), 4000)
            : setOpen(false);
        } catch (e) {
          if (e?.message.includes("Ikke nok kredit")) return;
          message.error(e?.message);
        }
      },
      type: "primary",
      disabled: orderButtonDisabled,
      id: `product_order_${product?.id}`,
      dataCy: "add-basket",
      tooltip,
    },
  ];

  if (!product) return null;
  const isMobile = width < 768;
  const withAnimation = !fromAdmin;

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      noPadding={true}
      actions={disabled ? [] : (actions as any)}
      floatingActions
    >
      {isMobile ? (
        <div
          id="product-detail-swipeable"
          className="overflow-y-auto mb-4 overflow-x-hidden flex-1 -mt-10"
          style={{ borderRadius: "24px 24px 0 0" }}
        >
          <ProductDetailsContent
            {...props}
            currentDayTimestamp={currentDayTimestamp}
            setData={setData}
            data={data}
          />
          <div
            className={`fixed bottom-0 pb-8 w-full bg-gradient-to-b from-transparent to-main-500`}
            style={{
              paddingBottom: `calc(var(--safe-inset-bottom))`,
            }}
          ></div>
        </div>
      ) : (
        <div className="w-full h-full ">
          <div className="mb-4">
            <ProductDetailsContent
              {...props}
              currentDayTimestamp={currentDayTimestamp}
              setData={setData}
              data={data}
            />
          </div>
        </div>
      )}
      {withAnimation && (
        <PurchaseAnimation
          out={showConfirm}
          mobile={isMobile}
          close={() => setShowConfirm(false)}
        />
      )}
    </DrawerOrModal>
  );
};
