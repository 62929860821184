import { Billy } from "@kanpla/types";
import { BillyClient } from ".";

export const createInvoice = async (client: BillyClient, data: any) => {
  const res = (await client.request("POST", "/invoices", {
    invoice: data,
  })) as Billy.InvoiceResponse;

  return res;
};
