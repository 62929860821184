import { db } from "@kanpla/system";
import { User } from "@kanpla/types";
import { useDocumentListener } from "libs/system/src/firestore/UseDocumentListener";
import { useEffect, useState } from "react";

export const useUser = ({ isSaving, userId }) => {
  const [balance, setBalance] = useState<number>(null);

  const updateBalance = (newBalance: number) => {
    if (isSaving === 0) setBalance(newBalance);
  };

  const [user = null] = useDocumentListener<User>({
    collection: "users",
    id: userId,
  });

  useEffect(() => {
    if (!user?.balance && user?.balance !== 0) return;
    updateBalance(user.balance);
  }, [user?.balance]);

  return { user, balance, setBalance };
};
