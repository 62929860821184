import { priceFormatter } from "@kanpla/system";
import { CustomOrderContent, FlexOption, MenuItem } from "@kanpla/types";
import { ProductSettingsHeader } from "@kanpla/ui";
import { Space } from "antd";
import { isEmpty } from "lodash";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { ProductOptions } from "../../flex/VariantsSelectModal/Options";
import { AllergensDisplay } from "../AllergensDisplay";
import DeadlineTimer from "../DeadlineTimer";
import { Image } from "../Image";
import { Labels } from "../Labels";
import { ProductProps } from "./Product";

interface Props
  extends Pick<
    ProductProps,
    | "product"
    | "isChildView"
    | "stock"
    | "showDeadlineCountdown"
    | "plugins"
    | "order"
    | "orderInfo"
    | "mealOptions"
    | "module"
    | "maxAmount"
    | "defaultReference"
    | "showOrderInfo"
    | "initialAmount"
    | "initialOptions"
    | "hasRequiredProduct"
    | "schoolId"
    | "customInputs"
    | "noImage"
    | "customBranding"
  > {
  setData: Dispatch<SetStateAction<CustomOrderContent>>;
  data: CustomOrderContent;
  currentDay?: {
    available: true;
    stock?: number;
    menu?: MenuItem;
  };
  currentDayTimestamp?: number;
}

export const ProductDetailsContent = (props: Props) => {
  const {
    product,
    isChildView = false,
    stock = null,
    showDeadlineCountdown = false,
    customInputs,
    noImage = false,
    currentDayTimestamp,
  } = props;
  const { t, i18n } = useTranslation(["libs", "translation"]);

  const hasDiscount = product?.originalPrice;
  // For leftovers products with no discount (already applied to price)
  const discountIsSameAsPrice = product?.originalPrice === product?.price;
  const showDiscountUI = !discountIsSameAsPrice && hasDiscount;

  // For display more information about the product in the current day of a menu
  const currentDay = product?.dates?.[currentDayTimestamp];

  const showStock = product?.isLeftover && stock !== Infinity && !isNaN(stock);

  const stockBg = () => {
    if (stock < 4) return "bg-danger-main";
    if (stock > 3 && stock < 6) return "bg-yellow-400";
    return "bg-primary-main";
  };

  const productPrice = product.taxPercentage
    ? (product.price as number) * (1 + product.taxPercentage)
    : product.price;

  const hideImage = noImage || !product?.photo;

  return (
    <div className="w-full h-full mb-6 kanpla-bg-primary rounded-t-lg">
      {!hideImage && (
        <div
          className={`w-full relative bg-gradient-to-bl md:rounded-t-lg from-gray-300 to-background-secondary user-select-none pointer-events-none  ${
            isChildView ? "h-52" : "h-72"
          }`}
        >
          <Image
            src={`${product.photo}?w=400`}
            alt={currentDay?.menu?.name || product.name}
            className="h-full w-full object-cover object-center md:rounded-t-lg inset-x absolute"
          />
          <Image
            src={`${product.photo}?w=600`}
            alt={currentDay?.menu?.name || product.name}
            className="h-full w-full object-cover object-center md:rounded-t-lg inset-x absolute"
          />

          <Labels
            labels={currentDay?.menu?.labels || product.labels}
            className={isChildView && "bottom-0 top-auto"}
          />

          {showStock && (
            <div
              className={`absolute ${
                isChildView ? "top-6 right-2" : "bottom-4 right-2"
              }`}
            >
              {stock && (
                <div
                  className={`py-1 px-4 ${stockBg()} font-semibold rounded-md text-background-primary flex items-center`}
                >
                  {!isEmpty(i18n)
                    ? t("libs:value-back", { value: stock })
                    : `${stock} tilbage`}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {showDeadlineCountdown && (
        <DeadlineTimer deadline={product?.individualDeadline || null} />
      )}
      <div
        className={`px-4 pt-6 md:p-6 kanpla-bg-primary ${
          hideImage ? "md:rounded-t-lg" : ""
        }`}
      >
        <div className="px-2 pb-2">
          <h3
            className="text-text-primary text-bold text-2xl"
            title={currentDay?.menu?.name || product.name}
          >
            {currentDay?.menu?.name || product.name}
          </h3>
          <div className="flex items-center mb-5 -mt-1">
            <p
              className={`${
                showDiscountUI
                  ? "text-text-disabled line-through text-sm"
                  : "text-primary-main"
              } text-md`}
            >
              {showDiscountUI ? (
                !isEmpty(i18n) ? (
                  t("libs:amount-with-value", { value: hasDiscount })
                ) : (
                  `${hasDiscount} kr.`
                )
              ) : (
                <>
                  {priceFormatter(
                    productPrice as number,
                    {
                      language: i18n?.language,
                      dictionaries: { from: t("libs:from") },
                    },
                    product.multiplePrices
                  )}
                  {product.extraPriceLabelText || ""}
                  {product.basePrice !== product.price &&
                  typeof product.price === "number" &&
                  typeof product.basePrice === "number" ? (
                    <span className="text-xs pl-1.5 text-text-disabled">
                      {!isEmpty(i18n)
                        ? t("libs:price-formatting", {
                            value:
                              (priceFormatter(
                                (product.price as number) *
                                  (1 + (product.taxPercentage || 0)) -
                                  product.basePrice
                              ),
                              { language: i18n?.language }),
                          })
                        : ` inkl. ${priceFormatter(
                            (product.price as number) *
                              (1 + (product.taxPercentage || 0)) -
                              product.basePrice
                          )} moms`}
                    </span>
                  ) : null}
                </>
              )}
            </p>
            {showDiscountUI && (
              <p className={`text-primary-main ml-2 font-semibold text-md`}>
                {priceFormatter(
                  productPrice as number,
                  {
                    language: i18n?.language,
                    dictionaries: { from: t("libs:from") },
                  },
                  product.multiplePrices
                )}
                {product.extraPriceLabelText || ""}
                {product.basePrice !== product.price &&
                typeof product.price === "number" &&
                typeof product.basePrice === "number" ? (
                  <span className="text-xs pl-1.5 text-text-disabled">
                    {!isEmpty(i18n)
                      ? t("libs:price-formatting", {
                          value:
                            (priceFormatter(
                              (product.price as number) *
                                (1 + (product.taxPercentage || 0)) -
                                product.basePrice
                            ),
                            { language: i18n?.language }),
                        })
                      : ` inkl. ${priceFormatter(
                          (product.price as number) *
                            (1 + (product.taxPercentage || 0)) -
                            product.basePrice
                        )} moms`}
                  </span>
                ) : null}
              </p>
            )}
          </div>

          {(currentDay?.menu?.description || product.description) && (
            <p className="mb-4 whitespace-pre-wrap text-text-secondary">
              {currentDay?.menu?.description || product.description}
            </p>
          )}

          {customInputs ? (
            customInputs
          ) : (
            <div className="-mx-2">
              <DefaultContent {...props} currentDay={currentDay} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const DefaultContent = (props: Props) => {
  const {
    product,
    plugins = {},
    orderInfo,
    mealOptions,
    module,
    defaultReference = "",
    order,
    setData,
    showOrderInfo = true,
    initialOptions = {},
    hasRequiredProduct = false,
    schoolId,
    data,
    currentDay,
  } = props;

  const options = mealOptions?.filter(
    (option: FlexOption) =>
      currentDay?.menu?.options?.includes(option?.id) ||
      product.options?.includes(option?.id)
  );

  const updateValues = (newData: Partial<CustomOrderContent>) => {
    // const combinedData = { ...data, ...newData };
    // const isDifferent = JSON.stringify(combinedData) !== JSON.stringify(data);
    // if (isDifferent) setData(combinedData);

    setData((data) => ({ ...data, ...newData }));
  };

  const {
    timeInput = null,
    reference = null,
    textInput = null,
    optionChoices = initialOptions,
    requiredProductOrder = null,
  } = data;

  return (
    <>
      <div className="px-2">
        <ProductOptions
          product={{
            name: currentDay?.menu?.name || product.name,
            id: product.productId,
            color: product.color,
            price: product.price,
            taxPercentage:
              module?.plugins?.taxSettings?.active && product.taxPercentage,
          }}
          options={options}
          value={optionChoices}
          onChange={(newValue) => updateValues({ optionChoices: newValue })}
        />
      </div>

      <Space direction="vertical" size="large" className="w-full">
        <AllergensDisplay
          allergens={currentDay?.menu?.allergens || product.allergens}
          pictograms={product?.pictograms}
        />
        {product.ingredients?.length > 0 && (
          <div className="px-2 block">
            <ProductSettingsHeader title="Ingredienser" />
            <div className="mt-2 text-sm text-text-secondary">
              {product.ingredients.map(({ name }) => name).join(", ")}
            </div>
          </div>
        )}
        {/* {showOrderInfo && plugins?.timeInput?.active && (
          <TimeInput
            interval={plugins.timeInput?.interval}
            name={plugins.timeInput?.fieldName}
            description={
              product.timeInput?.fieldDescription ??
              plugins.timeInput?.fieldDescription
            }
            endAt={product.timeInput?.endAt ?? plugins.timeInput?.endAt}
            startAt={product.timeInput?.startAt ?? plugins.timeInput?.startAt}
            timeInput={timeInput}
            setTimeInput={(newValue) => updateValues({ timeInput: newValue })}
            orderInfo={orderInfo}
          />
        )} */}
        {/* {showOrderInfo && plugins?.invoiceReference?.active && (
          <InvoiceReference
            reference={reference}
            setReference={(newReference) =>
              updateValues({ reference: newReference })
            }
            defaultReference={defaultReference}
            module={module}
          />
        )} */}

        {/* {showOrderInfo && plugins?.textInput?.active && (
          <TextInput
            orderInfo={orderInfo}
            value={textInput}
            onChange={(newTextInput) =>
              updateValues({ textInput: newTextInput })
            }
            settings={plugins?.textInput}
            schoolId={schoolId}
          />
        )} */}

        {/* {showOrderInfo && hasRequiredProduct && (
          <RequiredProduct
            settings={plugins?.requiredProduct}
            requiredProductOrder={requiredProductOrder}
            setRequiredProductOrder={(newValue) =>
              updateValues({ requiredProductOrder: newValue })
            }
            mealOptions={mealOptions}
            module={module}
            order={order}
          />
        )} */}
      </Space>
    </>
  );
};
