/* eslint-disable @typescript-eslint/ban-ts-comment */
import { callFunction } from "@kanpla/system";
import { message } from "antd";
import Script from "next/script";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocalstorageState, useWindowSize } from "rooks";
import { useContainer } from "unstated-next";
import {
  reepayCardAdd,
  reepayCustomerCreateIfNeeded,
} from "../../../lib/firebase.functions";
import { AppContext } from "../../contextProvider";

interface Props {
  children: any;
  loading?: boolean;
  setLoading?: Dispatch<SetStateAction<boolean>>;
  onSuccess?: (p?: any) => void | Promise<void>;
}

const CardAddButtonWrapper = (props: Props) => {
  const {
    children,
    loading = false,
    setLoading = () => null,
    onSuccess,
  } = props;
  const { t } = useTranslation(["modals", "translation"]);

  const [reepayWindowToken, setReepayWindowToken] = useLocalstorageState(
    "reepay-window-token-loaded",
    false
  );

  const { loadCards, userId, partnerId } = useContainer(AppContext);
  const [windowLoaded, setWindowLoaded] = useState(reepayWindowToken);
  const { innerWidth } = useWindowSize();
  const [shouldOpen, setShouldOpen] = useState(false);

  // Load default card (using PensoPay subscription ID)
  // manage the old card system also
  useEffect(() => {
    if (windowLoaded) return;
    loadReepayWindow();

    return () => {
      setReepayWindowToken(false);
      setLoading(false);
    };
  }, [windowLoaded]);

  const loadReepayWindow = async () => {
    try {
      // Make sure that ReePay customer is made for the user
      await reepayCustomerCreateIfNeeded({ userId });

      const publicKey = await callFunction("integrations-getPublicToken", {
        partnerId,
      });

      // @ts-ignore
      window.reepayAddCardHandler = reepaytoken.configure({
        key: publicKey,
        language: "da",
        buttonText: t("modals:add-the-card"),
        token: ({ token }) => {
          submitCardToReepay(token);
        },
        close: () => setLoading(false),
      });

      setWindowLoaded(true);
      setReepayWindowToken(true);

      try {
        document.querySelectorAll("#reepay-token-iframe").forEach((e) => {
          (e as HTMLElement).style.visibility = "hidden";

          if (innerWidth < 421) {
            (e as HTMLElement).style.paddingTop =
              "calc(var(--status-bar-height) + 0.75rem)";
            (e as HTMLElement).style.backgroundColor = "white";
          }
        });
      } catch (err) {
        console.log(err);
      }
    } catch (e) {
      message.error(e?.message);
      console.error(e);
    }
  };

  const submitCardToReepay = async (token: string) => {
    try {
      await reepayCardAdd({
        cardToken: token,
      });

      await loadCards();

      message.success(t("modals:message.success.card-added"));
      onSuccess && onSuccess();
    } catch (err) {
      message.error(t("translation:message.error.payment-failed"));
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const openWindow = () => {
    setShouldOpen(false);
    document.getElementById("reepay-token-iframe").style.visibility = "visible";
    // @ts-ignore
    window.reepayAddCardHandler.open();
  };

  useEffect(() => {
    if (shouldOpen && windowLoaded) {
      openWindow();
    }
  }, [shouldOpen, windowLoaded]);

  return (
    <>
      {React.cloneElement(children, {
        disabled: loading,
        onClick: () => {
          console.log("clicked");
          setLoading(true);
          if (!windowLoaded) {
            setShouldOpen(true);
            return;
          }
          openWindow();
        },
      })}
      <Script
        id="reepay-token-load"
        src="https://token.reepay.com/token.js"
        onLoad={() => loadReepayWindow()}
      />
    </>
  );
};

export default CardAddButtonWrapper;
