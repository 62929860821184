import { getOrderConfigs } from "@kanpla/system";
import { OrderOrder } from "@kanpla/types";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSessionStorage } from "react-use";

const useKanplaGoBasket = ({ auth }) => {
  const STRING_BASKET = "basket";
  const STRING_BACKUP_BASKET = "basket";
  const typeofWindow = typeof window !== "undefined";
  const [backupBasket, setBackupBasket] = useState({});
  const [open, setOpen] = useSessionStorage<boolean>("open_basket", false);
  const [basket, setBasket] = useSessionStorage<OrderOrder>(
    STRING_BASKET,
    backupBasket
  );

  useEffect(() => {
    const itemBasket = window.sessionStorage.getItem(STRING_BASKET);
    if (itemBasket && !isEmpty(JSON.parse(itemBasket || {}))) {
      window.sessionStorage.setItem(STRING_BACKUP_BASKET, itemBasket);
      setBackupBasket(JSON.parse(itemBasket));
    }
  }, [typeofWindow && basket]);

  useEffect(() => {
    const itemBackupBasket =
      window.sessionStorage.getItem(STRING_BACKUP_BASKET);
    if (typeofWindow) {
      if (!isEmpty(JSON.parse(itemBackupBasket)))
        setBasket(JSON.parse(itemBackupBasket));
    }
  }, [auth.user]);

  /** Adds configuration uid to let differentiate orders items of same type */
  const setBasketWrapper = useCallback((newBasket: OrderOrder) => {
    Object.entries(newBasket || {}).forEach(([productId, item]) =>
      item.config?.forEach((config) => {
        config.uid =
          config.uid || `${productId}-${Math.random().toString(16).slice(2)}`;
      })
    );
    setBasket(newBasket);
    setBackupBasket(newBasket);
  }, []);

  const configs = getOrderConfigs(basket);

  useEffect(() => {
    if (configs.length <= 0) setOpen(false);
  }, [configs.length]);

  return {
    setOpenBasket: setOpen,
    openBasket: open,
    basket,
    setBasket: setBasketWrapper,
  };
};

export default useKanplaGoBasket;
