import { faArrowLeft, faFilter } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  checkWithADoubleClick,
  createScopeFromKeys,
  getAllTheTopLevelsKeysByChildKey,
  getIds,
  getKeys,
  getKeysUnderScope,
  getParentProductBanks,
  getScopeSelectionString,
  orderProductBanks,
  purifyScopeKeys,
  uniqueArray,
} from "@kanpla/system";
import {
  ProductBank,
  School,
  Scope,
  Tree as TreeType,
  TreeData,
} from "@kanpla/types";
import { Alert, Button, Modal, Tree } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { ScopeInputTreeNode } from "./ScopeInputTreeNode";

export type ScopeInputProps = {
  value?: Scope;
  onChange?: (newScope: Scope) => void;
  /** school data, required to load selector options */
  schools?: School[];
  /** Display selector options next to the school, if available */
  withSelectors?: boolean;
  /** Show alert when the input is empty */
  highlightWhenEmpty?: boolean;
  /** Disable choice */
  disabled?: boolean;
  /** Limit the selection only to certain schools */
  allowedIds?: Array<School["id"] | ProductBank["id"]>;
  /** Data about the structure of the tree */
  tree: TreeType;
};

/**
 * Values utils to prevent the trigger of the single click
 * when a double click is triggered.
 */
let timer = null;
let prevent = false;
const delay = 200;

let hasBeenCalled = false;

export const ScopeInput = ({
  value,
  onChange,
  schools,
  withSelectors = false,
  highlightWhenEmpty = false,
  disabled = false,
  allowedIds = null,
  tree,
}: ScopeInputProps) => {
  const [open, setOpen] = useState<boolean>(false);

  /** Get checked keys of the tree */
  const checked = [
    ...Object.keys(value?.schools || {}),
    ...Object.keys(value?.productBanks || {}),
  ];
  const halfChecked = getKeysUnderScope(checked, tree);

  /** expandedKeys */
  const [expandedKeys, setExpandedKeys] = useState([]);

  useEffect(() => {
    const checkedWithParents = [
      ...checked,
      ...getParentProductBanks({ generatedProductBankIds: checked, tree }),
    ];
    const checkedKeys = getKeys(checkedWithParents, treeData, []);
    setExpandedKeys((prev) => {
      const parentsKeys = getAllTheTopLevelsKeysByChildKey(
        treeData,
        checkedKeys,
        "array"
      );
      return uniqueArray([...prev, ...parentsKeys]);
    });
  }, [checked.join()]);

  /** Construct TreeData (filter by allowedIds) */
  const treeData = useMemo(() => {
    const filterNodes = (
      nodes: TreeType["schools"] | TreeType["productBanks"]
    ) => {
      if (!allowedIds) return nodes;

      const allowedIdsWithParents = [
        ...allowedIds,
        ...getParentProductBanks({
          generatedSchoolIds: allowedIds,
          generatedProductBankIds: allowedIds,
          tree,
        }),
      ];

      return Object.fromEntries(
        Object.entries(nodes || {}).filter(([id, v]) =>
          allowedIdsWithParents.includes(id)
        )
      );
    };
    const newTree: TreeType = {
      ...tree,
      schools: filterNodes(tree.schools),
      productBanks: filterNodes(tree.productBanks),
    };

    return orderProductBanks(newTree);
  }, [JSON.stringify(tree), JSON.stringify(allowedIds)]);

  const checkedKeys = getKeys(checked, treeData, []);
  const halfCheckedKeys = getKeys(halfChecked, treeData, []);

  const treeDataTrigger = JSON.stringify(treeData);
  const checkedKeysTrigger = JSON.stringify(checkedKeys);

  /**
   * Init all the expanded keys (parents and children).
   */
  useEffect(() => {
    if (treeData && checkedKeys && checkedKeys.length && !hasBeenCalled) {
      hasBeenCalled = true;
      const initialExpandedKeys = getAllTheTopLevelsKeysByChildKey(
        treeData,
        checkedKeys,
        "array"
      );
      const uniqueInitialExpandedKeys = uniqueArray([...initialExpandedKeys]);
      setExpandedKeys(uniqueInitialExpandedKeys);
    }
  }, [treeDataTrigger, checkedKeysTrigger]);

  const onCheck = (newValue: { checked: string[]; halfChecked: string[] }) => {
    const newChecked = getIds(treeData, newValue.checked, []);
    const newCheckedPure = purifyScopeKeys(newChecked, tree);
    const newScope = createScopeFromKeys(newCheckedPure, tree);
    onChange(newScope);
  };

  const singleClick = (node: TreeData) => {
    timer = setTimeout(() => {
      if (!prevent) {
        expand(node);
      }
      prevent = false;
    }, delay);
  };

  const doubleClick = (node: TreeData) => {
    clearTimeout(timer);
    prevent = true;
    check(node);
  };

  const expand = (node: TreeData) => {
    setExpandedKeys((prevState) => {
      const clickedKeys = checkWithADoubleClick("expand", prevState, node);
      const hierarchyKeys = getAllTheTopLevelsKeysByChildKey(
        treeData,
        node.key,
        "string"
      );
      const newState = uniqueArray([...clickedKeys, ...hierarchyKeys]);
      return newState;
    });
  };

  /**
   * This state is util to keep the state of the checked keys
   * by a double click.
   */
  const [, setCurrentCheckedKeys] = useState<string[]>([]);

  useEffect(() => {
    setCurrentCheckedKeys(checkedKeys);
  }, [checkedKeysTrigger]);

  const check = (node: TreeData) => {
    setCurrentCheckedKeys((prevState) => {
      const newState = checkWithADoubleClick("check", prevState, node);
      const newValue = { checked: newState, halfChecked: halfCheckedKeys };
      onCheck(newValue);
      return newState;
    });
  };

  return (
    <>
      <Button
        disabled={disabled}
        onClick={() => setOpen(true)}
        data-cy="salesplaces-btn"
      >
        <FontAwesomeIcon icon={faFilter} className="mr-1" />
        {getScopeSelectionString({
          scope: value,
          tree,
          mode: "scope",
        })}
      </Button>
      {highlightWhenEmpty && isEmpty(value) && (
        <Alert
          message="Husk at vælge salgssteder!"
          type="warning"
          showIcon
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
        />
      )}
      <Modal
        className="choose-school-modal"
        visible={open}
        title="Vælg salgssteder"
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div
          className="w-full bg-background-primary text-text-secondary"
          style={{ minWidth: 240 }}
        >
          <div>
            <Tree
              className="tree-scope-input"
              checkable
              expandedKeys={expandedKeys}
              onExpand={setExpandedKeys}
              onCheck={onCheck}
              treeData={treeData || []}
              checkStrictly={true}
              checkedKeys={{
                checked: checkedKeys,
                halfChecked: halfCheckedKeys,
              }}
              titleRender={(node) => {
                return (
                  <ScopeInputTreeNode
                    node={node}
                    key={node.id}
                    schools={schools}
                    withSelectors={withSelectors}
                    singleClick={singleClick}
                    doubleClick={doubleClick}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            ></Tree>
          </div>
        </div>
      </Modal>
    </>
  );
};
