import { Button } from "antd";
import React, { ReactNode } from "react";

interface Props {
  /** Title of the screen */
  title: string;
  /** Short description of what the user will accept */
  description: string;
  backgroundImageUrl: string;
  /** A component used to preview what the user will allow for */
  previewElement: ReactNode;
  /** Called on the button click */
  onContinue: () => void;
  /** The label of the main button */
  buttonLabel?: string;
}

export const SystemScreen = (props: Props) => {
  const {
    title,
    description,
    backgroundImageUrl,
    previewElement,
    onContinue,
    buttonLabel = "Fortsæt",
  } = props;

  return (
    <div
      className="w-full h-full relative"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("${backgroundImageUrl}")`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="w-full h-full flex flex-col">
        <div className={`w-full flex justify-center md:mt-0 mt-10`}>
          {previewElement}
        </div>
        <div className="w-full h-full px-10">
          <div className="w-full h-full flex flex-col justify-between">
            <div />
            <div className="w-full flex items-center flex-col">
              <h1 className="text-2xl text-center text-white font-semibold">
                {title}
              </h1>
              <h1 className="text-white mt-4 text-lg text-center">
                {description}
              </h1>
            </div>
            <Button
              size="large"
              className="w-full md:w-auto md:px-16 mb-16"
              type="primary"
              onClick={onContinue}
            >
              {buttonLabel}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
