import React from "react";
import { getPixelValues, size } from "./IconSizes";

interface Props {
  color?: string;
  size?: size;
}

export const LunchIcon = ({ color = null, size = "regular" }: Props) => {
  const dimension = getPixelValues(size);

  return (
    <svg
      width={dimension}
      height={dimension}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.0219 32.9126C24.4312 34.5033 22.1943 35.0004 20.1563 34.3542L16.3784 38.2812C18.8141 39.673 21.6475 40.4187 24.6301 40.4187C33.9257 40.4187 41.4815 32.8629 41.4815 23.5673C41.4815 14.2717 33.9257 6.76562 24.6301 6.76562C15.3345 6.76562 7.77869 14.3214 7.77869 23.617C7.77869 26.5996 8.57403 29.433 9.91618 31.8687L13.8432 28.0908C13.2467 26.0528 13.7438 23.8158 15.2848 22.2252L22.7908 14.6694C23.4371 14.0232 24.2821 13.6752 25.2266 13.6752C26.1213 13.6752 26.9664 14.0232 27.6126 14.6694C28.1594 15.2162 28.5074 15.9121 28.5571 16.6577C29.3027 16.7572 29.9987 17.1051 30.5455 17.6022C31.0923 18.149 31.4402 18.8449 31.4899 19.5906C32.2356 19.69 32.9315 19.9882 33.4783 20.535C34.1245 21.1813 34.4725 22.0263 34.4725 22.9211C34.4725 23.8158 34.1245 24.7106 33.4783 25.3568L26.0219 32.9126Z"
        fill={color || "#4A5568"}
      />
      <path
        d="M24.63 0.354492C11.8051 0.354492 1.36615 10.7934 1.36615 23.6183C1.36615 28.3407 2.80771 32.7648 5.24346 36.4433L8.02717 33.759C6.23764 30.8262 5.19375 27.3465 5.19375 23.6183C5.19375 12.8812 13.9425 4.13238 24.6797 4.13238C35.4169 4.13238 44.1657 12.8812 44.1657 23.6183C44.1657 34.3555 35.4169 43.1043 24.6797 43.1043C20.9515 43.1043 17.5216 42.0604 14.5391 40.2709L11.8548 43.0546C15.5332 45.4903 19.9573 46.9319 24.6797 46.9319C37.5047 46.9319 47.9436 36.493 47.9436 23.6681C47.8939 10.7934 37.4549 0.354492 24.63 0.354492Z"
        fill={color || "#4A5568"}
      />
      <path
        d="M24.3813 31.2724L31.9371 23.7166C32.3348 23.319 32.3348 22.6727 31.9371 22.2254C31.5394 21.8277 30.8932 21.8277 30.4458 22.2254L24.2322 28.439C24.0333 28.6378 23.7351 28.6378 23.5363 28.439L22.7409 27.6436C22.5421 27.4448 22.5421 27.1466 22.7409 26.9477L28.9545 20.7341C29.3522 20.3364 29.3522 19.6902 28.9545 19.2428C28.5569 18.8451 27.9107 18.8451 27.4633 19.2428L21.2496 25.5062C21.0508 25.705 20.7525 25.705 20.5537 25.5062L19.8081 24.7108C19.6092 24.512 19.6092 24.2137 19.8081 24.0149L26.0217 17.8012C26.4194 17.4036 26.4194 16.7574 26.0217 16.31C25.624 15.9123 24.9778 15.9123 24.5304 16.31L16.9747 23.8658C15.6822 25.1582 15.6325 27.1466 16.7261 28.5384L1.41571 43.4511C0.471233 44.3956 0.421524 45.9366 1.366 46.8811C2.31047 47.8255 3.85145 47.8255 4.79592 46.8314L19.6589 31.4712C21.0508 32.6146 23.0889 32.5151 24.3813 31.2724Z"
        fill={color || "#4A5568"}
      />
    </svg>
  );
};
