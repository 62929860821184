import React from "react";
import { getPixelValues, size } from "./IconSizes";

interface Props {
  color?: string;
  size?: size;
}

export const SnacksIcon = ({ color = null, size = "regular" }: Props) => {
  const dimension = getPixelValues(size);

  return (
    <svg
      width={dimension}
      height={dimension}
      viewBox="0 0 48 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.482 19.8026C18.3372 19.8711 18.2001 19.932 18.063 19.993C17.7964 20.907 17.6898 21.8821 17.6745 22.758C17.6745 22.758 17.6822 22.758 17.6898 22.758C18.5582 22.758 19.3656 23.2608 19.7465 24.0378C20.2949 25.1804 19.8226 26.5439 18.6953 27.0999C16.6756 28.0937 14.3173 27.9769 12.251 27.1532L12.1749 27.1228C11.0399 26.5972 10.5371 25.2413 11.0551 24.0987C11.4208 23.2913 12.2282 22.7657 13.1041 22.758C13.1194 22.0878 13.1727 21.3412 13.3022 20.5567C12.1444 20.3357 10.8114 19.894 9.66115 19.018C9.24221 18.9875 8.79277 18.9951 8.30525 19.056C3.04934 19.7035 -0.264223 25.546 0.604169 32.6377C1.51825 40.1027 6.51521 46.0975 11.5426 45.4653C13.0128 45.2824 14.3839 44.5664 15.5569 43.3705C21.2852 49.2054 29.3138 42.3422 30.5021 32.6377C31.7437 22.6438 24.8424 16.7099 18.482 19.8026ZM8.33575 41.5271C6.3019 40.0189 4.588 37.0481 3.87197 33.7574C3.78057 33.3461 4.0472 32.9424 4.45852 32.851C4.86227 32.7596 5.27359 33.0262 5.36499 33.4375C5.98961 36.3321 7.51307 39.0286 9.24222 40.3007C10.0648 40.9101 9.14319 42.1213 8.33575 41.5271Z"
        fill={color || "#4A5568"}
      />
      <path
        d="M21.8565 14.1506C21.9326 14.5619 21.6584 14.958 21.247 15.0342C16.9503 15.8416 15.823 20.3456 16.2196 24.7082C16.5929 24.6549 16.9737 24.5482 17.3546 24.3578C17.7355 24.175 18.1925 24.3349 18.3754 24.7082C18.5582 25.089 18.3982 25.5461 18.0249 25.7289C16.3896 26.5311 14.4863 26.4045 12.8147 25.7365C12.4338 25.5613 12.2663 25.1119 12.4414 24.7234C12.6167 24.3425 13.0661 24.175 13.4469 24.3501C13.4698 24.3578 13.9649 24.5787 14.6886 24.6929C14.5362 23.0933 14.6048 21.0975 15.1532 19.2237C13.7821 19.1856 11.5883 18.858 10.1029 17.3803C7.89393 15.1637 8.2748 11.3474 8.29762 11.1875C8.33574 10.837 8.61758 10.5552 8.96795 10.5171C8.96795 10.5171 9.28791 10.479 9.79826 10.479C11.1084 10.479 13.5536 10.7151 15.1608 12.3224C16.1739 13.3431 16.6462 14.699 16.8519 15.8797C17.8117 14.7371 19.1447 13.8763 20.9652 13.5412C21.3766 13.465 21.7803 13.7316 21.8565 14.1506Z"
        fill={color || "#4A5568"}
      />
      <path
        d="M34.0978 11.9719V44.3912H47.6947V11.9719H34.0978ZM25.993 11.9719V18.4619C27.0594 19.0027 28.0421 19.7797 28.9029 20.7471C31.4699 23.6569 32.6049 28.0597 32.0107 32.8281C31.4242 37.6423 29.2989 41.8242 26.4729 44.3836H32.5668V11.9719H25.993ZM30.0378 0.416504V3.59291H43.6499V0.416504H30.0378ZM43.9775 5.11637H30.6091L33.7703 10.4485H47.1234L43.9775 5.11637ZM29.3218 5.91617L29.2761 5.84764L26.5643 10.4485H31.9955L29.3218 5.91617Z"
        fill={color || "#4A5568"}
      />
    </svg>
  );
};
