import { School } from "@kanpla/types";

type Props = {
  school: School;
};

export const selectorsHasOnlyChoice = ({ school }) => {
  const schoolSelectors = school?.selectors || ([] as School["selectors"]);
  if (schoolSelectors.length > 1) return null;

  const firstLayer = school?.selectors?.[0];
  const firstLayerOptions = firstLayer?.options || [];
  const availableOptions = firstLayerOptions.filter((o) => !o.hidden);
  const hasMoreOptions = availableOptions.length > 1;
  const hasChoice = hasMoreOptions;

  const firstLayerName = firstLayer?.name;
  const firstOptionName = availableOptions[0]?.name;
  if (!firstLayerName || !firstOptionName) return null;
  const onlyChoice = {
    [firstLayerName]: firstOptionName,
  };

  return onlyChoice;
};
