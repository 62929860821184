import {
  getActivePlugins,
  UseAuth,
  UseLocale,
  UseTimeNavigation,
  useWindowSize,
} from "@kanpla/system";
import {
  Child,
  DataAuthenticationModal,
  Supplier,
  TimeNavigation,
} from "@kanpla/types";
import moment from "moment";
import { useRouter } from "next/router";
import React, { ReactNode, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useLocalstorageState } from "rooks";
import { createContainer, useContainer } from "unstated-next";
import { usePosoneBalance } from "../lib/posone/useBalance";
import { useChild } from "../lib/useChild";
import useChildSalesplace from "../lib/useChildSalesplace";
import { useCustomerCard } from "../lib/useCustomerCard";
import useGetSupplier from "../lib/useGetSupplier";
import useKanplaGoBasket from "../lib/useKanplaGoBasket";
import { useSchool } from "../lib/useSchool";
import { useUser } from "../lib/useUser";
import NewPayment from "./modals/payment/index";
import UseIntercom from "./UseIntercom";
import UseModules from "./useModules";

const ContextState = () => {
  const { t, i18n } = useTranslation(["translation"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const router = useRouter();
  const auth = UseAuth();
  const { user: authUser, loading: userLoading } = auth;
  const [overrideUser, setOverrideUser] = useState(authUser);

  const [customBranding, setCustomBranding] = useLocalstorageState<Supplier>(
    "kanpla-custom-branding"
  );
  const [customBrandingLoading, setCustomBrandingLoading] = useState(true);

  useEffect(() => {
    setOverrideUser(authUser);
  }, [authUser?.uid]);

  const userId = overrideUser?.uid || (overrideUser as any)?.id || null;

  // MODULE WRAPPER
  const [isBulk, setIsBulk] = useState<boolean | null>(null);

  const [timeNavigation, setTimeNavigation] =
    useState<TimeNavigation>("todaySwitch");

  // UI
  const [menuOpen, setMenuOpen] = useState(false);
  const [appLoading, setAppLoading] = useState(true);
  // Used for fetching data, e.g. mealplan
  const [innerAppLoading, setInnerAppLoading] = useState(false);
  const [moduleLoading, setModuleLoading] = useState(false);
  const [navbarHasContent, setNavbarHasContent] = useState(false);

  // Popup interaction
  const [showPopupInteractionAnimation, setShowPopupInteractionAnimation] =
    useState(false);

  // Time
  const { week, setWeek, dayIndex, setDayIndex, date, setDate, dateSeconds } =
    UseTimeNavigation();

  const [__kanplaGoOrderFromWindow, __setKanplaGoOrderFromWindow] =
    useState(false);

  const calendarFormatter = {
    sameDay: `[${t("translation:today").toLowerCase()}]`,
    nextDay: `[${t("translation:tomorrow")}]`,
    nextWeek: "dddd",
    lastDay: `[${t("translation:yesterday")}]`,
    lastWeek: `[${t("translation:last")}] dddd`,
    sameElse: "dddd, D/M",
  };

  const displayDate = moment
    .unix(week[dayIndex]?.seconds || 0)
    .calendar(null, calendarFormatter);

  const [historyOpen, setHistoryOpen] = useState(false);

  // School
  const { school, schoolId, setSchoolId, partnerId } = useSchool();

  // Child
  const { children, child, childId, setChild, setChildId, childLoading } =
    useChild(userId);

  /**
   * It's a condition needed at the very first login from the landing page.
   * If the school id inside the child it's equal to the current school id,
   * we will return the current school id inside the child (most needed in the anonymous users flow),
   * otherwise we will return the current school id if it's truthy or the current child
   * school id.
   * Try to remove the last OR before the OR that return the value null (|| child?.schoolId),
   * and you will see something funny.
   */
  const childSchoolId =
    (child?.schoolId === schoolId
      ? child?.schoolId
      : schoolId || child?.schoolId) || null;

  useEffect(() => {
    if (schoolId) return;

    if (childSchoolId) setSchoolId(childSchoolId);
  }, [childSchoolId, schoolId]);

  const defaultReference = (child as Child)?.defaultReference;

  // Modules (Kanpla 3.0)
  const {
    allModules = [],
    modules = [],
    moduleId,
    setModuleId,
    module,
  } = UseModules({
    schoolId,
    child,
    school,
  });

  // Module props
  const hasKanplaGo = module?.plugins?.kanplaGo?.active;
  const hasPayPerOrder = module?.plugins?.payPerOrder?.active;
  const withCredit =
    module &&
    module.type === "mealplan" &&
    (module.paymentMethod === "credit" || module.paymentMethod === "posOne") &&
    !hasKanplaGo &&
    !hasPayPerOrder;

  // Ordering
  const [isSaving, setIsSaving] = useState(0);

  const { user, balance, setBalance } = useUser({ isSaving, userId });

  const {
    isPosone,
    balance: posoneBalance,
    getBalance: getPosoneBalance,
    balanceLoading: posoneBalanceLoading,
    posonePartnerData,
  } = usePosoneBalance({ partnerId, userId, auth });

  const [deficit, setDeficit] = useState(null);
  const [pleasePayOpen, setPleasePayOpen] = useState(false);
  const { card, cards, setCard, loadCards } = useCustomerCard({ userId });

  useEffect(() => {
    // if app is already loaded
    if (!appLoading) return;

    if (userLoading) return;
    if (childLoading) return;
    if (withCredit && balance === (null || undefined)) return;
    if (modules === (null || undefined)) return;
    if (modules.length && !moduleId) return;
    if (!date) return;

    // if the school has no modules, interrupt loading
    if (!modules.length && !moduleId) {
      setAppLoading(false);
      return;
    }
    setAppLoading(false);
  }, [
    balance,
    modules,
    date,
    moduleId,
    withCredit,
    appLoading,
    userLoading,
    childLoading,
  ]);

  useEffect(() => {
    setModuleLoading(true);
  }, [moduleId]);

  useEffect(() => {
    if (innerAppLoading || appLoading) return;
    setModuleLoading(false);
  }, [innerAppLoading, appLoading]);

  // Animation: pulse
  const [pulseCounter, setPulseCounter] = useState(0);

  const { width: screenWidth } = useWindowSize();
  const mobile = isMobile || screenWidth < 768;

  // Kanpla Go basket
  const { openBasket, setOpenBasket, basket, setBasket } = useKanplaGoBasket({
    auth,
  });

  const [paymentOpen, setPaymentOpen] = useState(false);

  const { allLanguages, localeFrom, setLocaleFrom } = UseLocale({
    school,
    user,
    customBranding,
  });
  const activePlugins = getActivePlugins({ module });

  // Supplier info
  const { supplier } = useGetSupplier({ customBranding });
  const isChildSalesplace = supplier?.childIsSalesplace || false;

  // Available salesplaces if the supplier has `isChildSalesplace` property
  const { childSalesplaceSchools } = useChildSalesplace({
    userId,
    isChildSalesplace,
    children,
  });

  // Authentication anonymous user
  const [openAuthenticationModal, setOpenAuthenticationModal] =
    useState<boolean>(false);

  const [dataAuthenticationModal, setDataAuthenticationModal] =
    useState<DataAuthenticationModal>({ action: "login" });
  const [fromAPrivateModule, setFromAPrivateModule] = useState<boolean>(false);

  return {
    // DATA: User
    auth,
    userId,
    user,
    setOverrideUser, // super-admin!

    // DATA: Money
    deficit,
    setDeficit,
    balance,
    setBalance,

    // DATA: Payment cards
    card,
    cards,
    setCard,
    loadCards,

    // DATA: Children + child
    children,
    child,
    childId,
    setChild,
    setChildId,
    defaultReference,

    // DATA: School
    school,
    schoolId,
    setSchoolId,
    partnerId,
    supplier,
    isChildSalesplace,

    // Available schools if `isChildSalesplace`
    childSalesplaceSchools,

    // DATA: Modules
    allModules,
    modules,
    module,
    moduleId,
    setModuleId,
    withCredit,
    isBulk,
    setIsBulk,

    // DATA: Custom branding
    customBranding,
    setCustomBranding,
    customBrandingLoading,
    setCustomBrandingLoading,

    // UI: Time navigation
    week,
    setWeek,
    /* Selected day index */
    dayIndex,
    setDayIndex,
    date,
    dateSeconds,
    setDate,
    displayDate,
    timeNavigation,
    setTimeNavigation,

    isSaving,
    setIsSaving,

    appLoading,
    innerAppLoading,
    setInnerAppLoading,
    moduleLoading,

    // UI: Animation
    pulseCounter,
    setPulseCounter,

    // DATA: PosOne
    isPosone,
    posonePartnerData,
    posoneBalance,
    getPosoneBalance,
    posoneBalanceLoading,

    // DATA: Kanpla Go
    basket,
    setBasket,
    openBasket,
    setOpenBasket,

    // DATA: i18n
    allLanguages,
    localeFrom,
    setLocaleFrom,

    // UI: Navigation
    menuOpen,
    setMenuOpen,
    navbarHasContent,
    setNavbarHasContent,

    // UI: Payment modals
    paymentOpen,
    setPaymentOpen,
    pleasePayOpen,
    setPleasePayOpen,

    // UI: Device detect
    mobile,

    // Popup interaction
    showPopupInteractionAnimation,
    setShowPopupInteractionAnimation,

    // Authentication modal
    openAuthenticationModal,
    setOpenAuthenticationModal,
    dataAuthenticationModal,
    setDataAuthenticationModal,
    fromAPrivateModule,
    setFromAPrivateModule,

    __kanplaGoOrderFromWindow,
    __setKanplaGoOrderFromWindow,
    activePlugins,

    historyOpen,
    setHistoryOpen,
  };
};

export const AppContext = createContainer(ContextState);

interface Props {
  children: ReactNode;
}

const ContextProvider = (props: Props) => {
  return (
    <AppContext.Provider>
      {props.children}
      <Extras />
    </AppContext.Provider>
  );
};

const Extras = () => {
  const { paymentOpen, setPaymentOpen } = useContainer(AppContext);

  return (
    <>
      <UseIntercom />
      <NewPayment open={paymentOpen} setOpen={setPaymentOpen} />
    </>
  );
};

export default ContextProvider;
