import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import React from "react";

interface Props {
  title: string;
  subtitle?: string;
  className?: string;
  /** Provide Font Awesome icon */
  icon?: IconProp;
  /** Text as a help "info" icon */
  help?: string;
  noBg?: boolean;
}

export const ProductSettingsHeader = ({
  title,
  subtitle,
  className = "",
  icon,
  help,
  noBg = false,
}: Props) => (
  <header
    className={`p-4 px-8 mb-0 pb-0 -mx-8 flex relative items-center justify-between ${className}`}
  >
    <div
      className={`absolute opacity-50 z-0 inset-0 ${
        noBg
          ? "bg-transparent"
          : "border-t border-divider-main bg-gradient-to-b from-background-secondary to-background-primary"
      }`}
    />

    <div className="relative">
      <h4 className="text-text-primary font-semibold flex items-center">
        {title}
        {help && (
          <Tooltip title={help} className="ml-1.5 ">
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="text-text-disabled"
              size="xs"
            />
          </Tooltip>
        )}
      </h4>
      {subtitle && <p className="text-xs text-text-secondary">{subtitle}</p>}
    </div>
    {icon && (
      <FontAwesomeIcon icon={icon} className="text-text-secondary text-2xl" />
    )}
  </header>
);
