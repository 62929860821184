import {
  DocumentReference,
  DocumentData,
  Query,
  FirestoreError,
} from "@firebase/firestore-types";
import { FirestoreDocument } from "@kanpla/types";
import { useCollectionData } from "react-firebase-hooks/firestore";

type Ref =
  | DocumentReference
  | Query<DocumentData>
  | Query<DocumentReference<DocumentData>>
  | null;

type Return<T> = [data: Array<T>, loading: boolean, error: FirestoreError];

interface Options {
  includeDeleted?: boolean;
}

export const useCollectionListener = <T extends FirestoreDocument>(
  ref: Ref,
  options?: Options,
  includeRef = true
): Return<T> => {
  // const postConverter: FirestoreDataConverter<T> = {
  //   toFirestore(document: WithFieldValue<T>): DocumentData {
  //     return { ...document };
  //   },
  //   fromFirestore(
  //     snapshot: QueryDocumentSnapshot,
  //     options: SnapshotOptions
  //   ): T {
  //     const data = snapshot.data(options) as T;
  //     const fullData = {
  //       ...data,
  //       id: snapshot?.id,
  //       ref: snapshot?.ref,
  //     };
  //     return fullData;
  //   },
  // };

  // const ref = collection
  //   ? query(
  //       collectionProvider(getFirestore(), collection),
  //       ...queryConstraints
  //     ).withConverter(postConverter)
  //   : null;

  // const [data = [], loading, error] = useCollectionData(ref);

  // const ref =
  //   typeof collection === `string` && typeof id === `string`
  //     ? db.collection(collection).doc(id)
  //     : null;

  const collectionOptions = {
    idField: "id",
    refField: includeRef ? "ref" : null,
  };

  //@ts-ignore
  const [allData = [], loading, error] = useCollectionData<T>(
    ref,
    collectionOptions
  );

  const data = options?.includeDeleted
    ? allData
    : allData.filter((d) => !d.deleted);

  return [data, loading, error];
};
