export const TriggerPagePrint = () => {
  // Print in app
  try {
    // @ts-ignore
    window.webkit?.messageHandlers?.iosListener?.postMessage("print");
  } catch (err) {}

  // Do Firefox-related activities
  if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
    window.print();
    return;
  }

  // Print in browser
  try {
    // Print for Safari browser
    document.execCommand("print", false, null);
  } catch {
    window.print();
  }
};
