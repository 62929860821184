import { getWeekSeconds } from "./getWeekSeconds";

export const getPrevWorkingDay = (seconds: number) => {
  const newSeconds = seconds - 86400; // jump to yesterday

  const mondaySeconds = getWeekSeconds(seconds);
  const secondsFromMonday = newSeconds - mondaySeconds;

  // Jump to prev week if its before Monday
  if (secondsFromMonday >= 0) return newSeconds;

  return mondaySeconds - 3 * 86400;
};
