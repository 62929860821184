import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormWrapperData, SettingsWrapper } from "@kanpla/ui";
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Modal,
  Space,
  Table,
  TableColumnsType,
  TableProps,
} from "antd";
import { useRouter } from "next/router";
import { default as React, Dispatch, SetStateAction, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { SettingsDetail, SettingsDetailProps } from "./Detail";

interface NewItemProps {
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}

export interface Action {
  title: string;
  Children?: (props: any) => JSX.Element;
  onClick?: () => void;
}

interface Props {
  /** Toggles Detailed view */
  itemId?: string | null;
  columns: TableColumnsType;
  title: string;
  // React Element
  CustomHeader?: (props: NewItemProps) => JSX.Element;
  tableProps?: Partial<TableProps<any>>;
  dataSource: any;
  /** Submitting item form */
  detailProps: Omit<
    SettingsDetailProps<unknown>,
    "backButtonLabel" | "isNew" | "backButtonOnClick"
  >;
  url: string;
  defaultData?: any;
  rowKey?: string;
  actions?: Array<Action>;
}

export const SettingsList = <Data extends FormWrapperData>(props: Props) => {
  const {
    columns,
    title,
    tableProps = {},
    CustomHeader,
    dataSource,
    itemId,
    detailProps,
    url,
    defaultData,
    rowKey = "id",
    actions,
  } = props;
  const router = useRouter();
  const [searchQuery, setSearchQuery] = useState("");

  const onRow = (record) => {
    return {
      onClick: () => {
        router.push(`${url}/${record.id}`);
      },
    };
  };

  const findData = (data) => {
    return data?.find((d) => d.id === itemId);
  };

  const pattern = new RegExp(searchQuery, "i");
  const filterData = () => {
    const dataSourceWithKeys = dataSource.map((d, index) => {
      d.key = `data-${index}`;
      return d;
    });
    return searchQuery
      ? dataSourceWithKeys.filter((d) => {
          return pattern.test(d.name) || pattern.test(d.email);
        })
      : dataSourceWithKeys;
  };

  const filteredData = filterData();

  const Content = () => {
    return (
      <>
        <MobileView>
          <Table
            dataSource={filteredData}
            columns={columns}
            pagination={false}
            scroll={{ x: 1000 }}
            onRow={onRow}
            size="small"
            rowKey={rowKey}
            rowClassName="cursor-pointer group"
            {...tableProps}
          />
        </MobileView>
        <BrowserView>
          <Table
            dataSource={filteredData}
            pagination={false}
            rowKey={rowKey}
            columns={columns}
            onRow={onRow}
            scroll={{ x: 800 }}
            rowClassName="cursor-pointer group"
            {...tableProps}
          />
        </BrowserView>
      </>
    );
  };

  const isDetailedView = typeof itemId === `string`;
  if (isDetailedView)
    return (
      <SettingsDetail<Data>
        backButton={{
          label: title,
          onClick: () => router.push(url),
        }}
        {...detailProps}
        key={itemId}
        isNew={itemId === "_new"}
        copyText={itemId}
        defaultData={defaultData || findData(dataSource)}
      />
    );

  return (
    <SettingsWrapper
      title={title}
      Content={Content}
      Header={
        CustomHeader ? (
          <CustomHeader />
        ) : (
          <Header
            url={url}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            actions={actions}
          />
        )
      }
    />
  );
};

interface HeaderProps {
  searchQuery?: string;
  setSearchQuery?: Dispatch<SetStateAction<string>>;

  actions?: Array<Action>;
  url: string;
}

const Header = ({
  searchQuery,
  setSearchQuery,

  actions = [],
  url,
}: HeaderProps) => {
  const [activeAction, setActiveAction] = useState(null);
  const router = useRouter();

  const moreActions = actions.length > 1;

  const openNew = () => {
    router.push(`${url}/_new`);
  };

  const firstAction = actions?.[0];
  const otherActions = actions?.slice(1) || [];

  const overlay = (
    <Menu>
      {otherActions.map((action, key) => {
        const defaultChildren = () => null;
        const Children = action.Children || defaultChildren;

        const defaultOnClick = () => setActiveAction(key);
        const onClick = action.onClick || defaultOnClick;

        return (
          <div key={key}>
            <Menu.Item onClick={onClick}>{action.title}</Menu.Item>
            <Modal
              title={action.title}
              visible={activeAction === key}
              onCancel={() => setActiveAction(null)}
              footer={null}
            >
              <Children
                onClose={() => setActiveAction(null)}
                visible={activeAction === key}
              />
            </Modal>
          </div>
        );
      })}
    </Menu>
  );

  const defaultFirstActionOnClick = () => openNew();
  const firstActionOnClick = firstAction?.onClick || defaultFirstActionOnClick;

  const defaultFirstActionTitle = "Tilføj";
  const firstActionTitle = firstAction?.title || defaultFirstActionTitle;

  return (
    <Space>
      <Input.Search
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Søg"
      />
      {!moreActions ? (
        <Button type="primary" onClick={firstActionOnClick} data-cy="add-btn">
          <FontAwesomeIcon icon={faPlus} className="mr-2" /> {firstActionTitle}
        </Button>
      ) : (
        <Dropdown.Button
          type="primary"
          overlay={overlay}
          trigger={["click"]}
          data-cy="add-btn"
          icon={<FontAwesomeIcon icon={faChevronDown} className="text-sm" />}
        >
          <span onClick={firstActionOnClick}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" />{" "}
            {firstActionTitle}
          </span>
        </Dropdown.Button>
      )}
    </Space>
  );
};
