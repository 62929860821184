import { Dispatch, SetStateAction } from "react";

/**
 *
 * @param cost The cost of the meal with variants
 * @param balance The balance for the user
 * @param setDeficit Returns the difference
 * @param openModal Function that opens up a modal if there is not enough credit
 * @returns
 */
export const checkIfChildHasCreditEnough = (
  cost: number,
  balance: number,
  setDeficit?: Dispatch<SetStateAction<number>>,
  openModal?: Dispatch<SetStateAction<boolean>>
) => {
  if (cost <= balance) return;
  // Reject if not enough credit
  setDeficit(cost - balance);
  openModal(true);
  throw new Error("Ikke nok kredit");
};
