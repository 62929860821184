import { TooltipDisabledButton } from "@kanpla/types";
import { Button, ButtonProps, Tooltip } from "antd";
import React, { useState } from "react";

export interface ActionButtonProps extends ButtonProps, TooltipDisabledButton {
  className: string;
  label: string;
  onClick: () => Promise<void>;
  key?: string;
  dataCy?: string;
}

export const ActionButton = (props: ActionButtonProps) => {
  const {
    onClick,
    label,
    className,
    type = "primary",
    dataCy = "",
    tooltip,
  } = props;
  const [loading, setLoading] = useState(false);

  const _onClick = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  if (tooltip?.hasTooltip) {
    return (
      <Tooltip title={tooltip?.title}>
        <Button
          type={type}
          size="large"
          className={`w-full flex-1 ${className || ""}`}
          style={tooltip?.style}
          {...props}
        >
          {label}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button
      type={type}
      size="large"
      onClick={_onClick}
      loading={loading}
      className={`w-full flex-1 ${className}`}
      data-cy={dataCy}
    >
      {label}
    </Button>
  );
};
