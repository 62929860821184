import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { getTabIcons, tabIcons, Lock } from "@kanpla/ui";
import { Dropdown, Menu } from "antd";
import { isEmpty } from "lodash";
import React, { ReactNode, useState } from "react";

export interface MultiDropdownItem {
  key: string;
  label: string;
  /** The reference used to retrieve specifc data, e.g. a `Child` or a `Module` */
  ref: any;
  extraLabel?: string;
  ariaLabel?: string;
  icon?: ReactNode | FontAwesomeIconProps | string;
}

export interface MultiDropdownData {
  items: MultiDropdownItem[];
  config: {
    /** Action to execute on item click, receiving the item ref and the item unique key */
    onClick: (ref: any, key?: string) => void;
    /** Callback function to execute after the item is clicked */
    callback?: () => void;
    /** Callback condition to make an item active, receiving the item ref and the item unique key */
    activeFilter: (ref: any, key?: string) => boolean;
  };
}

interface Props {
  defaultKey: string;
  /** The content of the dropdown  */
  data: MultiDropdownData;
  /** Don't render the component if true */
  hidden?: boolean;
  type?: "dropdown" | "tabs";
  color?: "primary" | "secondary";
  /** Used to apply a tilted border, defaults to null */
  tilted?: "left" | "right" | "both";
  className?: string;
}

export const MultiDropdown = (props: Props) => {
  const {
    defaultKey,
    data,
    hidden = false,
    type = "dropdown",
    color = "primary",
    tilted = null,
    className = "",
  } = props;

  const [visible, setVisible] = useState(false);

  if (hidden) return null;

  const defaultColors =
    color === "primary"
      ? "bg-background-secondary text-primary-dark hover:bg-primary-light"
      : "bg-main-200 text-main-900 hover:bg-main-300";

  const defaultSingleColors =
    color === "primary"
      ? "text-primary-main hover:bg-background-secondary"
      : "text-main-900 hover:bg-main-200";

  const defaultTabColors =
    color === "primary"
      ? "text-primary-main hover:bg-background-secondary"
      : "text-main-900 hover:bg-main-200";

  const getTiltedBorder = () => {
    switch (tilted) {
      case "left":
        return "angled-border-left";
      case "right":
        return "angled-border-right";
      case "both":
        return "angled-border-right angled-border-left";
      default:
        return "";
    }
  };

  const defaultData =
    data.items.find((item) => item.key === defaultKey) ||
    ({} as MultiDropdownItem);

  if (isEmpty(data)) return null;

  if (type === "tabs")
    return (
      <div className="flex flex-nowrap">
        {data.items.map((item) => {
          const { label, icon, ariaLabel = null, key, ref } = item;
          const active = data?.config?.activeFilter(ref, key);
          return (
            <button
              className={`w-auto whitespace-nowrap px-4 py-2 ${
                active ? defaultColors : defaultTabColors
              } font-semibold flex justify-center items-center select-none relative cursor-pointer ${
                !active ? "hover:opacity-70" : ""
              } ${className}`}
              style={{ borderRadius: "10px" }}
              key={key}
              aria-label={ariaLabel || ""}
              onClick={() => {
                if (!active) {
                  data?.config?.onClick(ref, key);

                  if (data?.config?.callback) data.config.callback();
                }
              }}
            >
              {icon && (
                <div className="mr-4">
                  {tabIcons.find(
                    (moduleIcon) =>
                      moduleIcon.key === (icon as unknown as string)
                  )?.icon || null}
                </div>
              )}
              {label}
            </button>
          );
        })}
      </div>
    );

  const overlay = (
    <Menu style={{ maxHeight: "80vh" }} className="overflow-scroll">
      {data.items.map((item, index) => {
        const {
          label,
          icon,
          extraLabel = null,
          ariaLabel = null,
          key,
          ref,
        } = item;
        const active = data?.config?.activeFilter(ref, key);
        return (
          <Menu.Item
            key={`${key}-${item.label}-${index}`}
            aria-label={ariaLabel || ""}
            className={`px-6 w-full py-3 border-b text-left items-center justify-between hover:bg-gray-100 ${
              active ? "bg-gray-200" : " border-gray-200"
            }`}
            onClick={() => {
              if (!active) {
                data?.config?.onClick(ref, key);

                if (data?.config?.callback) data.config.callback();
                setVisible(false);
              }
              return;
            }}
          >
            <div className={`flex items-center ${!extraLabel ? "py-1" : ""}`}>
              {(icon &&
                tabIcons.find(
                  (moduleIcon) => moduleIcon.key === (icon as unknown as string)
                )?.icon) ||
                null}
              <p className={`${icon ? "ml-4" : ""} whitespace-nowrap`}>
                {label}
              </p>
            </div>

            {extraLabel && (
              <p className="text-xs font-light whitespace-nowrap">
                {extraLabel}
              </p>
            )}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown
      overlay={overlay}
      trigger={["click"]}
      onVisibleChange={(v) => setVisible(v)}
    >
      <div
        className={`w-auto whitespace-nowrap px-4 min-w-fit ${
          tilted ? defaultColors : defaultSingleColors
        } transition-colors text-base font-semibold ${getTiltedBorder()} z-10 flex justify-center items-center select-none relative cursor-pointer h-full ${className}`}
        style={{
          borderRadius: "8px",
          height: 40,
          marginRight: tilted ? "24px" : "10px",
          fontSize: 18,
        }}
      >
        {visible && <Lock />}

        {defaultData?.icon && (
          <span className="mr-2 -ml-1">
            {getTabIcons("small", "currentColor").find(
              (icon) => icon.key === (defaultData.icon as unknown as string)
            )?.icon || null}
          </span>
        )}
        <span
          data-cy="child-switch-btn"
          className={`overflow-hidden overflow-ellipsis ${
            defaultData?.icon ? `hidden lg:inline` : ""
          }`}
          style={{ maxWidth: 160, minWidth: 80 }}
        >
          {defaultData.label}
        </span>

        {/* {defaultData.label} */}
        <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
      </div>
    </Dropdown>
  );
};
