import { db, fetchCollection, KanplaError } from "@kanpla/system";
import { Child } from "@kanpla/types";

/**
 * Function to get all children of the schools, they are not grouped by schoolId
 * @param schoolIds Array (or single) school ID(s)
 * @param customDb You can pass in a custom db (in case of GCP)
 * @returns Promise with an array of children
 */
export const getChildrenFromSchools = async (
  schoolIds: Array<string> | string,
  customDb?: any
) => {
  const schools = typeof schoolIds === "string" ? [schoolIds] : schoolIds;

  try {
    const childrenResults = schools.map(async (schoolId) => {
      const childrenQuery = (customDb || db)
        .collection("children")
        .where("schoolId", "==", schoolId);

      const childrenData = await fetchCollection<Child>(childrenQuery, true);

      return childrenData;
    });

    const childrenDocs = await Promise.all(childrenResults);

    return childrenDocs.flat();
  } catch (e) {
    console.error("🚀 ~ file: getChildrenFromSchools.ts ~ line 25 ~ e", e);
    throw new Error(e);
  }
};
