import danishErrors from "./da/errors";
import { getReepayErrorText as getReepayErrorTextFn } from "./getReepayErrorText";

export class KanplaError extends Error {
  code: string;

  constructor(code: string, message: string) {
    const errorMessage = code
      ? `${message.startsWith("Fejl:") ? "" : "Fejl: "}${
          translations["da"]["errors"][code] || message
        }`
      : message;

    console.error(`${code}: ${errorMessage}`);

    super(errorMessage);
  }
}

export const translations = {
  da: {
    errors: danishErrors,
  },
};

export const getReepayErrorText = getReepayErrorTextFn;
