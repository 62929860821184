import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { Button, ButtonProps } from "antd";

interface Props extends ButtonProps {
  label?: string;
}

export const ButtonEdit = (props: Props) => {
  const { label } = props;

  return (
    <Button {...props}>
      <FontAwesomeIcon icon={faPen} size="sm" className="" />
      {label && <span className="ml-2">{label}</span>}
    </Button>
  );
};
