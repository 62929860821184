import { EnhancedOrder, Order, OrderPersonal } from "@kanpla/types";
import { groupBy, sortBy } from "lodash";
import { getConfig } from "./getConfig";
import { getOrders } from "./getOrders";
import { getTotals } from "./getTotals";
import { getUniqueUsers } from "./getUniqueUsers";

export const groupedOrders = (
  noEmptyOrders: Array<EnhancedOrder>,
  groupByField: string
) =>
  groupBy(
    noEmptyOrders.reduce((acc, order) => {
      acc.push(...order.order.map((ord) => ({ ...order, ...ord })));
      return acc;
    }, []),
    groupByField
  );

const productSorter = (
  noEmptyOrders: Array<EnhancedOrder>,
  type: "product" | "school" | "group" | "child",
  displayMode: "products" | "earnings" | "users"
) => {
  // 1. Group orders by type (product, school, or group)

  const groupFactors = {
    product: "productId",
    school: "schoolId",
    group: "groupName",
    child: "displayName",
  };

  const grouped = groupedOrders(noEmptyOrders, groupFactors[type]);

  // 2. For each group, get display details by days
  const displayOrders = Object.entries(grouped).map(([docId, data]) => {
    const byDayName = groupBy(data, "dayName");
    const ordersByName = getOrders(byDayName, displayMode);
    const totalPrice = getOrders(byDayName, "earnings");
    const uniqueUsers = getUniqueUsers(byDayName);

    const generateTitle = () => {
      switch (type) {
        case "product":
          return data.reduce((acc, doc) => {
            if (!doc.name) return acc;
            acc = doc.name;
            return acc;
          }, undefined);
        case "school":
          return data[0].school?.name;
        case "group":
          return data[0]?.groupName || "—";
      }
    };

    const toReturn = {
      title: generateTitle() || docId,
      id: docId,
      key: docId,
      config: getConfig(byDayName, displayMode),
      data: ordersByName,
      uniqueUsers: uniqueUsers,
      totalPrice: totalPrice,
      total:
        displayMode !== "users" ? getTotals(ordersByName) : uniqueUsers.length,
    };

    if (type === "group") {
      toReturn["group"] =
        data[0].groupName && data[0].groupName !== "" ? data[0].groupName : "—";
    }

    return toReturn;
  });

  // 3. Sort by title (either product name or school name)
  const rewrittenData = sortBy(displayOrders, "title");

  return rewrittenData;
};

export { productSorter };
