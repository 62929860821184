import { Child, Module, School } from "@kanpla/types";
import { hasAccessToModule } from "./hasAccessToModule";
import { sortModules } from "./sortModules";

interface Props {
  child: Child;
  school: School;
  modules: Array<Module>;
}

export const filterModulesForChild = (props: Props) => {
  const { modules, school, child } = props;

  const filtered = (modules || []).filter((module) => {
    if (module.deleted) return false;

    const belongsToSchool = module.scope?.generatedSchoolIds?.includes(
      school?.id || null
    );

    if (!belongsToSchool) return false;

    const hasAccess = hasAccessToModule({
      child,
      school,
      module,
    });

    return (
      belongsToSchool &&
      ((module.type === "flex" && (hasAccess.bulk || hasAccess.individual)) ||
        (module.type !== "flex" && hasAccess.other))
    );
  });

  const sorted = sortModules(filtered);

  return sorted;
};
