import {
  CombinedOfferItem,
  FlexOption,
  OrderOrder,
  OrderOrderProduct,
} from "@kanpla/types";
import { ProductSettingsHeader } from "@kanpla/ui";
import { isEmpty } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import MultipleLayers from "./MultipleLayers";
import NoLayer from "./NoLayer";
import OneLayer from "./OneLayer";

interface Props {
  /** Target product */
  product: CombinedOfferItem;
  /** mealOptions from frontend AppContext */
  options?: FlexOption[];
  /** Value of the inputs */
  order: OrderOrder;
  /** OnChange of the inputs */
  setOrder: (newValue: OrderOrder) => void;
  /** Maximum about of the product allowed (for limits, etc.) */
  maxAmount?: number;
}

interface VariantsContext {
  product: CombinedOfferItem;
  availableOptions?: FlexOption[];
  order: OrderOrder;
  /** Alias to order[product.id] */
  orderProduct: OrderOrderProduct;
  setOrder: (newValue: OrderOrder) => void;
  maxAmount: number;
}

export const VariantsContext = React.createContext<Partial<VariantsContext>>(
  {}
);

export const VariantsContent = (props: Props) => {
  const { product, options, order, setOrder, maxAmount = Infinity } = props;
  const { t, i18n } = useTranslation(["libs"]);
  const orderProduct = order?.[product.productId];

  const productOptionIds = product.options || [];
  const availableOptions =
    options?.filter((o) => productOptionIds.includes(o.id)) || [];
  const numberOfOptionLayers = availableOptions.length;

  const Content =
    numberOfOptionLayers === 0
      ? NoLayer
      : numberOfOptionLayers === 1
      ? OneLayer
      : MultipleLayers;

  return (
    <VariantsContext.Provider
      value={{
        availableOptions,
        product,
        order,
        orderProduct,
        setOrder,
        maxAmount: maxAmount || Infinity,
      }}
    >
      <ProductSettingsHeader
        title={isEmpty(i18n) ? "Vælg antal" : t("libs:select-number")}
        // subtitle="Dit køkken giver dig muligheden for at vælge dit ønskede
        // leveringstidspunkt."
      />
      <Content />
    </VariantsContext.Provider>
  );
};
