import { configLabel } from "@kanpla/system";
import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const PaymentAmount = ({ amount, setAmount }) => {
  const { t } = useTranslation(["forms"]);
  return (
    <Form.Item
      className="form-label-antd-h-unset"
      labelCol={configLabel({ span: 24 })}
      label={t("forms:amount")}
    >
      <Input
        className="mb-2"
        id="payment-amount"
        type="number"
        placeholder="200"
        min="50"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        required
      />
      <div className="flex -mx-2 justify-center">
        <Button
          size="small"
          className="mr-1"
          onClick={() => setAmount(50)}
          id="cy-payment-insert-50"
        >
          50
        </Button>
        <Button size="small" className="mr-1" onClick={() => setAmount(100)}>
          100
        </Button>
        <Button size="small" className="mr-1" onClick={() => setAmount(200)}>
          200
        </Button>
        <Button size="small" className="mr-1" onClick={() => setAmount(500)}>
          500
        </Button>
        <Button size="small" onClick={() => setAmount(1000)}>
          1.000
        </Button>
      </div>
    </Form.Item>
  );
};

export default PaymentAmount;
