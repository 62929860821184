import { Child, Module } from "@kanpla/types";
import { db } from "../firebase.config";
import { fetchCollection } from "../firestore/fetchCollection";

/**
 * This method fetch the modules that have inside the scope.generatedSchoolIds property a
 * reference to the current child school id.
 */
export const getModulesByChildSchoolId = async (child: Child) => {
  return await fetchCollection<Module>(
    db
      .collection("modules")
      .where("scope.generatedSchoolIds", "array-contains", child.schoolId)
  );
};
