import React, { FC, ReactNodeArray, useEffect, useRef } from "react";
import classnames from "classnames";
import { scroll2Elem } from "@kanpla/system";

interface SliderItemProps {
  scrollableElement: HTMLDivElement;
  selected?: boolean;
  onClick: () => void;
}

interface HorizontalSliderProps {
  selectedIndex: number;
  items: ReactNodeArray;
  onClick: (index: number) => void;
  classNames?: string;
}

const SliderItem: FC<SliderItemProps> = ({
  children,
  onClick,
  scrollableElement,
  selected = false,
}) => {
  const ref = useRef<HTMLDivElement>();

  /**
   * The smooth scrolling shouldn't be handled on time of click event,
   * since the click event can be prevented by the parent component of `HorizontalSlider`.
   * Instead, it should be handled on `selectedIndex` change.
   */
  useEffect(() => {
    if (!selected || !scrollableElement) return;
    const parentWidth = scrollableElement.clientWidth;
    const currentWidth = ref.current?.clientWidth || 0;
    /** The margins of items is not counted here. */
    const offsetX = parentWidth / 2 - currentWidth / 2;

    scroll2Elem(ref.current, { offsetX, scrollableElement });
  }, [selected, scrollableElement]);

  return (
    <div
      ref={ref}
      onClick={onClick}
      className={classnames(
        "w-fit mr-3 whitespace-nowrap px-4 py-2",
        "rounded-full cursor-pointer h-full border border-divider-main md:hover:shadow shadow-divider-main transition",
        { "bg-background-secondary": selected }
      )}
    >
      {children}
    </div>
  );
};

export const HorizontalSlider: FC<HorizontalSliderProps> = ({
  items,
  onClick,
  selectedIndex = 0,
  classNames,
}) => {
  const scrollableElement = useRef<HTMLDivElement>();
  if (!items?.length) return null;

  const containerClasses = classnames(
    classNames,
    "w-full h-fit overflow-x-auto px-5 flex flex-nowrap items-center scrollbar-hide"
  );

  const overlayClasses =
    "w-8 from-background-primary absolute inset-y-0 z-40 pointer-events-none";

  return (
    <div className="relative">
      <div className={containerClasses} ref={scrollableElement}>
        {items.map((content, index) => (
          <SliderItem
            key={index}
            selected={index === selectedIndex}
            onClick={() => onClick(index)}
            scrollableElement={scrollableElement.current}
          >
            {content}
          </SliderItem>
        ))}

        {/* Element with zero-width-space to fix last item's right-padding in mobile. */}
        <div style={{ minWidth: "10px", minHeight: "100%" }}>&#8203;</div>
      </div>
      <div className={classnames(overlayClasses, "left-0 bg-gradient-to-r")} />
      <div className={classnames(overlayClasses, "right-0 bg-gradient-to-l")} />
    </div>
  );
};
