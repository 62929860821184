import { DrawerOrModal, Image } from "@kanpla/ui";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import React, { Dispatch, SetStateAction } from "react";

export interface CardErrorProps {
  text: string | JSX.Element;
  extra?: string;
}

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  cardError: CardErrorProps;
  setCardError: Dispatch<SetStateAction<CardErrorProps>>;
}

const ErrorModal = ({ open, setOpen, cardError, setCardError }: Props) => {
  const { t } = useTranslation(["translation"]);
  return (
    <DrawerOrModal
      open={open}
      setOpen={(state) => {
        setCardError({ text: t("translation:payment-failed") });
        setOpen(state);
      }}
      actions={[
        <a
          rel="noreferrer"
          href="https://intercom.help/kanpla/da/articles/4294601-hvordan-optanker-jeg-min-kanpla-konto"
          target="_blank"
          className="text-background-primary focus:text-background-primary"
        >
          <Button
            size="large"
            className="bg-gradient-to-b from-danger-main to-danger-dark w-full text-background-primary focus:text-background-primary"
          >
            {t("translation:read-more")}
          </Button>
        </a>,
        <Button
          type="primary"
          danger
          size="large"
          className="w-full"
          onClick={() => {
            setCardError({ text: t("translation:payment-failed") });
            setOpen(false);
          }}
        >
          Okay
        </Button>,
      ]}
    >
      <h2 className="h700 text-danger-main">
        {t("translation:payment-failed")}
      </h2>
      <Image
        src="/images/card_error.svg"
        alt={t("translation:payment-failed")}
        className="my-6 mx-auto w-3/4 md:w-full"
      />
      <p>{cardError.text}</p>
      {cardError.extra && (
        <p className="p-2 bg-background-secondary rounded h200 mt-4 border border-divider-main">
          {cardError.extra}
        </p>
      )}
    </DrawerOrModal>
  );
};

export default ErrorModal;
