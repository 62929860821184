import { Week } from "@kanpla/types";
import moment from "moment";
import { getWeekSeconds } from "./getWeekSeconds";
import { timestampToMoment } from "./timestampToMoment";

export const weekToDisplayString = (week?: Week, dictionary?: string) => {
  const startDate = timestampToMoment(week[0]);
  const endDate = timestampToMoment(week[4]);

  const sameMonth = startDate.format("M") === endDate.format("M");

  const startDateFormatted = startDate.format(sameMonth ? "D" : "DD/M");
  const endDateFormatted = endDate.format("D/M");

  const genericFormat = `${
    dictionary || "Uge"
  } ${startDate.week()}: ${startDateFormatted}-${endDateFormatted}`;

  const currentSeconds = getWeekSeconds(moment().unix());
  const weeksAhead = (week[0].seconds - currentSeconds) / 604800;
  const displayString =
    weeksAhead === 0
      ? "Denne uge"
      : weeksAhead === 1
      ? "Næste uge"
      : genericFormat;

  return displayString;
};
