/* eslint-disable @typescript-eslint/ban-ts-comment */
import { OrderInfo, Plugins } from "@kanpla/types";
import { ProductSettingsHeader } from "@kanpla/ui";
import { Form, Select, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { isEmpty } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

const { Option } = Select;

interface Props {
  settings: Plugins.TextInput;
  orderInfo: OrderInfo;
  schoolId: string;
  noBg?: boolean;
}

const TextInputInner = ({ field, value, onChange }) => {
  const { t, i18n } = useTranslation(["libs"]);
  const isTextInput = field?.type === "text" || !field?.type;
  const isSelectInput =
    field?.type === "select" && field?.type && !field?.isMultiSelect;
  const isMultiSelectInput =
    field?.type === "select" && field?.type && field?.isMultiSelect;

  if (isTextInput)
    return (
      <TextArea
        className="my-2"
        required={field?.required}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={field.placeholder || field?.title || ""}
        autoSize={{
          minRows: field.minRows || 1,
          maxRows: field.maxRows || 3,
        }}
      />
    );

  if (isSelectInput)
    return (
      <Select
        className="my-2"
        placeholder={
          isEmpty(i18n) ? "Vælg en mulighed" : t("libs:select-an-option")
        }
        value={typeof value === "string" ? value : value?.[0]}
        onChange={(_value) => {
          onChange(_value);
        }}
      >
        {field.options.map((option, i) => (
          <Option key={i} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    );

  if (isMultiSelectInput)
    return (
      <Select
        className="my-2"
        mode={"multiple"}
        placeholder={
          isEmpty(i18n)
            ? "Vælg en eller flere muligheder"
            : t("libs:select-one-or-more")
        }
        value={value}
        onChange={(_value) => {
          onChange(_value);
        }}
      >
        {field.options.map((option, i) => (
          <Option key={i} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    );
};

export const TextInput = (props: Props) => {
  const { settings, orderInfo = {}, schoolId, noBg } = props;
  const { t, i18n } = useTranslation(["libs"]);
  const { textInput } = orderInfo || {};

  // useEffect(() => {
  //   if (isEmpty(value)) onChange(textInput);
  // }, [textInput]);

  // Filter out fields disabled for this school
  const availableFields =
    settings?.fields?.filter((f) => !f.disabledSchoolIds?.includes(schoolId)) ||
    [];
  if (availableFields.length === 0) return null;

  return (
    <Space direction="vertical" className="w-full">
      {availableFields.map((field) => {
        return (
          <Form.Item
            key={field.key}
            name={`textInput.${field.key}`}
            initialValue={textInput?.[field.key]}
            rules={[
              {
                required: field?.required,
                message: isEmpty(i18n)
                  ? "Dette felt er påkrævet"
                  : t("libs:specific-field-required", {
                      fieldName: field?.title,
                    }),
              },
            ]}
            label={
              <ProductSettingsHeader
                title={field.title}
                subtitle={field.subtitle}
                noBg={noBg}
              />
            }
          >
            {/* @ts-ignore */}
            <TextInputInner field={field} />
          </Form.Item>
        );
      })}
    </Space>
  );
};
