import { Billy } from "@kanpla/types";
import { BillyClient } from ".";
import CryptoJS from "crypto-js";

export const postProduct = async (client: BillyClient, name: string) => {
  const res = (await client.request("POST", "/products", {
    product: {
      name,
      productNo:
        "KNPL" + CryptoJS.MD5(name) + CryptoJS.lib.WordArray.random(10),
    },
  } as { product: { name: string; productNo: string } })) as Billy.Response<Billy.Product>;

  return res.products[0];
};
