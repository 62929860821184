import { fn } from "@kanpla/system";
import { PosOnePartnerData } from "@kanpla/types";
import { message } from "antd";
import { useDocumentListener } from "libs/system/src/firestore/UseDocumentListener";
import { useEffect, useState } from "react";

interface Props {
  partnerId: string;
  userId: string;
  auth;
}

export const usePosoneBalance = ({ partnerId, userId, auth }: Props) => {
  // 1. Is PosOne
  const [isPosone, setIsPosone] = useState(false);

  useEffect(() => {
    auth?.user
      ?.getIdTokenResult()
      .then(({ claims }) => {
        const provider = claims.provider;
        setIsPosone(provider === "Posone");
      })
      .catch((e) => message.warning(e?.message));
  }, [userId]);

  // 2. Balance
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [balance, setBalance] = useState(0);

  const getBalance = async () => {
    setBalanceLoading(true);
    try {
      if (!partnerId) return;
      const newBalance = await fn.httpsCallable("getPosoneBalance")({
        partnerId,
      });
      setBalance(newBalance.data);
    } catch (e) {
      message.error(e?.message);
    } finally {
      setBalanceLoading(false);
    }
  };

  useEffect(() => {
    if (!partnerId) return;
    if (!isPosone) return;

    getBalance();
    const interval = setInterval(getBalance, 1000 * 60 * 5);
    return () => clearInterval(interval);
  }, [partnerId, isPosone]);

  // 3. Partner data
  const [posonePartnerData] = useDocumentListener<PosOnePartnerData>({
    collection: "partnersPosOne",
    id: partnerId,
  });

  return {
    getBalance,
    balance,
    balanceLoading,
    posonePartnerData,
    isPosone,
  };
};
