import { FirebaseFirestore } from "@firebase/firestore-types";
import { OrderConfig, OrderPersonal } from "@kanpla/types";
import groupBy from "lodash/groupBy";
import { getConfigNameAndId } from "../flex/cleanOrders";
import { calculateConfigTotal } from "../orders/calculateConfigTotal";

interface EntriesProps {
  id: string;
  amount: number;
  price: number;
  reference?: string;
  orderId: string;
  displayName?: string;
}

const groupItems = (
  items: Array<OrderPersonal> = [],
  collection: string,
  db: FirebaseFirestore,
  countVariants?: boolean
) => {
  const orders = items.map(
    ({ order: data, id: orderId, displayName, info }) => {
      const smushedOrders = Object.entries(data || {}).reduce(
        (acc, [id, data]) => {
          const reference = info?.reference || data.reference;

          acc.push({
            id: `${id}-${data.price || 0}`,
            price: data.price || 0,
            amount: data.amount || 0,
            reference: reference || null,
            orderId: orderId,
            displayName: displayName || "-",
            name: data.name,
          });

          // Add config as their own product
          countVariants &&
            (data.config || [])
              // Filter out empty or free config
              .filter(
                (conf) =>
                  conf?.amount > 0 &&
                  calculateConfigTotal(conf || ({} as OrderConfig)) > 0
              )
              .map((conf) => {
                if (!conf) return;
                if (Object.values(conf.options || {}).length === 0) return;

                Object.entries(conf.options).map(([choiceId, choiceData]) => {
                  if (choiceData?.extraPrice === 0 || choiceData?.amount === 0)
                    return;

                  const confInitials = getConfigNameAndId({
                    [choiceId]: choiceData,
                  });
                  acc.push({
                    id: `${orderId}-${confInitials.id}-${confInitials.name}-${
                      choiceData?.extraPrice || 0
                    }`,
                    price: choiceData?.extraPrice || 0,
                    amount: choiceData?.amount * (data.amount || 0),
                    reference: reference || null,
                    orderId: orderId,
                    displayName: displayName || "-",
                    name: `Variant ${choiceData.name}, tilhørende "${data.name}"`,
                  });
                });
              });

          return acc;
        },
        []
      );

      return smushedOrders;
    }
  );
  const allItems = orders.flat();
  const groupedItems = groupBy(allItems, "id");

  const data = Object.entries(groupedItems).map(([id, data]) => ({
    name: data[0]?.name,
    id,
    reference:
      data
        .filter((d) => d.reference && d.amount > 0)
        .map((d) => `${d.amount}x ${d.reference} (${d.amount * d.price}kr.)`) ||
      [],
    data: data.reduce(
      ({ totalAmount, totalPrice }, { amount, price }) => ({
        totalAmount: totalAmount + amount,
        totalPrice: totalPrice + price * amount,
        unitPrice: price,
      }),
      { totalAmount: 0, totalPrice: 0, unitPrice: 0 }
    ),
    split: data || [],
  }));

  const namedData = data.map(async (d) => {
    const oldData = d as {
      name?: string;
      id: string;
      data: any;
    };
    try {
      if (!oldData.name) {
        const name = (
          await db.collection(collection).doc(d.id?.split("-")[0]).get()
        ).data();

        oldData.name =
          (name?.name || "Uden navn") + " - " + d.data.unitPrice + "kr.";
      }
      // console.log(collection, d.id);
      return oldData;
    } catch (e) {
      console.log("Error naming item group", e);
      return;
    }
  });

  return namedData;
};

export default groupItems;
