import { db, fetchDocument } from "..";
import { Firestore } from "@google-cloud/firestore";
import { Child } from "@kanpla/types";

interface Props {
  childId: string;
  userId: string;
  /** You can pass in a custom db (in case of GCP) */
  customDb?: Firestore;
}

/**
 * Check whether the child belongs to the user
 * @param props Send in userId and childId in object
 * @returns boolean if the child belongs to the user
 */
export const isChildUser = async (props: Props) => {
  const { childId, userId, customDb } = props;

  const useDb = customDb || db;

  try {
    // Allow superadmin
    const child = await fetchDocument<Child>(
      useDb.collection("children").doc(childId)
    );

    if (child.userId === userId) return true;

    throw new Error("Child doesn't belong to the user");
  } catch (err) {
    console.error(err);
    throw new Error(err?.message);
  }
};
