import { useDropdown } from "@kanpla/system";
import React, { ReactElement, useEffect, useRef } from "react";
import { animated, config, useTransition } from "react-spring";
import { Lock } from "../modal/Lock";

interface Props {
  children: ReactElement;
  button: ReactElement;
  className?: string;
  overlay?: boolean;
  style?: React.CSSProperties;
  /** Close by clicking on button again */
  easyClose?: boolean;
}

export const ContextCard = (props: Props) => {
  const { children, button, className, overlay, style = {}, easyClose } = props;

  const cardEl = useRef();
  const { open, openUp, closeUp } = useDropdown(cardEl, easyClose);

  const openRef = useRef(open);

  useEffect(() => {
    setTimeout(() => (openRef.current = open), 400);
  }, [open]);

  const triggerOpenClose = () => {
    !openRef.current ? openUp() : closeUp();
  };

  const modalTransitions = useTransition(open, {
    config: { mass: 1, tension: 500, friction: 77 },
    from: { opacity: 1, transform: "translate3d(0,6px,0)" },
    enter: { opacity: 1, transform: "translate3d(0,0px,0)" },
    leave: { opacity: 0, transform: "translate3d(0,8px,0)" },
  });

  const overlayTransitions = useTransition(open, {
    config: config.slow,
    from: { opacity: 0 },
    enter: { opacity: 0.5 },
    leave: { opacity: 0 },
  });

  return (
    <div className="relative">
      <button className="block h-full" onClick={triggerOpenClose} type="button">
        {button}
      </button>
      <div ref={cardEl}>
        {overlay &&
          overlayTransitions(
            (props, item) =>
              item && (
                <animated.div
                  style={{ ...props, pointerEvents: open ? "all" : "none" }}
                  className="fixed inset-0 bg-text-primary opacity-25 z-20"
                  onClick={() => closeUp()}
                >
                  <Lock />
                </animated.div>
              )
          )}
        {modalTransitions(
          (props, item) =>
            item && (
              <animated.div
                style={{ ...props, maxHeight: "80vh", ...style }}
                className={`absolute z-max card shadow-xl overflow-x-visible overflow-y-scroll ${className}`}
              >
                {React.cloneElement(children, {
                  open,
                  openup: openUp,
                  closeup: closeUp,
                })}
              </animated.div>
            )
        )}
      </div>
    </div>
  );
};
