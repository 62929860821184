import { useLayoutEffect } from "react";

// Hook
export const useBodyLockScroll = (querySelector?: string) => {
  useLayoutEffect(() => {
    const component = document.querySelector<HTMLElement>(
      querySelector
        ? querySelector
        : // @ts-ignore
        typeof window !== "undefined" && window.isRenderedInWebView
        ? "body"
        : "html"
    );

    // Prevent scrolling on mount
    component.style.overflow = "hidden";
    // Re-enable scrolling when component unmounts
    return () => {
      component.style.overflow = "auto";
    };
  }, []); // Empty array ensures effect is only run on mount and unmount
};
