import { Module } from "@kanpla/types";

interface Props {
  /** Module to check */
  module: Module;
}

/**
 * This method check if a module is available to an anonymous user.
 */
export const checks = ({ module }: Props) => {
  /** It's hide in the desktop tabs */
  const hide = module?.displayOptions?.hideDesktopTab;
  /** If the payment method exist and it's credit, it means that this module is public */
  const isPaymentMethodCredit =
    module?.paymentMethod && module?.paymentMethod === "credit";
  /** If the module type is flex and is not hidden and the flow is menuPreview, it means that this module is public */
  const isFlexAndMenuPreview =
    module?.type === "flex" && module?.flow === "menuPreview";
  /** If the module type is homescreen and it's not hidden, it means that this module is public */
  const isHomescreen = module?.type === "homescreen";
  return {
    hide,
    isPaymentMethodCredit,
    isFlexAndMenuPreview,
    isHomescreen,
  };
};
