/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from "react";

const ServiceWorker = () => {
  const [showBanner, setShowBanner] = useState(false);
  // This hook only run once in browser after the component is rendered for the first time.
  // It has same effect as the old componentDidMount lifecycle callback.
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      "serviceWorker" in navigator &&
      //@ts-ignore
      window.workbox !== undefined
    ) {
      //@ts-ignore
      const wb = window.workbox;
      // add event listeners to handle any of PWA lifecycle event
      // https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-window.Workbox#events
      //   wb.addEventListener('installed', event => {
      //
      //
      //   })

      //   wb.addEventListener('controlling', event => {
      //
      //
      //   })

      //   wb.addEventListener('activated', event => {
      //
      //
      //   })

      // A common UX pattern for progressive web apps is to show a banner when a service worker has updated and waiting to install.
      // NOTE: MUST set skipWaiting to false in next.config.js pwa object
      // https://developers.google.com/web/tools/workbox/guides/advanced-recipes#offer_a_page_reload_for_users
      const promptNewVersionAvailable = (event) => {
        // `event.wasWaitingBeforeRegister` will be false if this is the first time the updated service worker is waiting.
        // When `event.wasWaitingBeforeRegister` is true, a previously updated service worker is still waiting.
        // You may want to customize the UI prompt accordingly.
        if (
          confirm(
            "A newer version of this web app is available, reload to update?"
          )
        ) {
          wb.addEventListener("controlling", (event) => {
            window.location.reload();
          });

          // Send a message to the waiting service worker, instructing it to activate.
          wb.messageSW({ type: "SKIP_WAITING" });
        } else {
          // 'User rejected to reload the web app, keep using old version. New version will be automatically load when user open the app next time.'
        }
      };

      wb.addEventListener("waiting", promptNewVersionAvailable);
      wb.addEventListener("externalwaiting", promptNewVersionAvailable);

      // ISSUE - this is not working as expected, why?
      // I could only make message event listenser work when I manually add this listenser into sw.js file
      wb.addEventListener("message", (event) => {});

      /*
      wb.addEventListener('redundant', event => {
        
        
      })

      wb.addEventListener('externalinstalled', event => {
        
        
      })

      wb.addEventListener('externalactivated', event => {
        
        
      })
      */

      // never forget to call register as auto register is turned off in next.config.js
      wb.register();
    }
  }, []);

  return null;
};

export default ServiceWorker;
