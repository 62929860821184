import { useMemo, useRef, useState } from "react";

export const useDropdown = (buttonEl, easyClose?: boolean) => {
  const [open, _setOpen] = useState(false);
  const openRef = useRef(open);

  const setOpen = (data: boolean) => {
    openRef.current = data;
    _setOpen(data);
  };

  const detectClick = (e) => {
    const scrollbar =
      e.offsetX > e.target.clientWidth || e.offsetY > e.target.clientHeight;
    const isOutside = buttonEl.current && !buttonEl.current.contains(e.target);

    if (!isOutside && openRef.current) closeUp();
    if (!scrollbar && isOutside) {
      closeUp();
    }
  };

  const detectKey = (e) => e.keyCode === 27 && closeUp();

  const openUp = () => {
    setOpen(true);
    document.addEventListener("mousedown", detectClick, false);
    document.addEventListener("keydown", detectKey, false);
  };

  const closeUp = () => {
    setOpen(false);
    document.removeEventListener("mousedown", detectClick, false);
    document.removeEventListener("keydown", detectKey, false);
  };

  return { open, openUp, closeUp };
};
