import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { db, useCollectionListener } from "@kanpla/system";
import { Image as ImageType, UnsplashImage } from "@kanpla/types";
import Image from "./../Image";
import { Empty, message, Pagination } from "antd";
import { orderBy } from "lodash";

interface Props {
  partnerId: string;
  selectedImage: string;
  setSelectedImage: Dispatch<SetStateAction<string>>;
  setImages: Dispatch<SetStateAction<ImageType[] | UnsplashImage[]>>;
  view: "kanpla" | "unsplash";
}

const KanplaPhotos = (props: Props) => {
  const { partnerId, selectedImage, setSelectedImage, setImages, view } = props;
  const [current, setCurrent] = useState<number>(1);

  const [images = [], loading, error] = useCollectionListener<ImageType>(
    partnerId
      ? db
          .collection("images")
          .where("partnerId", "==", partnerId)
          .where("shared", "==", true)
      : null
  );

  useEffect(() => {
    if (error) return message.error(error?.message);
  }, [error]);

  const imagesToString = JSON.stringify(images);

  useEffect(() => {
    if (view === "kanpla") {
      setImages(images);
    }
  }, [imagesToString, view]);

  return (
    <main className="h-full w-full flex flex-col">
      {(images.length > 0 || loading) && (
        <div className="grid grid-cols-1 grid-rows-12 md:grid-cols-2 md:grid-rows-6 lg:grid-cols-3 lg:grid-rows-4 xl:grid-cols-4 xl:grid-rows-3 gap-2 md:gap-4 lg:gap-8 flex-grow w-full flex-shrink">
          {loading &&
            Array(12)
              .fill("loader")
              .map((l, key) => (
                <div
                  className="w-full h-20 loader rounded-lg"
                  key={`${l}-${key}`}
                />
              ))}

          {!loading &&
            orderBy(images, "createdAtSeconds", "desc")
              .filter(
                (_, index) =>
                  index >= (current - 1) * 12 && index < current * 12
              )
              .map((image) => (
                <Image
                  image={image}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                  key={image.id}
                />
              ))}
        </div>
      )}

      {images.length === 0 && !loading && (
        <Empty
          className="h-full w-full flex items-center justify-center flex-col"
          description="Der er ikke nogle tilgængelige billeder"
        />
      )}

      <nav className="mt-6 flex-shrink-0 flex items-center">
        <Pagination
          className="mx-auto inline-block"
          size="small"
          total={images.length}
          current={current}
          onChange={(e) => setCurrent(e.valueOf())}
          disabled={images.length <= 12}
        />
      </nav>
    </main>
  );
};

export default KanplaPhotos;
