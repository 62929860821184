import React from "react";
import { getPixelValues, size } from "./IconSizes";

interface Props {
  color?: string;
  size?: size;
}

export const BreakfastIcon = ({ color = null, size = "regular" }: Props) => {
  const dimension = getPixelValues(size);

  return (
    <svg
      width={dimension}
      height={dimension}
      viewBox="0 0 57 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.6959 7.03567C36.7861 5.24766 36.1387 3.50022 34.905 2.2025C34.9044 2.20193 34.9044 2.20193 34.9044 2.20193C33.6707 0.904783 31.9581 0.169922 30.1678 0.169922H27.6901C25.8998 0.169922 24.1872 0.904783 22.9535 2.20193C22.9535 2.20193 22.9535 2.20193 22.9529 2.2025C21.7192 3.50022 21.0718 5.24766 21.1621 7.03567C21.3804 11.3643 21.6832 17.3746 21.8941 21.5603C22.0689 25.038 24.9398 27.7678 28.4221 27.7678H29.4358C32.9181 27.7678 35.789 25.038 35.9639 21.5603L36.6959 7.03567ZM38.6107 4.35337C38.8622 5.25337 38.9696 6.19623 38.9216 7.14824L38.1896 21.6723C38.1113 23.2334 37.6273 24.6826 36.8433 25.9192C39.8782 26.7912 42.913 25.5563 43.961 23.1683C45.2542 20.2209 47.1108 15.9888 48.4479 12.9414C49.0508 11.5677 48.9582 9.96025 48.1302 8.49967C47.381 7.17681 46.0736 6.08652 44.4936 5.50766L42.2833 4.69794C41.0719 4.25394 39.7879 4.14308 38.6107 4.35337ZM21.0146 25.9192C20.2306 24.6826 19.7466 23.2334 19.6684 21.6723C19.4575 17.4866 19.1546 11.4763 18.9364 7.14824C18.8884 6.19623 18.9958 5.25337 19.2472 4.35337C18.0701 4.14308 16.7861 4.25394 15.5741 4.69794L13.3643 5.50766C11.7843 6.08652 10.4769 7.17681 9.72774 8.49967C8.89974 9.96025 8.80717 11.5677 9.41003 12.9414C10.7472 15.9888 12.6038 20.2209 13.8969 23.1683C14.9449 25.5563 17.9792 26.7906 21.0146 25.9192ZM7.31516 13.7106C5.39172 14.7791 3.60486 16.0563 2.45171 17.4677C0.844849 19.4357 0.340847 21.654 1.76657 24.0283C2.09971 24.5832 2.97114 25.1215 4.38143 25.1877C6.12259 25.27 9.01917 24.8197 11.9255 24.2163L11.8563 24.0637C10.5632 21.1163 8.7066 16.8843 7.36945 13.8374C7.35116 13.7951 7.33288 13.7528 7.31516 13.7106ZM50.5428 13.7106C50.5251 13.7523 50.5068 13.7951 50.4885 13.8368C49.1513 16.8843 47.2948 21.1163 46.0016 24.0637L45.9325 24.2163C48.8388 24.8197 51.7354 25.27 53.4765 25.1877C54.8868 25.1215 55.7582 24.5832 56.0914 24.0283C57.5171 21.654 57.0131 19.4351 55.4057 17.4677C54.2525 16.0557 52.4656 14.7791 50.5428 13.7106Z"
        fill={color || "#4A5568"}
      />
    </svg>
  );
};
