import { OrderOrder, OrderOrderProduct } from "@kanpla/types";
import { removeUndefinedKeys } from "..";

/**
 * Returns a negated order, so all amounts will be negative
 * @param order
 */

export const negateOrder = (order: OrderOrder) => {
  const newOrder: OrderOrder = Object.entries(order || {}).reduce(
    (acc, [productId, productLine]) => {
      const negatedConfigs = productLine.config?.map((c) => ({
        ...c,
        amount: -c.amount,
      }));

      const productData: OrderOrderProduct = {
        ...productLine,
        amount: -productLine.amount,
        config: negatedConfigs,
      };

      removeUndefinedKeys(productData);

      acc[productId] = productData;

      return acc;
    },
    {}
  );

  return newOrder;
};
