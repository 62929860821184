import { Module, School } from "@kanpla/types";
import { isEmpty } from "lodash";

export const getAvailableAccessCodes = (
  modules: Array<Module>,
  school: School
) => {
  const allAvailableCodes = [
    ...modules
      .filter((module) =>
        module.scope?.generatedSchoolIds?.includes(school?.id)
      )
      .map((module) => {
        if (!module || isEmpty(module)) return;

        const schoolSpecificSettings = school?.modules?.[module.id] || {};

        return [
          ...(module.config?.codes || []),
          ...(module.config?.bulkCodes || []),
          ...((!schoolSpecificSettings.individualDisabled &&
            schoolSpecificSettings.codes) ||
            []),
          ...((schoolSpecificSettings.bulkEnabled &&
            schoolSpecificSettings.bulkCodes) ||
            []),
        ];
      })
      .flat(),
  ];

  return allAvailableCodes;
};
