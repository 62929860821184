import { Module } from "@kanpla/types";
import * as firebase from "@google-cloud/firestore";
import { db, fetchDocument, isPartnerAdmin } from "..";

interface Options {
  db: firebase.Firestore;
}

/**
 * Gets and returns modules ids from given set `moduleIds`
 * of user with given `userId` argument.
 *
 * * Superadmin has access to all modules.
 * * Admin has access to module if admin is a partner of that module.
 */
export const getAuthorizedModuleIds = async (
  adminUserId: string,
  moduleIds?: string[],
  options?: Options
): Promise<string[] | undefined> => {
  if (!Array.isArray(moduleIds)) return undefined;

  const modulePromises: Promise<Module>[] =
    moduleIds.map((moduleId) =>
      fetchDocument<Module>(
        (options?.db || db).collection("modules").doc(moduleId)
      )
    ) || [];

  const modules: Module[] = await Promise.all(modulePromises);

  const authorizedModules = modules.filter(({ partnerId }) =>
    isPartnerAdmin({
      db: options?.db || db,
      userId: adminUserId,
      partnerId,
    })
  );

  return authorizedModules.map((m) => m.id);
};
