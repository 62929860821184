import { OrderConfig, OrderOrder } from "@kanpla/types";
import { set, sum } from "lodash";
import { diff } from "just-diff";

interface Props {
  /** Order to make the changes on */
  order: OrderOrder;
  /** Product to edit */
  productId: string;
  /** Product config to edit */
  choice?: OrderConfig["options"];
  /** Change in number of products. Positive to add, negative to remove */
  amountChange: number;
  /** If true, current items don't count */
  replaceAmount?: boolean;
}

export const editConfigAmount = (props: Props) => {
  const { order, productId, choice, amountChange, replaceAmount } = props;
  const newOrder = Object.assign({}, order);

  const validAmountChange =
    !amountChange || isNaN(amountChange) ? 0 : amountChange;
  let totalChange = validAmountChange;

  // Change amounts
  const prevTotalAmount = newOrder?.[productId]?.amount || 0;

  // If it has a config, find it and adjust the amount on that
  if (choice) {
    const isTargetConfig = (conf) => diff(choice, conf.options).length === 0;

    const originalConfig = newOrder?.[productId]?.config || [];

    // Update target config if exists, or create a new one
    const index: number = originalConfig.findIndex((conf) =>
      isTargetConfig(conf)
    );
    const prevConfig = originalConfig[index];
    const prevAmount = prevConfig?.amount || 0;

    const newConfig: OrderConfig = {
      options: choice,
      amount: replaceAmount
        ? validAmountChange
        : prevAmount + validAmountChange,
    };

    if (replaceAmount) {
      totalChange = validAmountChange - prevAmount;
    }

    /**
     * Add the config at the end of the array if the config is new.
     * Otherwise create new array with same items to not affect the original array.
     */
    const newConfigs = prevConfig
      ? [...originalConfig]
      : [...originalConfig, newConfig];
    if (prevConfig) {
      // Replace the prevConfig with newConfig using splice to NOT change the order of configs
      newConfigs.splice(index, 1, newConfig);
    }
    set(newOrder, `${productId}.config`, newConfigs);

    if (replaceAmount) {
      const newTotal = sum(newConfigs.map((c) => c.amount));
      set(newOrder, `${productId}.amount`, newTotal);
    } else {
      set(newOrder, `${productId}.amount`, totalChange + prevTotalAmount);
    }
  } else {
    set(
      newOrder,
      `${productId}.amount`,
      replaceAmount ? totalChange : totalChange + prevTotalAmount
    );
  }
  return newOrder;
};
