import { rules } from "@kanpla/system";
import { Modal } from "@kanpla/ui";
import { Button, Form, Input, message } from "antd";
import axios from "axios";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const SentryFeedback = ({ eventId, className = "" }) => {
  const { t, i18n } = useTranslation(["libs", "translation"]);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const submitFeedback = async () => {
    setLoading(true);

    // build body
    const data = {
      event_id: eventId,
      name,
      email,
      comments: description,
    };

    try {
      const url = `https://sentry.io/api/0/projects/${process.env.NEXT_PUBLIC_SENTRY_ORG}/${process.env.NEXT_PUBLIC_SENTRY_PROJECT}/user-feedback/`;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_SENTRY_AUTH_TOKEN}`,
        },
      };

      await axios.post(url, data, config);

      message.success(
        isEmpty(i18n) ? "Tak for din feedback!" : t("libs:message.thank")
      );
    } catch (err) {
      console.error(err);
      message.error(err.message);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <div className={className}>
      <Button
        size="large"
        type="primary"
        className="w-full"
        onClick={() => setOpen(true)}
      >
        {isEmpty(i18n) ? "Fortæl os hvad der skete" : t("libs:tell-us")}
      </Button>
      <Modal
        open={open}
        setOpen={setOpen}
        title={
          isEmpty(i18n)
            ? "Det ser ud til, at vi har problemer"
            : t("libs:looks-like")
        }
        subtitle={
          isEmpty(i18n)
            ? "Hjælp os, ved at fortælle hvad der skete nedenfor"
            : t("libs:help-us")
        }
        className="max-w-lg z-max"
      >
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={submitFeedback}
          // To catch the submit event on a custom form
          onSubmitCapture={(event) => event.preventDefault()}
        >
          <Form.Item
            label="Navn"
            name="field-name"
            rules={[
              rules({
                rule: "required",
                ruleValue: true,
                message: isEmpty(i18n)
                  ? "Indtast venligst et navn"
                  : t("translation:form.errors.required-a", {
                      value: t("translation:name"),
                    }),
              }),
            ]}
          >
            <Input
              id="name"
              type="name"
              placeholder={isEmpty(i18n) ? "Navn" : t("translation:name")}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[
              rules({
                rule: "required",
                ruleValue: true,
                message: isEmpty(i18n)
                  ? "Indtast venligst e-mailen"
                  : t("translation:form.errors.required", {
                      value: t("translation:email"),
                    }),
              }),
              rules({
                rule: "type",
                ruleValue: "email",
                message: isEmpty(i18n)
                  ? "Ugyldig email"
                  : t("translation:form.errors.invalid", {
                      value: t("translation:email"),
                    }),
              }),
            ]}
          >
            <Input
              id="email"
              type="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={isEmpty(i18n) ? "Hvad skete der?" : t("libs:what-happened")}
            name="description"
            rules={[
              rules({
                rule: "required",
                ruleValue: true,
                message: isEmpty(i18n)
                  ? "Indtast venligst en beskrivelse"
                  : t("translation:form.errors.required-en", {
                      value: t("translation:values.description"),
                    }),
              }),
            ]}
          >
            <Input.TextArea
              placeholder={
                isEmpty(i18n)
                  ? "Beskrivelse"
                  : t("translation:values.description").toUpperCase()
              }
              style={{ resize: "none" }}
              rows={4}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Item>
          <div className="flex justify-end">
            <Form.Item className="mb-0">
              <Button className="mr-2" onClick={() => setOpen(false)}>
                {isEmpty(i18n) ? "Luk" : t("translation:close")}
              </Button>
            </Form.Item>
            <Form.Item className="mb-0">
              <Button
                className="w-32"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Send
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default SentryFeedback;
