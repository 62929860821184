import { Child } from "@kanpla/types";

export const getChildDisplayName = (name: Child["name"]) => {
  const fullName = name.split(" ");
  const firstName = fullName[0];
  const [, ...otherNames] = fullName;

  const initials = otherNames
    .filter((name) => name.length > 0)
    .map((name) => `${name.substr(0, 1).toUpperCase()}.`)
    .join(" ");

  const displayName = `${firstName} ${initials}`;

  return displayName;
};
