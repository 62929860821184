import { message } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";

const RefillNotification = () => {
  const { t } = useTranslation(["translation", "modals"]);
  const { user, balance } = useContainer(AppContext);

  const [processing, setProcessing] = useState(false);
  const [wasRefilling, setWasRefilling] = useState(false);

  useEffect(() => {
    if (!user) return;
    //

    const isRefilling = !!user.refilling;

    const now = Date.now() / 1000;
    const triggeredRecently =
      user.refillLast && now - user.refillLast.seconds < 600; // if less than 10 minutes since

    setProcessing(isRefilling && triggeredRecently);

    // When stopped refilling...
    if (wasRefilling && !isRefilling) {
      if (user["refillError"] && now - user["refillError"].seconds < 15) {
        // Display payment error
        message.error(t("translation:payment-failed"));
      } else {
        // Display success message
        message.success(t("modals:message.success.refueling-successful"));
      }
    }

    setWasRefilling(isRefilling);
  }, [user, balance]);

  useEffect(() => {
    //

    if (processing === true) {
      message.loading(t("modals:message.loading.refueling-is-processed"));
    } else if (processing === false) {
      message.destroy();
    }
  }, [processing]);

  return null;
};

export default RefillNotification;
