import { db } from "@kanpla/system";

interface Props {
  /** collection to update */
  collection: string;
  /** document id inside of the collection */
  docId: string;
  /** preference language based on the user or the school */
  language: string;
}

/**
 * This method return a promise that will update a document inside the db,
 * more precisely a property called "locale"
 */
export const editLanguage = async ({
  collection,
  docId,
  language,
}: Props): Promise<void> => {
  const ref = db.collection(collection).doc(docId);
  return await ref.update({
    language,
  });
};
