import React, { ReactElement } from "react";

interface Props {
  children: any;
}

/**
 * Dynamically adds tilted borders to MultiDropdown with type `dropdown`
 * @param props
 */
export const MultiDropdownWrapper = (props: Props) => {
  const { children } = props;

  /**
   * Check if some child element has an `hidden` prop. This is done to prevent the element being counted in the
   * clone function below.
   */
  const noHiddenChildren: any = React.Children.toArray(children).filter(
    (child: any) => !child.props?.hidden
  );
  const count = React.Children.count(noHiddenChildren);

  const hasTab = Object.values(children).some(
    (child: ReactElement) => child?.props?.type === "tabs"
  );

  // If one child is of type `tabs` the tilted border should not be applied
  if (hasTab) return <div className="flex">{children}</div>;

  // Get the tilted border based on the children count and the current element index
  const getTiltedBorder = (index: number) => {
    if (count === 1) return null;
    if (count === 2) {
      if (index === 0) return "right";
      if (index === 1) return "left";
    }
    if (count > 2) {
      if (index === 0) return "right";
      if (index === count - 1) return "left";
      return "both";
    }
  };

  return (
    <div className="flex">
      {React.Children.map(noHiddenChildren, (child, i) => {
        return React.cloneElement(child, {
          ...child.props,
          tilted: getTiltedBorder(i),
        });
      })}
    </div>
  );
};
