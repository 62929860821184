import { Week, DayIndex, Timestamp as TimestampType } from "@kanpla/types";
import {
  getCurrentWeek,
  Timestamp,
  getWeekArray,
  getWeekSeconds,
} from "@kanpla/system";
import React, { useState } from "react";

export const UseTimeNavigation = () => {
  const [week, setWeek] = useState<Week>(getCurrentWeek({ Timestamp }));
  const [dayIndex, setDayIndex] = useState<DayIndex>(0); // Can be 0,1,2,3,4
  const date = week[dayIndex];
  const dateSeconds = date?.seconds;
  const setDate = (newDate: TimestampType) => {
    const jsDate = new Date(newDate.seconds * 1000);
    let newDateIndex = (jsDate.getDay() - 1) as DayIndex;
    if (typeof newDateIndex !== `number`) return;
    if (newDateIndex > 4) newDateIndex = 4;
    if (newDateIndex < 0) newDateIndex = 0;
    if (setDayIndex) setDayIndex(newDateIndex);

    // Adjust week
    const newWeek = getWeekArray(getWeekSeconds(newDate.seconds), Timestamp);
    setWeek(newWeek);
  };

  return { week, setWeek, dayIndex, setDayIndex, date, setDate, dateSeconds };
};
