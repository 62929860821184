/* eslint-disable react-hooks/rules-of-hooks */
import {
  flexBulkOrder,
  FlexOrder,
  FlexStandard,
  OrderOrder,
  Timestamp,
} from "@kanpla/types";
import moment from "moment";
import { useCollectionListener } from "../firestore/UseCollectionListener";
import { getDayIndexFromSeconds } from "./getDayIndexFromSeconds";

interface Props {
  schoolId: string;
  moduleId: string;
  childId: string;
  /** If true, looking for bulk standard */
  isBulk?: boolean;
  /** Database provider */
  db: any;
  /** Day of the order */
  date: Timestamp;
  /** Standard order as a fallback, if no document was found */
  standard: FlexStandard["standard"];
}

export const getFlexOrder = <T>(props: Props) => {
  const { schoolId, moduleId, childId, isBulk, db, date, standard } = props;

  const dayIndex = getDayIndexFromSeconds(date?.seconds || 0);

  const bulkRef = db
    .collection("flexBulkOrders")
    .where("schoolId", "==", schoolId || null)
    .where("moduleId", "==", moduleId || null)
    .where(`date`, "==", date);
  const individualRef = db
    .collection("flexOrders")
    .where("childId", "==", childId || null)
    .where("schoolId", "==", schoolId || null)
    .where("moduleId", "==", moduleId || null)
    .where(`date`, "==", date);

  const [result = [], orderLoading] = useCollectionListener<
    flexBulkOrder | FlexOrder
  >(isBulk ? bulkRef : individualRef);

  // Get the first document
  const orderDocument = result.filter((res) => !res.deleted)[0];

  const isPastDate = moment.unix(date?.seconds || 0).isBefore(moment(), "day");

  // If no document exists, return standard order
  const noOrderDocument = !orderLoading && typeof orderDocument === `undefined`;
  const standardOrder = isPastDate ? 0 : standard[dayIndex] || {};
  const documentOrderOrder = orderDocument?.order || {};
  const order: OrderOrder = noOrderDocument
    ? standardOrder
    : documentOrderOrder;
  const orderInfo = orderDocument?.info || {};

  return { order, orderInfo, orderLoading, orderDocument };
};
